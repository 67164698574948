import { UserStatus, OrdersListType } from "../@types";
import { Moment } from "moment";

import axios from "axios";
import { apiBaseUrl } from "../deployment";

const instance = axios.create({
	baseURL: apiBaseUrl,
});

export const userAPI = {
  authMe() {
    return new Promise<{ driver_id: number; full_name: string; status: UserStatus; error_code?: number; error_message?: string }>((resolve, reject) => {
      resolve({
        driver_id: 1,
        full_name: "Шпагин А.В.",
        status: UserStatus.Working
      });
      // reject({
      //   error_code: 1,
      //   error_message: "Unathorized"
      // });
    });
  },
  getDriverStatus(driver_id: number) {
    return new Promise<{ id: number; status: UserStatus; dates: string[]  }>((resolve, reject) => {
      resolve({
        id: 1,
        status: UserStatus.Working,
        dates: ["2021-03-27"]
      });
    });
  },
  setDriverStatus({ id, status, dates }: { id: number, status: UserStatus, dates: string[] }) {
    return new Promise<{ id: number; status: UserStatus; error_message?: string; }>((resolve, reject) => {
      resolve({
        id,
        status
      });
    });
  },
  statistic({ driver_id, date_from, date_to }: { driver_id: number, date_from: Moment, date_to: Moment }) {
    return new Promise<{ debit: number; credit: number; price: number; fuel_rate: number; distance: number; }>((resolve, reject) => {
      resolve({
        debit: 15590,
        credit: 3223,
        price: 723,
        fuel_rate: 8,
        distance: 154
      });
    });
  }
}

export const ordersAPI = {
  list(type: OrdersListType) {
    return new Promise<{ id: number, address: string, lon: number, lat: number, price: number, date: Date, is_look: boolean }[]>((resolve, reject) => {
      resolve([
        {
          id: 1,
          address: "Адрес",
          lon: 55.761901,
          lat: 37.612143,
          price: 2500,
          date: new Date("2021-03-16 12:30:00"),
          is_look: false
        },
        {
          id: 2,
          address: "Адрес",
          lon: 55.767113,
          lat: 37.593339,
          price: 1000,
          date: new Date("2021-03-16 12:30:00"),
          is_look: false
        },
        {
          id: 3,
          address: "Адрес",
          lon: 55.773069,
          lat: 37.615338,
          price: 1000,
          date: new Date("2021-03-16 12:45:00"),
          is_look: false
        },
        {
          id: 4,
          address: "Адрес",
          lon: 55.765578,
          lat: 37.631270,
          price: 1700,
          date: new Date("2021-03-16 12:50:00"),
          is_look: false
        },
        {
          id: 5,
          address: "Адрес",
          lon: 55.764378,
          lat: 37.644694,
          price: 1800,
          date: new Date("2021-03-16 12:55:00"),
          is_look: false
        }
      ])
    });
  }
}

export const phoneAPI = {
	sendSms(phone: string) {
		let requestData = {phone};
		return instance.post('/send-sms-code', requestData)
			.then(response => response)
			.catch(error => error);
	},

	checkSms(phone: string, code: string) {
		return instance.get('/check-sms-code?phone=' + phone + '&code=' + code)
			.then(response => response)
			.catch(error => error);
	},

	checkPhone(phone: string) {
		return instance.get('/check-tin-phone?phone=' + phone)
			.then(response => response)
			.catch(error => error);
	}
};

export const tinAPI = {
	checkTin(tin: string) {
		return instance.get('/check-tin?bik=' + tin)
			.then(response => response)
			.catch(error => error);
	}
};

export const vehicleAPI = {
	getCarTypes() {
		return instance.get('/car-types')
			.then(response => response)
			.catch(error => error);
	},

	getBodyOptions(carTypeId: string) {
		return instance.get('/body-options?car_type_id=' + carTypeId)
			.then(response => response)
			.catch(error => error);
	},

	getBodyOptionChs(bodyOptionId: string, bodyTypeId: string, carTypeId: string) {
		return instance.get('/body-option-characteristics?body_option_id=' +
			bodyOptionId + '&body_type_id=' + bodyTypeId +
			'&car_type_id=' + carTypeId)
			.then(response => response)
			.catch(error => error);
	},

	getBodyOptionChValues(bodyOptionChId: string, carTypeId: string) {
		return instance.get('/body-option-characteristics-values' +
			'?body_option_characteristics_id=' + bodyOptionChId +
			'&car_type_id=' + carTypeId)
			.then(response => response)
			.catch(error => error);
	},

	getCarPasses() {
		return instance.get('/car-pass')
			.then(response => response)
			.catch(error => error);
	}
};

export const registrationAPI = {
	postData(data: any) {
		return instance.post('/drivers', data)
			.then(response => response)
			.catch(error => error);
	},

	postFile(data: any) {
		return instance.post('/driver-files', data)
			.then(response => response)
			.catch(error => error);
	}
}

export const referralsAPI = {
	getData(driverId: string) {
		return instance.get('/referals?driver_id=' + driverId)
			.then(response => response)
			.catch(error => error);
	},

	sendDriver(driverId: string, phone: string) {
		let data = {
			driver_id: driverId,
			phone: phone,
			type: 'driver'
		}
		return instance.post('/referals', data)
			.then(response => response)
			.catch(error => error);
	},

	sendClient(driverId: string, phone: string, name: string, email: string) {
		let data = {
			driver_id: driverId,
			phone: phone,
			full_name: name,
			email: email,
			type: 'client'
		}
		return instance.post('/referals', data)
			.then(response => response)
			.catch(error => error);
	}
}

export const toBase64 = (blob: Blob) => new Promise((resolve, reject) => {
	const reader = new FileReader();
	reader.readAsDataURL(blob);
	reader.onload = () => resolve(reader.result);
	reader.onerror = error => reject(error);
});
