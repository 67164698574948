import { OrdersState, OrdersActionTypes, SET_ORDERS } from "./@types";

const initialState: OrdersState = {
  items: []
}

const ordersReducer = (state = initialState, action:  OrdersActionTypes) => {
  switch (action.type) {
    case SET_ORDERS:
      return {
        ...state,
        items: action.orders
      }
    default: 
    return state;
  }
}

export default ordersReducer;