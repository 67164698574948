import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { RootState } from "../../redux/store";
import InputMask from "react-input-mask";
import {
	CheckPhoneState,
	SetRegistrationStartedAction,
	SetPhoneEnteredAction,
	SetCodeSendingAction,
	SetCodeSentAction,
	SetCodeEnteredAction,
	SetCodeVerifyingAction
} from "../../redux/reducers/check_phone/@types";
import {
	setRegistrationStarted,
	setPhoneEntered,
	setCodeSending,
	setCodeSent,
	setCodeEntered,
	setCodeVerifying
} from "../../redux/reducers/check_phone/actions";
import BulletSVG from "../App/svg/bullet.svg";
import CheckSVG from "../App/svg/check.svg";
import { ReactSVG } from "react-svg";
import { CancelBtn } from "../Elements/elements";
import { ordersAPI, phoneAPI } from "../../api";
import { trimPhone } from "../../api/validate";
import { OrdersListType } from "../../@types";
import "./CheckPhone.css";

interface IProps {
  state: CheckPhoneState,
  setRegistrationStarted: (registration_started: boolean) => SetRegistrationStartedAction,
  setPhoneEntered: (phone_entered: boolean, phone?: string) => SetPhoneEnteredAction,
  setCodeSending: (code_sending: boolean) => SetCodeSendingAction,
  setCodeSent: (code_sent: boolean) => SetCodeSentAction,
  setCodeEntered: (code_entered: boolean, code?: string) => SetCodeEnteredAction,
  setCodeVerifying: (code_verifying: boolean) => SetCodeVerifyingAction
}

const CheckPhone: React.FunctionComponent<IProps> = ({
	state,
	setRegistrationStarted,
	setPhoneEntered,
	setCodeSending,
	setCodeSent,
	setCodeEntered,
	setCodeVerifying
}) => {

  const [numOrders, setNumOrders] = useState(0);
  const [sendCodeError, setSendCodeError] = useState(false);
  const [verifyCodeError, setVerifyCodeError] = useState(false);
  const [checkPhoneError, setCheckPhoneError] = useState(false);
  const [time, setTime] = useState(60);
  const timeLeft = useRef(0);
	const history: any = useHistory();

  useEffect(() => {
    ordersAPI.list(OrdersListType.All).then((res) => {
      setNumOrders(res.length);
    }).catch((err) => {})
  }, []);

  return (
		<>
			<CancelBtn />
			{state.code_sent ?
				<>
					<div className="check_phone_window">
						<div className="check_phone_container">
							<label className="check_phone_label">
								<div className="check_phone_label_text">На ваш номер придет SMS-сообщение с кодом, который нужно ввести ниже</div>
								<div className="check_phone_input_container">
									<InputMask mask="9 9 9 9" id="check_phone_code"
										className="check_phone_input" type="tel" value={state.code} onChange={(e) => {
										let code = e.target.value;
										if (code.indexOf('_') === -1) {
											code = code.replace(/ /g, '');
											setCodeEntered(true, code);
										} else {
											setCodeEntered(false);
										}
									}}>
										{(inputProps: any) => <input {...inputProps} />}
									</InputMask>
									{state.code_entered && <ReactSVG src={CheckSVG} className="check_phone_check" style={{"right": "33%"}}/>}
									{verifyCodeError && <div className="check_phone_error">Введенный код некорректен.</div>}
									{checkPhoneError && <div className="check_phone_error">Ошибка. Попробуйте еще раз.</div>}
									{timeLeft.current > 0 && !verifyCodeError &&
										<div className="check_phone_text_grey">Отправить новый код можно через {time} сек...</div>
									}
								</div>
							</label>
							<div id="check_phone_verify_code" className={'check_phone_next_button' +
								(state.code_entered && !state.code_verifying ? '' : ' inactive')}
  			    		onClick={async () => {
  			    			setCodeVerifying(true);
  			    			let res = await phoneAPI.checkSms(state.phone, state.code);
  			    			if (res.data && res.data.valid) {
  			    				res = await phoneAPI.checkPhone(state.phone);
  			    				if (res.data && res.data.exist) {
  			    					console.log('Phone number already registered - proceeding to login');
  			    					history.push('/'); //TODO
  			    				} else if (res.data && !res.data.exist) {
	  			    				history.push('/owner-select');
	  			    			} else {
	  			    				setCodeVerifying(false);
  				    				setCheckPhoneError(true);
  			    					setTimeout(setCheckPhoneError, 5000, false);
	  			    			}
  			    			} else {
  			    				setCodeVerifying(false);
  			    				setVerifyCodeError(true);
  			    				setTimeout(setVerifyCodeError, 5000, false);
  			    			}
  			    		}}>
  			    		<div className="check_phone_start_text">Далее</div>
  			    	</div>
						</div>
					</div>
				</> :
				<>
					{state.registration_started ?
						<div className="check_phone_window">
							<div className="check_phone_container">
								<label className="check_phone_label">
									<div className="check_phone_label_text">Введите свой номер телефона</div>
									<div className="check_phone_input_container">
										<InputMask mask="+7(999) 999-99-99" id="check_phone_phone"
											className="check_phone_input" type="tel" value={state.phone} onChange={(e) => {
											let phone = e.target.value;
											if (phone.indexOf('_') === -1) {
												phone = trimPhone(phone);
												if (phone === '70000000000')
													history.push('/owner-select');//TODO for tests only
												setPhoneEntered(true, phone);
											} else {
												setPhoneEntered(false);
											}
										}}>
											{(inputProps: any) => <input {...inputProps} />}
										</InputMask>
										{state.phone_entered && <ReactSVG src={CheckSVG} className="check_phone_check" />}
										{sendCodeError && <div className="check_phone_error">Ошибка отправки кода. Попробуйте еще раз.</div>}
									</div>
								</label>
								<div id="check_phone_send_code" className={'check_phone_next_button' +
									(state.phone_entered && !state.code_sending ? '' : ' inactive')}
  				    		onClick={async () => {
  				    			setCodeSending(true);
  				    			timeLeft.current = 60;
  				    			setTime(60);
  				    			let smsInterval = setInterval(() => {
  				    				timeLeft.current -= 1;
  				    				setTime(timeLeft.current);
  				    				if (timeLeft.current === 0) {
  				    					clearInterval(smsInterval);
  				    					setCodeSent(false);
  				    					setCodeEntered(false, '');
  				    				}
  				    			}, 1000);
  				    			let res = await phoneAPI.sendSms(state.phone);
  				    			setCodeSending(false);
  				    			if (res.data && res.data.status === 'success') {
  				    				setCodeSent(true);
  				    			} else {
  				    				setSendCodeError(true);
  				    				setTimeout(setSendCodeError, 5000, false);
  				    			}
  				    		}}>
  				    		<div className="check_phone_start_text">Далее</div>
  				    	</div>
							</div>
						</div> :
			    	<div className="check_phone_window">
				      <div className="check_phone_container">
  				    	<div className="check_phone_title">Получите доступ ко всем {numOrders} заказам в Москве</div>
  				    	<div className="check_phone_text_bold">а также</div>
  				    	<table className="check_phone_list">
  				    		<tbody>
				  	    		<tr>
					  	    		<td className="check_phone_list_bullet"><ReactSVG src={BulletSVG} /></td>
					  	    		<td className="check_phone_list_item">Получайте заказы, подобранные специально для вас</td>
					  	    	</tr>
					  	    	<tr>
					  	    		<td className="check_phone_list_bullet"><ReactSVG src={BulletSVG} /></td>
  					    			<td className="check_phone_list_item">Зарабатывайте каждый день</td>
  					    		</tr>
  					    		<tr>
					  	    		<td className="check_phone_list_bullet"><ReactSVG src={BulletSVG} /></td>
  					    			<td className="check_phone_list_item">Удобно и просто общайтесь с клиентом, не покидая сайта</td>
  					    		</tr>
  					    	</tbody>
  				    	</table>
  				    	<div id="check_phone_start" className="check_phone_start_button"
  				    		onClick={() => setTimeout(
  				    			setRegistrationStarted, 1000, true)}>
  				    		<div className="check_phone_start_text">Начать работу</div>
  				    	</div>
  				    </div>
						</div>
					}
				</>
			}
		</>
	)
}

const mapStateToProps = (state: RootState) => ({
	state: state.checkPhoneReducer
});

export default connect(mapStateToProps, {
	setRegistrationStarted,
	setPhoneEntered,
	setCodeSending,
	setCodeSent,
	setCodeEntered,
	setCodeVerifying
})(CheckPhone);
