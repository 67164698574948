import {
	LicenseDataState,
	SET_LICENSE_NUMBER_ENTERED,
	SET_LICENSE_ISSUE_DATE_ENTERED,
	SET_LICENSE_ISSUED_BY_ENTERED,
	SET_COUNTRY_ENTERED,
	SET_CATEGORY_ENTERED,
	ADD_LICENSE_PHOTO,
	REPLACE_LICENSE_PHOTO,
	CLEAR_LICENSE_PHOTOS,
	LicenseDataActionTypes
} from "./@types";

let initialState: LicenseDataState = {
	number_entered: false,
	number: '',
	issue_date_entered: false,
	issue_date: '',
	issued_by_entered: false,
	issued_by: '',
	country_entered: false,
	country: '',
	category_entered: false,
	categories: [],
	photos: []
}

const licenseDataReducer = (state = initialState, action: LicenseDataActionTypes) => {
	switch (action.type) {
		case SET_LICENSE_NUMBER_ENTERED:
			return {
				...state,
				number_entered: action.number_entered,
				number: action.number
			}
		case SET_LICENSE_ISSUE_DATE_ENTERED:
			return {
				...state,
				issue_date_entered: action.issue_date_entered,
				issue_date: action.issue_date
			}
		case SET_LICENSE_ISSUED_BY_ENTERED:
			return {
				...state,
				issued_by_entered: action.issued_by_entered,
				issued_by: action.issued_by
			}
		case SET_COUNTRY_ENTERED:
			return {
				...state,
				country_entered: action.country_entered,
				country: action.country
			}
		case SET_CATEGORY_ENTERED:
			return {
				...state,
				category_entered: action.category_entered,
				categories: action.categories
			}
		case ADD_LICENSE_PHOTO:
			let temp = state.photos;
			temp.push(action.photo);
			return {
				...state,
				photos: temp
			}
		case REPLACE_LICENSE_PHOTO:
			let tmp = state.photos;
			if (action.index < tmp.length) {
				tmp[action.index] = action.new_photo;
			}
			return {
				...state,
				photos: tmp
			}
		case CLEAR_LICENSE_PHOTOS:
			return {
				...state,
				photos: []
			}
		default:
			return state;
	}
}

export default licenseDataReducer;
