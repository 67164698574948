export const SET_REGISTRATION_STARTED = 'SET_REGISTRATION_STARTED';
export const SET_PHONE_ENTERED = 'SET_PHONE_ENTERED';
export const SET_CODE_SENDING = 'SET_CODE_SENDING';
export const SET_CODE_SENT = 'SET_CODE_SENT';
export const SET_CODE_ENTERED = 'SET_CODE_ENTERED';
export const SET_CODE_VERIFYING = 'SET_CODE_VERIFYING';

export interface SetRegistrationStartedAction {
  type: typeof SET_REGISTRATION_STARTED;
  registration_started: boolean;
}

export interface SetPhoneEnteredAction {
  type: typeof SET_PHONE_ENTERED;
  phone_entered: boolean;
  phone?: string;
}

export interface SetCodeSendingAction {
  type: typeof SET_CODE_SENDING;
  code_sending: boolean;
}

export interface SetCodeSentAction {
  type: typeof SET_CODE_SENT;
  code_sent: boolean;
}

export interface SetCodeEnteredAction {
  type: typeof SET_CODE_ENTERED;
  code_entered: boolean;
  code?: string;
}

export interface SetCodeVerifyingAction {
  type: typeof SET_CODE_VERIFYING;
  code_verifying: boolean;
}

export type CheckPhoneActionTypes = SetRegistrationStartedAction
	| SetPhoneEnteredAction
	| SetCodeSendingAction
	| SetCodeSentAction
	| SetCodeEnteredAction
	| SetCodeVerifyingAction;

export interface CheckPhoneState {
  registration_started: boolean;
  phone_entered: boolean;
  phone: string;
  code_sending: boolean;
  code_sent: boolean;
  code_entered: boolean;
  code: string;
  code_verifying: boolean;
}
