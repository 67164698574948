import {
	SET_ACCOUNT_ENTERED,
	SET_BANK_ENTERED,
	SET_BIC_ENTERED,
	SET_CORR_ENTERED,
	SET_INN_ENTERED,
	SET_KPP_ENTERED,
	SET_OGRN_ENTERED,
	SET_BANK_ADDRESS_ENTERED,
	SetAccountEnteredAction,
	SetBankEnteredAction,
	SetBicEnteredAction,
	SetCorrEnteredAction,
	SetInnEnteredAction,
	SetKppEnteredAction,
	SetOgrnEnteredAction,
	SetBankAddressEnteredAction
} from "./@types";

export const setAccountEntered = (account_entered: boolean, account?: string): SetAccountEnteredAction => ({ 
  type: SET_ACCOUNT_ENTERED, 
  account_entered,
  account
});

export const setBankEntered = (bank_entered: boolean, bank?: string): SetBankEnteredAction => ({ 
  type: SET_BANK_ENTERED, 
  bank_entered,
  bank
});

export const setBicEntered = (bic_entered: boolean, bic?: string): SetBicEnteredAction => ({ 
  type: SET_BIC_ENTERED, 
  bic_entered,
  bic
});

export const setCorrEntered = (corr_entered: boolean, corr?: string): SetCorrEnteredAction => ({ 
  type: SET_CORR_ENTERED, 
  corr_entered,
  corr
});

export const setInnEntered = (inn_entered: boolean, inn?: string): SetInnEnteredAction => ({ 
  type: SET_INN_ENTERED, 
  inn_entered,
  inn
});

export const setKppEntered = (kpp_entered: boolean, kpp?: string): SetKppEnteredAction => ({ 
  type: SET_KPP_ENTERED, 
  kpp_entered,
  kpp
});

export const setOgrnEntered = (ogrn_entered: boolean, ogrn?: string): SetOgrnEnteredAction => ({ 
  type: SET_OGRN_ENTERED, 
  ogrn_entered,
  ogrn
});

export const setBankAddressEntered = (bank_address_entered: boolean, bank_address?: string): SetBankAddressEnteredAction => ({ 
  type: SET_BANK_ADDRESS_ENTERED, 
  bank_address_entered,
  bank_address
});
