import { Category } from "../../../../components/Elements/Containers/@types";

export const SET_LICENSE_NUMBER_ENTERED = 'SET_LICENSE_NUMBER_ENTERED';
export const SET_LICENSE_ISSUE_DATE_ENTERED = 'SET_LICENSE_ISSUE_DATE_ENTERED';
export const SET_LICENSE_ISSUED_BY_ENTERED = 'SET_LICENSE_ISSUED_BY_ENTERED';
export const SET_COUNTRY_ENTERED = 'SET_COUNTRY_ENTERED';
export const SET_CATEGORY_ENTERED = 'SET_CATEGORY_ENTERED';
export const ADD_LICENSE_PHOTO = 'ADD_LICENSE_PHOTO';
export const REPLACE_LICENSE_PHOTO = 'REPLACE_LICENSE_PHOTO';
export const CLEAR_LICENSE_PHOTOS = 'CLEAR_LICENSE_PHOTOS';

export interface SetLicenseNumberEnteredAction {
  type: typeof SET_LICENSE_NUMBER_ENTERED;
  number_entered: boolean;
  number?: string;
}

export interface SetLicenseIssueDateEnteredAction {
  type: typeof SET_LICENSE_ISSUE_DATE_ENTERED;
  issue_date_entered: boolean;
  issue_date?: string;
}

export interface SetLicenseIssuedByEnteredAction {
  type: typeof SET_LICENSE_ISSUED_BY_ENTERED;
  issued_by_entered: boolean;
  issued_by?: string;
}

export interface SetCountryEnteredAction {
  type: typeof SET_COUNTRY_ENTERED;
  country_entered: boolean;
  country?: string;
}

export interface SetCategoryEnteredAction {
  type: typeof SET_CATEGORY_ENTERED;
  category_entered: boolean;
  categories?: Category[];
}

export interface AddLicensePhotoAction {
  type: typeof ADD_LICENSE_PHOTO;
  photo: Blob;
}

export interface ReplaceLicensePhotoAction {
  type: typeof REPLACE_LICENSE_PHOTO;
  index: number;
  new_photo: Blob;
}

export interface ClearLicensePhotosAction {
	type: typeof CLEAR_LICENSE_PHOTOS;
}

export type LicenseDataActionTypes = SetLicenseNumberEnteredAction
	| SetLicenseIssueDateEnteredAction
	| SetLicenseIssuedByEnteredAction
	| SetCountryEnteredAction
	| SetCategoryEnteredAction
	| AddLicensePhotoAction
	| ReplaceLicensePhotoAction
	| ClearLicensePhotosAction;

export interface LicenseDataState {
	number_entered: boolean;
	number: string;
	issue_date_entered: boolean;
	issue_date: string;
	issued_by_entered: boolean;
	issued_by: string;
	country_entered: boolean;
	country: string;
	category_entered: boolean;
	categories: Category[];
	photos: Blob[];
}
