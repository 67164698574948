import { UserStatus } from "../../../../@types";

export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_STATUS = 'SET_STATUS';

export interface SetAuthenticatedAction {
  type: typeof SET_AUTHENTICATED;
  authenticated: boolean;
  driver_id?: number;
  full_name?: string;
  status?: UserStatus
}

export interface SetStatusAction {
  type: typeof SET_STATUS;
  status: UserStatus
}

export type UserActionTypes = SetAuthenticatedAction | SetStatusAction;

export interface UserState {
  authenticated: boolean;
  driver_id: number;
  full_name: string;
  status: UserStatus
}