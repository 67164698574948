import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../redux/store";
import { UserState } from "../../redux/reducers/user/@types";
import Screen, { ScreenTab } from "./Screen/Screen";
import "./Profile.css";

interface IProps {
  state: UserState;
}

const Profile: React.FunctionComponent<IProps> = ({ state }) => {
  const { full_name, status } = state;

  return (
    <Screen title={full_name} status={status === "working" ? "Статус: работаю" : "Статус: не работаю"}>
      <ScreenTab url="/" title="Журнал заказов" />
      <ScreenTab url="/statistic" title="Статистика" />
    </Screen>
  )
}

const mapStateToProps = (state: RootState) => ({
  state: state.userReducer
});

export default connect(mapStateToProps)(Profile);