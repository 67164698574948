import React, { useRef } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { BankSuggestions, AddressSuggestions } from "react-dadata";
import { daDataToken } from "../../deployment";
import { RootState } from "../../redux/store";
import {
	Vehicle,
	Driver,
	Passport,
	Bank
} from "../Elements/Containers/@types";
import {
	BankDataState,
	SetAccountEnteredAction,
	SetBankEnteredAction,
	SetBicEnteredAction,
	SetCorrEnteredAction,
	SetInnEnteredAction,
	SetKppEnteredAction,
	SetOgrnEnteredAction,
	SetBankAddressEnteredAction
} from "../../redux/reducers/bank_data/@types";
import {
	FinalizeState,
	SaveOwnerAction
} from "../../redux/reducers/finalize/@types";
import {
	setAccountEntered,
	setBankEntered,
	setBicEntered,
	setCorrEntered,
	setInnEntered,
	setKppEntered,
	setOgrnEntered,
	setBankAddressEntered
} from "../../redux/reducers/bank_data/actions";
import { saveOwner } from "../../redux/reducers/finalize/actions";
import { CancelBtn } from "../Elements/elements";
import { editingChars } from "../../@types";
import "./BankData.css";

interface IProps {
  state: BankDataState,
  finalize: FinalizeState,
  setAccountEntered: (account_entered: boolean, account?: string) => SetAccountEnteredAction,
  setBankEntered: (bank_entered: boolean, bank?: string) => SetBankEnteredAction,
  setBicEntered: (bic_entered: boolean, bic?: string) => SetBicEnteredAction,
  setCorrEntered: (corr_entered: boolean, corr?: string) => SetCorrEnteredAction,
  setInnEntered: (inn_entered: boolean, inn?: string) => SetInnEnteredAction,
  setKppEntered: (kpp_entered: boolean, kpp?: string) => SetKppEnteredAction,
  setOgrnEntered: (ogrn_entered: boolean, ogrn?: string) => SetOgrnEnteredAction,
  setBankAddressEntered: (bank_address_entered: boolean, bank_address?: string) => SetBankAddressEnteredAction,
  saveOwner: (owner_type?: string, tin_type?: string, tin?: string,
		agree?: boolean, passport?: Passport, bank?: Bank, vehicles?: Vehicle[],
		drivers?: Driver[]) => SaveOwnerAction
}

const BankData: React.FunctionComponent<IProps> = ({
	state,
	finalize,
	setAccountEntered,
	setBankEntered,
	setBicEntered,
	setCorrEntered,
	setInnEntered,
	setKppEntered,
	setOgrnEntered,
	setBankAddressEntered,
	saveOwner
}) => {
	const history: any = useHistory();
	const bic = useRef<BankSuggestions>(null);
	const bankAddress = useRef<AddressSuggestions>(null);

  return (
		<>
			<CancelBtn />
			<div className="bank_window">
				<div className="bank_container">
					<div className="bank_title">Реквизиты ИП</div>
					<div id="bank_account" className={'bank_input' + (state.account_entered ?
						' bank_input_complete' : '')}>
						<input className="bank_input_text" placeholder="Рассчетный счет в банке" type="number"
							value={state.account} onKeyDown={(e: React.KeyboardEvent) => {
								if ((!/[0-9]/.test(e.key) && !editingChars.includes(e.key)) ||
									(/[0-9]/.test(e.key) && (e.target as HTMLInputElement).value.length === 20)) {
									e.preventDefault();
								}
							}}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								if (e.target.value.length === 20) {
									setAccountEntered(true, e.target.value);
								} else {
									setAccountEntered(false);
								}
							}}
						/>
					</div>
					<div id="bank_bic" className={'bank_input' + (state.bic_entered ?
						' bank_input_complete' : '')}>
						<BankSuggestions
							token={daDataToken}
							ref={bic}
							defaultQuery={state.bic}
							count={5}
							onChange={(suggestion) => {
								if (suggestion && suggestion.data && suggestion.data.state &&
									suggestion.data.state.status === 'ACTIVE') {
									setBicEntered(true, suggestion.data.bic);
									setBankEntered(true, suggestion.value);
									setCorrEntered(true, suggestion.data.correspondent_account);
									setInnEntered(true, suggestion.data.inn);
									setKppEntered(true, suggestion.data.kpp);
									setBankAddressEntered(true, suggestion.data.address.value);
									if (bic.current) bic.current.setInputValue(suggestion.data.bic);
									if (bankAddress.current) bankAddress.current.setInputValue(suggestion.data.address.value);
								}
							}}
							inputProps={{className: 'bank_input_text', placeholder: 'БИК банка', type: 'number',
								onKeyDown: (e: React.KeyboardEvent) => {
									if ((!/[0-9]/.test(e.key) && !editingChars.includes(e.key)) ||
										(/[0-9]/.test(e.key) && (e.target as HTMLInputElement).value.length === 9)) {
										e.preventDefault();
									}
								},
								onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
									if (e.target.value.length === 9) {
										setBicEntered(true, e.target.value);
									} else {
										setBicEntered(false);
										setBankEntered(false, '');
										setCorrEntered(false, '');
										setInnEntered(false, '');
										setKppEntered(false, '');
										setBankAddressEntered(false, '');
										if (bankAddress.current) bankAddress.current.setInputValue('');
									}
								},
							}}
						/>
					</div>
					<div id="bank_name" className={'bank_input bank_autofill' + (state.bank_entered ?
						' bank_input_complete' : '')}>
						<input className="bank_input_text" placeholder="Наименование банка" type="text"
							value={state.bank} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								if (e.target.value.length >= 5) {
									setBankEntered(true, e.target.value);
								} else {
									setBankEntered(false);
								}
							}}
						/>
					</div>
					<div id="bank_corr" className={'bank_input bank_autofill' + (state.corr_entered ?
						' bank_input_complete' : '')}>
						<input className="bank_input_text" placeholder="Корр. счет банка" type="number"
							value={state.corr} onKeyDown={(e: React.KeyboardEvent) => {
								if ((!/[0-9]/.test(e.key) && !editingChars.includes(e.key)) ||
									(/[0-9]/.test(e.key) && (e.target as HTMLInputElement).value.length === 20)) {
									e.preventDefault();
								}
							}}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								if (e.target.value.length === 20) {
									setCorrEntered(true, e.target.value);
								} else {
									setCorrEntered(false);
								}
							}}
						/>
					</div>
					<div id="bank_inn" className={'bank_input bank_autofill' + (state.inn_entered ?
						' bank_input_complete' : '')}>
						<input className="bank_input_text" placeholder="ИНН банка" type="number"
							value={state.inn} onKeyDown={(e: React.KeyboardEvent) => {
								if ((!/[0-9]/.test(e.key) && !editingChars.includes(e.key)) ||
									(/[0-9]/.test(e.key) && (e.target as HTMLInputElement).value.length === 10)) {
									e.preventDefault();
								}
							}}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								if (e.target.value.length === 10) {
									setInnEntered(true, e.target.value);
								} else {
									setInnEntered(false);
								}
							}}
						/>
					</div>
					<div id="bank_kpp" className={'bank_input bank_autofill' + (state.kpp_entered ?
						' bank_input_complete' : '')}>
						<input className="bank_input_text" placeholder="КПП банка" type="number"
							value={state.kpp} onKeyDown={(e: React.KeyboardEvent) => {
								if ((!/[0-9]/.test(e.key) && !editingChars.includes(e.key)) ||
									(/[0-9]/.test(e.key) && (e.target as HTMLInputElement).value.length === 9)) {
									e.preventDefault();
								}
							}}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								if (e.target.value.length === 9) {
									setKppEntered(true, e.target.value);
								} else {
									setKppEntered(false);
								}
							}}
						/>
					</div>
					<div id="bank_ogrn" className={'bank_input' + (state.ogrn_entered ?
						' bank_input_complete' : '')}>
						<input className="bank_input_text" placeholder="ОГРН банка" type="number"
							value={state.ogrn} onKeyDown={(e: React.KeyboardEvent) => {
								if ((!/[0-9]/.test(e.key) && !editingChars.includes(e.key)) ||
									(/[0-9]/.test(e.key) && (e.target as HTMLInputElement).value.length === 13)) {
									e.preventDefault();
								}
							}}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								if (e.target.value.length === 13) {
									setOgrnEntered(true, e.target.value);
								} else {
									setOgrnEntered(false);
								}
							}}
						/>
					</div>
					<div id="bank_address" className={'bank_input' + (state.bank_address_entered ?
						' bank_input_complete' : '')}>
						<AddressSuggestions
							token={daDataToken}
							ref={bankAddress}
							defaultQuery={state.bank_address}
							count={5}
							onChange={(suggestion) => {
								if (suggestion && suggestion.value) {
									setBankAddressEntered(true, suggestion.value);
								}
							}}
							inputProps={{className: 'bank_input_text', placeholder: 'Юридический адрес банка', type: 'text',
								onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
									setBankAddressEntered(false, '');
								},
							}}
						/>
					</div>
					<section className="bank_footer">
						<div id="bank_back" className="bank_link" onClick={() => history.goBack()}>Вернуться назад</div>
						<div id="bank_next" className={'bank_button' +
							(state.account_entered && state.bank_entered && state.bic_entered &&
							state.corr_entered && state.inn_entered && state.kpp_entered &&
							state.ogrn_entered && state.bank_address_entered ? '' : ' inactive')}
								onClick={() => {
									saveOwner(finalize.owner.owner_type, finalize.owner.tin_type, finalize.owner.tin,
										finalize.owner.agree, finalize.owner.passport, {account: state.account,
										bic: state.bic, name: state.bank, corr: state.corr, inn: state.inn,
										kpp: state.kpp, ogrn: state.ogrn, address: state.bank_address},
										finalize.owner.vehicles, finalize.owner.drivers);
									setTimeout(history.push, 1000,
										finalize.finalizing ? '/finalize' : '/passport');
								}}>
			    		<div className="bank_button_text">{finalize.finalizing ? 'Сохранить' : 'Далее'}</div>
			    	</div>
					</section>
				</div>
			</div>
		</>
	)
}

const mapStateToProps = (state: RootState) => ({
	state: state.bankDataReducer,
	finalize: state.finalizeReducer
});

export default connect(mapStateToProps, {
	setAccountEntered,
	setBankEntered,
	setBicEntered,
	setCorrEntered,
	setInnEntered,
	setKppEntered,
	setOgrnEntered,
	setBankAddressEntered,
	saveOwner
})(BankData);
