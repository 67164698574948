export const MULTIPLE_CARS_OWNER = 'MULTIPLE_CARS_OWNER';
export const SINGLE_CAR_OWNER = 'SINGLE_CAR_OWNER';
export const TIN_IP = 'TIN_IP';
export const TIN_SAM = 'TIN_SAM';

export const SELECT_OWNER_TYPE = 'SELECT_OWNER_TYPE';
export const SET_TIN_ENTERED = 'SET_TIN_ENTERED';
export const SET_AGREE = 'SET_AGREE';
export const SET_TIN_VERIFYING = 'SET_TIN_VERIFYING';

export interface SelectOwnerTypeAction {
  type: typeof SELECT_OWNER_TYPE;
  owner_type_selected: boolean;
  owner_type?: string;
}

export interface SetTinEnteredAction {
  type: typeof SET_TIN_ENTERED;
  tin_entered: boolean;
  tin_type?: string;
  tin?: string;
}

export interface SetAgreeAction {
	type: typeof SET_AGREE;
	agree: boolean;
}

export interface SetTinVerifyingAction {
	type: typeof SET_TIN_VERIFYING;
	tin_verifying: boolean;
}

export type OwnerSelectActionTypes = SelectOwnerTypeAction
	| SetTinEnteredAction
	| SetAgreeAction
	| SetTinVerifyingAction;

export interface OwnerSelectState {
	owner_type_selected: boolean;
  owner_type: string;
  tin_entered: boolean;
  tin_type: string;
  tin: string;
  agree: boolean;
  tin_verifying: boolean;
}
