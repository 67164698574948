import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../../redux/store";
import { UserState} from "../../../redux/reducers/user/@types";
import { SystemState, ToggleMenuWindowAction } from "../../../redux/reducers/system/@types";
import { toggleMenuWindow } from "../../../redux/reducers/system/actions";
import BarsSVG from "../../App/svg/bars.svg";
import HelpSVG from "../../App/svg/help.svg";
import PartnerSVG from "../../App/svg/partner.svg";
import JournalSVG from "../../App/svg/journal.svg";
import UserSVG from "../../App/svg/user.svg";
import CargoTruckSVG from "../../App/svg/cargo_truck.svg";
import StopWatchSVG from "../../App/svg/stopwatch.svg";
import StatisticSVG from "../../App/svg/statistic.svg";
import { ReactSVG } from "react-svg";
import "./Menu.css";

interface IProps {
  state: {
    user: UserState,
    system: SystemState
  },
  toggleMenuWindow: () => ToggleMenuWindowAction
}

const Menu: React.FunctionComponent<IProps> = ({ state, toggleMenuWindow }) => {
  const { user } = state;
  const { menuWindow } = state.system;

  if (!user.authenticated) {
    return (
      <>
        <div className="menu_btn" onClick={toggleMenuWindow}><ReactSVG src={BarsSVG} /></div>
        {menuWindow &&
          <div className="menu_window">
            <div className="menu_items">
              <div className="menu_item">
                <div className="menu_item_info">
                  <div className="menu_item_title">Регистрация</div>
                </div>
                <div className="menu_item_icon"><ReactSVG src={UserSVG} /></div>
              </div>
              <div className="menu_item">
                <div className="menu_item_info">
                  <div className="menu_item_title">Партнерская программа</div>
                  <div className="menu_item_description">Взаимовыгодное сотрудничество</div>
                </div>
                <div className="menu_item_icon"><ReactSVG src={PartnerSVG} /></div>
              </div>
              <div className="menu_item">
                <div className="menu_item_info">
                  <div className="menu_item_title">Свободные заказы</div>
                </div>
                <div className="menu_item_icon"><ReactSVG src={StopWatchSVG} /></div>
              </div>
              <div className="menu_item">
                <div className="menu_item_info">
                  <div className="menu_item_title">Помощь</div>
                </div>
                <div className="menu_item_icon"><ReactSVG src={HelpSVG} /></div>
              </div>

            </div>
            <div className="version_info">Версия сайта: 0.01</div>
          </div>
        }
      </>
    )
  } else {
    return (
      <>
        <div className="menu_btn" onClick={toggleMenuWindow}><ReactSVG src={BarsSVG} /></div>
        {menuWindow &&
          <div className="menu_window">
            <div className="menu_window_header">
              <Link to="/profile"><div className="user_avatar"></div></Link>
              <div className="user_info">
                <div className="user_fullname">{user.full_name}</div>
              </div>
            </div>
            <div className="menu_items">
              <Link to="/profile">
                <div className="menu_item">
                  <div className="menu_item_info">
                    <div className="menu_item_title">Профиль</div>
                  </div>
                  <div className="menu_item_icon"><ReactSVG src={UserSVG} /></div>
                </div>
              </Link>
              <div className="menu_item">
                <div className="menu_item_info">
                  <div className="menu_item_title">Партнерская программа</div>
                  <div className="menu_item_description">Взаимовыгодное сотрудничество</div>
                </div>
                <div className="menu_item_icon"><ReactSVG src={PartnerSVG} /></div>
              </div>
              <div className="menu_item">
                <div className="menu_item_info">
                  <div className="menu_item_title">Выполнение заказа</div>
                </div>
                <div className="menu_item_icon"><ReactSVG src={CargoTruckSVG} /></div>
              </div>
              <div className="menu_item">
                <div className="menu_item_info">
                  <div className="menu_item_title">Свободные заказы</div>
                </div>
                <div className="menu_item_icon"><ReactSVG src={StopWatchSVG} /></div>
              </div>
              <div className="menu_item">
                <div className="menu_item_info">
                  <div className="menu_item_title">Журнал заказов</div>
                </div>
                <div className="menu_item_icon"><ReactSVG src={JournalSVG} /></div>
              </div>
              <Link to="/statistic">
                <div className="menu_item">
                  <div className="menu_item_info">
                    <div className="menu_item_title">Статистика</div>
                  </div>
                  <div className="menu_item_icon"><ReactSVG src={StatisticSVG} /></div>
                </div>
              </Link>
              <div className="menu_item">
                <div className="menu_item_info">
                  <div className="menu_item_title">Помощь</div>
                </div>
                <div className="menu_item_icon"><ReactSVG src={HelpSVG} /></div>
              </div>

            </div>
            <div className="version_info">Версия сайта: 0.01</div>
          </div>
        }
      </>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  state: {
    user: state.userReducer,
    system: state.systemReducer
  }
});

export default connect(mapStateToProps, { toggleMenuWindow })(Menu);