import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "../../redux/store";
import { UserState } from "../../redux/reducers/user/@types";
import { StatisticState, SetStatisticAction } from "../../redux/reducers/statistic/@types";
import { setStatistic } from "../../redux/reducers/statistic/actions";
import { userAPI } from "../../api";
import moment, { Moment } from "moment";
import "./Statistic.css";

interface IProps {
  state: {
    user: UserState;
    statistic: StatisticState;
  },
  setStatistic: ({ debit, credit, price, fuel_rate, distance }: StatisticState) => SetStatisticAction;
}

const MONTHS: { [key: string]: string } = {
  "01": "Январь",
  "02": "Февраль",
  "03": "Март",
  "04": "Апрель",
  "05": "Май",
  "06": "Июнь",
  "07": "Июль",
  "08": "Август",
  "09": "Сентябрь",
  "10": "Октябрь",
  "11": "Ноябрь",
  "12": "Декабрь",
}

interface ILocalState {
  date_from: Moment;
  date_to: Moment;
}

const Statistic: React.FunctionComponent<IProps> = ({ state, setStatistic }) => {
  const [localState, setLocalstate] = useState<ILocalState>({
    date_from: moment().startOf("month"),
    date_to: moment().endOf("month"),
  });

  const { date_from, date_to } = localState;
  const { statistic, user } = state;

  const history: any = useHistory();

  useEffect(() => {
    userAPI.statistic({
      driver_id: user.driver_id,
      date_from,
      date_to
    }).then((res) => {
      setStatistic({
        debit: res.debit,
        credit: res.credit,
        price: res.price,
        fuel_rate: res.fuel_rate,
        distance: res.distance
      });
    }).catch();
  }, []);

  const month = MONTHS[moment(date_from).format("MM")];
  const year = moment(date_from).format("YYYY");

  return (
    <div className="statistic_page">
      <div className="page_back_link" onClick={() => history.goBack()}>
        <svg width="1rem" height="1.5rem" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.02348 16.6742C8.23875 16.885 8.51272 17 8.83562 17C9.48141 17 10 16.5017 10 15.8692C10 15.553 9.86301 15.2655 9.63796 15.0451L2.78865 8.49042L9.63796 1.9549C9.86301 1.7345 10 1.43743 10 1.13078C10 0.498309 9.48141 0 8.83562 0C8.51272 0 8.23875 0.114994 8.02348 0.325817L0.410959 7.60879C0.136986 7.85795 0.00978474 8.1646 0 8.5C0 8.8354 0.136986 9.12289 0.410959 9.38162L8.02348 16.6742Z" fill="#888E99"/>
        </svg>
      </div>
      <div className="statistic_header">
        <div className="page_title">Статистика</div>
        <div className="statistic_period_container">
          <div className="statistic_period_title">Период</div>
          <div className="statistic_period_info">
            <div>{ month } { year }</div>
            <div style={{ marginLeft: "0.5rem" }}>
              <svg width="1.2rem" height="1.35rem" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 18H2V7H16V18ZM13 0V2H5V0H3V2H2C0.89 2 0 2.89 0 4V18C0 18.5304 0.210714 19.0391 0.585786 19.4142C0.960859 19.7893 1.46957 20 2 20H16C16.5304 20 17.0391 19.7893 17.4142 19.4142C17.7893 19.0391 18 18.5304 18 18V4C18 2.89 17.1 2 16 2H15V0H13ZM14 11H9V16H14V11Z" fill="#5364FF"/>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className="statistic_container">
        <div className="statistic_money">
          <div className="statistic_debit_credit">
            <div className="statistic_item statistic_debit">
              <div className="statistic_item_name">Доход</div>
              <div className="statistic_item_value">{ statistic.debit } ₽</div>
            </div>
            <div className="statistic_item statistic_credit">
              <div className="statistic_item_name">Расход</div>
              <div className="statistic_item_value">{ statistic.credit } ₽</div>
            </div>
          </div>
          <div className="statistic_price">
            <div className="statistic_item_name">Средняя стоимость часа</div>
            <div className="statistic_item_value">{ statistic.price } ₽/ч</div>
          </div>
        </div>
        <div className="statistic_truck">
          <div className="statistic_fuel_rate">
            <div className="statistic_item_name">Средний расход топлива</div>
            <div className="statistic_item_value">{ statistic.fuel_rate } л/км</div>
          </div>
          <div className="statistic_distance">
            <div className="statistic_item_name">Пробег на заказах</div>
            <div className="statistic_item_value">{ statistic.distance } км</div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  state: {
    statistic: state.statisticReducer,
    user: state.userReducer
  }
});

export default connect(mapStateToProps, { setStatistic })(Statistic);