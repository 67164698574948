import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { RootState } from "../../../redux/store";
import { UserState } from "../../../redux/reducers/user/@types";
import { OrdersState, OrdersActionTypes, Order } from "../../../redux/reducers/orders/@types";
import { setOrders } from "../../../redux/reducers/orders/actions";
import { SetOverlayAction } from "../../../redux/reducers/system/@types";
import { setOverlay } from "../../../redux/reducers/system/actions";
import { ordersAPI } from "../../../api";
import { OrdersListType } from "../../../@types";
import OrdersMenu from "./OrdersMenu/OrdersMenu";
import "./OrdersWindow.css";

interface IProps {
  state: {
    user: UserState,
    orders: OrdersState
  },
  setOrders: (orders: Order[]) => OrdersActionTypes,
  setOverlay: (overlay: boolean) => SetOverlayAction
} 

interface ILocalState {
  type: OrdersListType,
  position: {
    y: number | null;
  }
}

const OrdersWindow: React.FunctionComponent<IProps> = ({ state, setOrders, setOverlay }) => {
  const [localState, setLocalState] = useState<ILocalState>({
    type: OrdersListType.All,
    position: {
      y: null,
    }
  });

  const { type, position } = localState;
  const { authenticated } = state.user;
  const { items } = state.orders;

  const setType = (type: OrdersListType) => {
    setLocalState((s) => ({
      ...s,
      type
    }));  
  }

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    setOverlay(true);

    const touch = e.touches[0] || e.changedTouches[0];
    setLocalState((s) => ({
      ...s,
      position: {
        y: touch.pageY,
      }
    }));
  }

  const handleTouchEnd = () => {
    if (position.y && position.y > window.innerHeight / 2) {
      setOverlay(false);

      setLocalState((s) => ({
        ...s,
        position: {
          y: null,
        }
      }));
    }

    if (position.y && position.y < window.innerHeight / 2) {
      setLocalState((s) => ({
        ...s,
        position: {
          y: 30,
        }
      }));
    }
  }

  useEffect(() => {
    ordersAPI.list(type).then((res) => setOrders(res));
  }, [type]);

  const orders_sum = items.reduce((sum, current) => (sum + current.price), 0);

  if (!authenticated) {
    return (
      <div className="orders_window" style={{ top: (position.y) ? `${position.y}px` : "auto", zIndex: (position.y) ? 20 : 15 }}>

        <div className="orders_general_info">
          <div className="orders_amount">{items.length} доступных заказов</div>
          <div className="orders_sum">Стоимостью до {orders_sum} ₽</div>
        </div>

        <div className={position.y ? "orders_list_vertical" : "orders_list_horizontal"}>
          {items.map((item) => (
            <div key={item.id} className="order_block">
              <div className="order_block_info">
                <div className="order_block_info_left">
                  <div className="order_block_title">3 точки | 21 км</div>
                  <div className="order_block_datetime">Сегодня: 12:00</div>
                </div>
                <div className="order_block_info_right">
                  <div className="order_block_distantion">2 км<br /> от вас</div>
                </div>
              </div>
              <div className="order_block_conditions">
                <ul>
                  <li>1500 кг</li>
                  <li>Любой крытый</li>
                </ul>
              </div>
            </div>
          ))}
        </div>
        
        {!position.y && <OrdersMenu />}
      </div>
    );
  } else {
    return (
      <div className="orders_window" style={{ top: (position.y) ? `${position.y}px` : "auto", zIndex: (position.y) ? 20 : 15 }}>

        <div className="orders_window_btn_container">
          <div className="orders_window_btn"></div>
        </div>

        <div className="orders_general_info" onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
          <div className="orders_window_title">Список заказов</div>
        </div>

        <div className="orders_tabs">
          <div className={type === "all" ? "orders_tab active" : "orders_tab"} onClick={() => setType(OrdersListType.All)}>Все</div>
          <div className={type === "set" ? "orders_tab active" : "orders_tab"} onClick={() => setType(OrdersListType.Set)}>Назначенные</div>
          <div className={type === "offer" ? "orders_tab active" : "orders_tab"} onClick={() => setType(OrdersListType.Offer)}>Предложенные</div>
        </div>
        
        {position.y ? 
          <div className="orders_list_vertical">
            {items.map((item) => (
              <div key={item.id} className={!item.is_look ? "order_block unviewed" : "order_block"}>
                <div className="order_block_info">
                  <div className="order_block_info_left">
                    <div className="order_block_title">{item.address}</div>
                    <div className="order_block_route">3 точки | 21 км</div>
                  </div>
                  <div className="order_block_info_right">
                    <div className="order_block_datetime">Сегодня: 12:00</div>
                    <div className="order_block_distantion">2 км от вас</div>
                  </div>
                </div>
                <div className="order_block_footer">
                  <div className="order_block_conditions">
                    <ul>
                      <li>1500 кг</li>
                      <li>Любой крытый</li>
                    </ul>
                  </div>
                  <div className="order_block_id">№{item.id}</div>
                </div>
              </div>
            ))}
          </div>
        :
          <div className="orders_list_horizontal">
            {items.map((item) => (
              <div key={item.id} className={!item.is_look ? "order_block unviewed" : "order_block"}>
                <div className="order_block_info">
                  <div className="order_block_info_left">
                    <div className="order_block_title">3 точки | 21 км</div>
                    <div className="order_block_datetime">Сегодня: 12:00</div>
                  </div>
                  <div className="order_block_info_right">
                    <div className="order_block_distantion">2 км от вас</div>
                  </div>
                </div>
                <div className="order_block_footer">
                  <div className="order_block_conditions">
                    <ul>
                      <li>1500 кг</li>
                      <li>Любой крытый</li>
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        }

        {!position.y && <OrdersMenu />}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  state: {
    user: state.userReducer,
    orders: state.ordersReducer
  }
});

export default connect(mapStateToProps, { setOrders, setOverlay })(OrdersWindow);