import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import InputMask from "react-input-mask";
import Select from "react-dropdown-select";
import { RootState } from "../../redux/store";
import {
	Passport,
	License,
	Category
} from "../Elements/Containers/@types";
import {
	LicenseDataState,
	SetLicenseNumberEnteredAction,
	SetLicenseIssueDateEnteredAction,
	SetLicenseIssuedByEnteredAction,
	SetCountryEnteredAction,
	SetCategoryEnteredAction,
	AddLicensePhotoAction,
	ReplaceLicensePhotoAction,
	ClearLicensePhotosAction
} from "../../redux/reducers/license_data/@types";
import {
	FinalizeState,
	SetCameraAccessedAction,
	SaveDriverAction,
	UpdateDriverAction
} from "../../redux/reducers/finalize/@types";
import {
	setLicenseNumberEntered,
	setLicenseIssueDateEntered,
	setLicenseIssuedByEntered,
	setCountryEntered,
	setCategoryEntered,
	addLicensePhoto,
	replaceLicensePhoto,
	clearLicensePhotos
} from "../../redux/reducers/license_data/actions";
import {
	setCameraAccessed,
	saveDriver,
	updateDriver
} from "../../redux/reducers/finalize/actions";
import { CancelBtn, PhotoTaker } from "../Elements/elements";
import PhotoSVG from "../App/svg/photo.svg";
import { ReactSVG } from "react-svg";
import { validateOrg, validateDate } from "../../api/validate";
import { editingChars } from "../../@types";
import "./LicenseData.css";

const licenseFrames = [
	{
		index: 0,
		title: "Сторона с фотографией",
		box: true,
		facing: 'environment',
		width: '80%',
		height: '25%'
	},
	{
		index: 1,
		title: "Обратная сторона",
		box: true,
		facing: 'environment',
		width: '80%',
		height: '25%'
	}
];

interface IProps {
  state: LicenseDataState,
  finalize: FinalizeState,
  setLicenseNumberEntered: (number_entered: boolean, number?: string) => SetLicenseNumberEnteredAction,
  setLicenseIssueDateEntered: (issue_date_entered: boolean, issue_date?: string) => SetLicenseIssueDateEnteredAction,
  setLicenseIssuedByEntered: (issued_by_entered: boolean, issued_by?: string) => SetLicenseIssuedByEnteredAction,
  setCountryEntered: (country_entered: boolean, country?: string) => SetCountryEnteredAction,
  setCategoryEntered: (category_entered: boolean, categories?: Category[]) => SetCategoryEnteredAction,
  addLicensePhoto: (photo: Blob) => AddLicensePhotoAction,
  replaceLicensePhoto: (index: number, new_photo: Blob) => ReplaceLicensePhotoAction,
  clearLicensePhotos: () => ClearLicensePhotosAction,
  setCameraAccessed: (camera_accessed: boolean) => SetCameraAccessedAction,
	saveDriver: (is_owner?: boolean, passport?: Passport, license?: License) => SaveDriverAction,
	updateDriver: () => UpdateDriverAction
}

const LicenseData: React.FunctionComponent<IProps> = ({
	state,
	finalize,
	setLicenseNumberEntered,
	setLicenseIssueDateEntered,
	setLicenseIssuedByEntered,
	setCountryEntered,
	setCategoryEntered,
	addLicensePhoto,
	replaceLicensePhoto,
	clearLicensePhotos,
	setCameraAccessed,
	saveDriver,
	updateDriver
}) => {
	const history: any = useHistory();
	const licenseIssueDate = useRef<HTMLInputElement>(null);
	const [cameraShown, setCameraShown] = useState(false);
	const [editing, setEditing] = useState(-1);

	const handleChange = (photos: Blob[]) => {
		setCameraAccessed(true);
		if (editing >= 0) {
			replaceLicensePhoto(editing, photos[0]);
			setEditing(-1);
		} else {
			photos.forEach((photo, index) => {
				replaceLicensePhoto(index, photo);
			});
		}
	}

	useEffect(() => {
		if (licenseIssueDate.current && state.issue_date)
			licenseIssueDate.current.value = state.issue_date;
	}, [state.photos.length, editing]);

	useEffect(() => {
		if (finalize.assigning_driver) {
			setLicenseNumberEntered(false, '');
			setLicenseIssueDateEntered(false, '');
			setLicenseIssuedByEntered(false, '');
			setCountryEntered(false, '');
			setCategoryEntered(false, []);
			clearLicensePhotos();
			if (licenseIssueDate.current)
				licenseIssueDate.current.value = '';
		} else {
			const current = finalize.current_driver;
			setLicenseNumberEntered(current?.license?.number !== undefined &&
				current?.license?.number !== '', current?.license?.number);
			setLicenseIssueDateEntered(current?.license?.issue_date !== undefined &&
				current?.license?.issue_date !== '', current?.license?.issue_date);
			setLicenseIssuedByEntered(current?.license?.issued_by !== undefined &&
				current?.license?.issued_by !== '', current?.license?.issued_by);
			setCountryEntered(current?.license?.country !== undefined &&
				current?.license?.country !== '', current?.license?.country);
			setCategoryEntered(current?.license?.categories !== undefined &&
				current?.license?.categories.length !== 0, current?.license?.categories);
			clearLicensePhotos();
			current?.license?.photos?.forEach((photo) => addLicensePhoto(photo));
			if (licenseIssueDate.current)
				licenseIssueDate.current.value = current?.license?.issue_date || '';
		}
	}, []);

  return (
		<>
			<CancelBtn />
			{cameraShown && (state.photos.length < 2 || editing >= 0) ?
				<PhotoTaker
					id="license_photo"
					title="Сделайте фотографии водительских прав"
					editing={editing >= 0}
					ask={!finalize.camera_accessed}
					frames={editing >= 0 ? [licenseFrames[editing]] : licenseFrames}
					pictures={editing >= 0 ? [state.photos[editing]] : state.photos}
					onChange={(photos: Blob[]) => handleChange(photos)}
				/> :
				<div className="license_window">
					<div className="license_container">
						<div className="license_title">{finalize.is_owner ?
							'Ваше водительское удостоверение' : 'Водительское удостоверение'}</div>
						<div id="license_name"
							className={'license_input' + ((finalize.is_owner && finalize.owner?.passport?.name === '') ||
								(!finalize.is_owner && finalize.current_driver?.passport?.name === '') ?
								'' : ' license_input_complete license_autofill')}>
							<input className="license_input_text" placeholder="ФИО полностью" type="text"
								value={finalize.is_owner ? finalize.owner?.passport?.name :
									finalize.current_driver?.passport?.name} readOnly
							/>
						</div>
						<div className="license_input_container">
							<div id="license_number" className={'license_input license_input_short' +
								(state.number_entered ? ' license_input_complete' : '')}>
								<InputMask mask="99 99 999999" value={state.number} onChange={(e) => {
										if (e.target.value !== '' && e.target.value.indexOf('_') === -1) {
											setLicenseNumberEntered(true, e.target.value);
										} else {
											setLicenseNumberEntered(false);
										}
									}}>
									{(inputProps: any) => <input {...inputProps} className="license_input_text"
										placeholder="Серия и номер" type="text" />}
								</InputMask>
							</div>
							<div id="license_issue_date" className={'license_input license_input_short' +
								(state.issue_date_entered ? ' license_input_complete' : '')}>
								<input className="license_input_text" placeholder="Дата выдачи"
									type="text" ref={licenseIssueDate}
									onFocus={(e) => {
										const text = e.target.value;
										e.target.type ='date';
										if (text !== '') {
											e.target.value = text.slice(6, 10) + '-' +
												text.slice(3, 5) + '-' + text.slice(0, 2);
										}
									}}
									onBlur={(e) => {
										const date = e.target.value;
										e.target.type ='text';
										if (date !== '') {
											e.target.value = date.slice(8, 10) + '.' +
												date.slice(5, 7) + '.' + date.slice(0, 4);
										}
									}}
									onChange={(e) => {
										if (validateDate(e.target.value)) {
											const date = e.target.value;
											setLicenseIssueDateEntered(true, date.slice(8, 10) + '.' +
												date.slice(5, 7) + '.' + date.slice(0, 4));
										} else {
											setLicenseIssueDateEntered(false);
										}
									}}/>
							</div>
						</div>
						<div id="license_issued_by" className={'license_input' + (state.issued_by_entered ?
							' license_input_complete' : '')}>
							<textarea className="license_textarea" placeholder="Кем выдано" rows={2}
								value={state.issued_by} onKeyDown={(e: React.KeyboardEvent) => {
									if (!/[-а-яА-Я0-9№.,/ ()"]/.test(e.key) && !editingChars.includes(e.key)) {
										e.preventDefault();
									}
								}}
								onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
									if (validateOrg(e.target.value)) {
										setLicenseIssuedByEntered(true, e.target.value);
									} else {
										setLicenseIssuedByEntered(false);
									}
								}}
							/>
						</div>
						<div className="license_input_container">
							<div id="license_country" className={'license_input license_input_short' +
								(state.country_entered ? ' license_input_complete' : '')}>
								<input className="license_input_text" placeholder="Страна" type="text"
									value={state.country} onKeyDown={(e: React.KeyboardEvent) => {
										if (!/[-а-яА-Я ]/.test(e.key) && !editingChars.includes(e.key)) {
											e.preventDefault();
										}
									}}
									onChange={(e) => {
										if (e.target.value.length >= 5) {
											setCountryEntered(true, e.target.value);
										} else {
											setCountryEntered(false);
										}
									}}
								/>
							</div>
							<div id="license_category" className={'license_input license_input_short' +
								' license_select_wrapper' + (state.category_entered ? ' license_input_complete' : '')}>
								<Select
									multi={true}
									options={[
									{"key": 0, "value": "A"},
									{"key": 1, "value": "B"},
									{"key": 2, "value": "C"},
									{"key": 3, "value": "B1"},
									{"key": 4, "value": "C1"},
									{"key": 5, "value": "C1E"},
									{"key": 6, "value": "D1"},
									{"key": 7, "value": "D1E"},
									{"key": 8, "value": "BE"},
									{"key": 9, "value": "CE"},
									{"key": 10, "value": "DE"}
								]}
									searchable={false}
									labelField="value"
									valueField="value"
									values={state.categories}
									onChange={(values) => {
										if (values.length !== 0) {
											setCategoryEntered(true, values);
										} else {
											setCategoryEntered(false);
										}
									}}
									contentRenderer={({ props, state }) => (
										<div className="react-dropdown-select-content">
											{state.values.length === 0 ?
												<span className="license_select_placeholder">Категория</span> :
												state.values.map((value) => value.value).join(', ')
											}
										</div>
									)}
								/>
							</div>
						</div>
						{state.photos.length > 0 ?
							<div className="license_photo_container">
								{state.photos.map((photo, index) => (
									<img id={'license_photo_' + index} key={'license_photo_' + index} alt=""
										className="license_photo" src={URL.createObjectURL(photo)}
										onClick={() => setEditing(index)} />
								))}
							</div> :
							<div id="license_photos" className="license_photo_button" onClick={() => setCameraShown(true)}>
								<div className="license_photo_button_text">
									Фотографии удостоверения<br />
									<ReactSVG src={PhotoSVG} />
								</div>
							</div>
						}
						<section className="license_footer">
							<div id="license_back" className="license_link" onClick={() => history.goBack()}>
								{finalize.is_owner ? 'Отменить' : 'Вернуться назад'}</div>
							<div id="license_next" className={'license_button' +
								(state.number_entered && state.issue_date_entered && state.issued_by_entered &&
								state.country_entered && state.category_entered ?
								'' : ' inactive')} onClick={() => {
								saveDriver(finalize.current_driver.is_owner, finalize.current_driver.passport,
									{number: state.number, issue_date: state.issue_date, issued_by: state.issued_by,
									country: state.country, categories: state.categories, photos: state.photos});
								updateDriver();
								setCameraShown(false);
								setTimeout(history.push, 1000, finalize.assigning_driver ? '/vehicle' : '/finalize');
							}}>
				    		<div className="license_button_text">Сохранить</div>
				    	</div>
						</section>
					</div>
				</div>
			}
		</>
	)
}

const mapStateToProps = (state: RootState) => ({
	state: state.licenseDataReducer,
	finalize: state.finalizeReducer
});

export default connect(mapStateToProps, {
	setLicenseNumberEntered,
	setLicenseIssueDateEntered,
	setLicenseIssuedByEntered,
	setCountryEntered,
	setCategoryEntered,
	addLicensePhoto,
	replaceLicensePhoto,
	clearLicensePhotos,
	setCameraAccessed,
	saveDriver,
	updateDriver
})(LicenseData);
