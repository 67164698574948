import { 
  SET_OVERLAY, 
  TOGGLE_MENU_WINDOW, 
  TOGGLE_DATEPICKER_WINDOW,
  TOGGLE_DRIVER_STATUS_WINDOW,
  CLOSE_ALL_WINDOWS,
  SetOverlayAction, 
  ToggleMenuWindowAction,
  ToggleDriverStatusWindowAction,
  ToggleDatepickerWindowAction,
  CloseAllWindowsAction,
} from "./@types";

export const setOverlay = (overlay: boolean): SetOverlayAction => ({
  type: SET_OVERLAY,
  overlay
});

export const toggleMenuWindow = (): ToggleMenuWindowAction => ({
  type: TOGGLE_MENU_WINDOW,
});

export const toggleDatepickerWindow = (): ToggleDatepickerWindowAction => ({
  type: TOGGLE_DATEPICKER_WINDOW,
});

export const toggleDriverStatusWindow = (): ToggleDriverStatusWindowAction => ({
  type: TOGGLE_DRIVER_STATUS_WINDOW,
});

export const closeAllWindows = (): CloseAllWindowsAction => ({
  type: CLOSE_ALL_WINDOWS
});