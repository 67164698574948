export const validateName = (name: string): boolean => {
	if (name === '') return false;
	let words = name.trim().split(' ');
	if (words.length !== 3) return false;
	for (let i = 0; i < 3; i++) {
		if (words[i] === '') return false;
		if (!/[А-Я-]/.test(words[i][0])) return false;
		if (/[^а-я-]/.test(words[i].slice(1))) return false;
	}
	return true;
};

export const validateNumber = (number: string, digits: number): boolean => {
	let cleanNumber = number.replace(/_/g, '');
	return (cleanNumber.length === digits);
};

export const validatePhone = (phone: string): boolean => {
	let cleanPhone = trimPhone(phone);	
	return (cleanPhone.length === 11);
};

export const validateOrg = (org: string): boolean => {
	return (org.length >= 5) && 
		!(/[^-а-яА-Я0-9№.,/ ()"]/.test(org));
};

export const validateDate = (date: string): boolean => {
	return (date.length === 10 && parseInt(date.slice(0, 4)) >= 1900 &&
		parseInt(date.slice(0, 4)) <= new Date().getFullYear());
}

export const trimPhone = (phone: string): string => {
	let cleanPhone = phone.replace(/_/g, '')
		.replace('(', '')
		.replace(')', '')
		.replace('+', '')
		.replace(/ /g, '')
		.replace(/-/g, '');
	return cleanPhone;
};

export const validateEmail = (email: string): boolean => {
	if (email === '') return false;
	let parts = email.split('@');
	if (parts.length !== 2) return false;
	if (parts[0].length === 0) return false;
	if (parts[1].length === 0 || parts[1].indexOf('.') <= 0 ||
		parts[1].indexOf('.') === parts[1].length - 1) return false;
	return true;
};

export const convertDate = (text: string): string => {
	return text.slice(6, 10) + '.' +
		text.slice(3, 5) + '.' + text.slice(0, 2);
}
