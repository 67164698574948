import { UserStatus } from "../../../@types";
import { UserState, SET_AUTHENTICATED, SET_STATUS, UserActionTypes } from "./@types";

let initialState: UserState = {
  authenticated: false,
  driver_id: 0,
  full_name: "",
  status: UserStatus.Unathenticated
}

const userReducer = (state = initialState, action: UserActionTypes) => {
  switch (action.type) {
    case SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: action.authenticated,
        driver_id: action.driver_id,
        full_name: action.full_name,
        status: action.status
      }
    case SET_STATUS:
      return {
        ...state,
        status: action.status
      }
    default:
      return state;
  }
}

export default userReducer;