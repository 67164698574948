export const SET_OVERLAY = 'SET_OVERLAY';
export const TOGGLE_MENU_WINDOW = 'TOGGLE_MENU_WINDOW';
export const TOGGLE_DATEPICKER_WINDOW = 'TOGGLE_DATEPICKER_WINDOW';
export const TOGGLE_DRIVER_STATUS_WINDOW = 'TOGGLE_DRIVER_STATUS_WINDOW';
export const CLOSE_ALL_WINDOWS = 'CLOSE_ALL_WINDOWS';

export interface SetOverlayAction {
  type: typeof SET_OVERLAY;
  overlay: boolean;
}

export interface ToggleMenuWindowAction {
  type: typeof TOGGLE_MENU_WINDOW;
}

export interface ToggleDatepickerWindowAction {
  type: typeof TOGGLE_DATEPICKER_WINDOW;
}

export interface ToggleDriverStatusWindowAction {
  type: typeof TOGGLE_DRIVER_STATUS_WINDOW;
}

export interface CloseAllWindowsAction {
  type: typeof CLOSE_ALL_WINDOWS;
}

export type SystemActionTypes = SetOverlayAction | ToggleMenuWindowAction | ToggleDatepickerWindowAction | ToggleDriverStatusWindowAction | CloseAllWindowsAction;

export interface SystemState {
  overlay: boolean;
  menuWindow: boolean;
  datepickerWindow: boolean;
  driverStatusWindow: boolean;
}