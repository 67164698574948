import {
	FinalizeState,
	SET_FINALIZING,
	SET_ASSIGNING_DRIVER,
	SET_IS_OWNER,
	SET_CAMERA_ACCESSED,
	SAVE_OWNER,
	SAVE_DRIVER,
	UPDATE_DRIVER,
	SAVE_VEHICLE,
	UPDATE_VEHICLE,
	REMOVE_VEHICLE,
	FinalizeActionTypes
} from "./@types";

let initialState: FinalizeState = {
	finalizing: false,
	assigning_driver: false,
	is_owner: false,
	camera_accessed: false,
	owner: {
		owner_type: '',
		tin_type: '',
		tin: '',
		agree: false,
		passport: {
			name: '',
			birthdate: '',
			number: '',
			department: '',
			issue_date: '',
			issued_by: '',
			registration: '',
			address: '',
			same_address: false,
			photos: []
		},
		bank: {
			account: '',
			bic: '',
			name: '',
			corr: '',
			inn: '',
			kpp: '',
			ogrn: '',
			address: ''
		},
		vehicles: [],
		drivers: []
	},
	current_driver: {
		is_owner: false,
		passport: {
			name: '',
			birthdate: '',
			number: '',
			department: '',
			issue_date: '',
			issued_by: '',
			registration: '',
			address: '',
			same_address: false,
			photos: []
		},
		license: {
			number: '',
			issue_date: '',
			issued_by: '',
			country: '',
			categories: [],
			photos: []
		}
	},
	current_vehicle: {
		car_type: '',
		body_option: '',
		characteristics: [],
		car_pass: '',
		hydroboard: '',
		ramp: '',
		length: '',
		width: '',
		height: '',
		address: {
			address: '',
			latitude: '',
			longitude: ''
		},
		photos: [],
		medical_book: false,
		will_help: false,
		driver_loader: false,
		certificate: {
			license_plate: '',
			vin: '',
			brand_model: '',
			vehicle_type: '',
			category: '',
			build_year: '',
			eco_class: '',
			pts_series: '',
			pts_number: '',
			sts_series: '',
			sts_number: '',
			max_weight: '',
			load_weight: '',
			photos: []
		},
		drivers: []
	}
}

const finalizeReducer = (state = initialState, action: FinalizeActionTypes) => {
	switch (action.type) {
		case SET_FINALIZING:
			return {
				...state,
				finalizing: action.finalizing
			}
		case SET_ASSIGNING_DRIVER:
			return {
				...state,
				assigning_driver: action.assigning_driver
			}
		case SET_IS_OWNER:
			return {
				...state,
				is_owner: action.is_owner
			}
		case SET_CAMERA_ACCESSED:
			return {
				...state,
				camera_accessed: action.camera_accessed
			}
		case SAVE_OWNER:
			return {
				...state,
				owner: {
					owner_type: action.owner_type,
					tin_type: action.tin_type,
					tin: action.tin,
					agree: action.agree,
					passport: action.passport,
					bank: action.bank,
					vehicles: action.vehicles,
					drivers: action.drivers
				}
			}
		case SAVE_DRIVER:
			return {
				...state,
				current_driver: {
					is_owner: action.is_owner,
					passport: action.passport,
					license: action.license
				}
			}
		case UPDATE_DRIVER:
			let driver_owner = state.owner;
			if (driver_owner.drivers && driver_owner.drivers[0].passport.name !== '' &&
				driver_owner.drivers[0].passport.name !== undefined) {
				let index = driver_owner.drivers.findIndex((driver) => (
					driver.passport.name === state.current_driver.passport.name
				));
				if (index >= 0) {
					driver_owner.drivers[index] = state.current_driver;
				} else {
					driver_owner.drivers.push(state.current_driver);
				}
			} else {
				driver_owner.drivers = [state.current_driver];
			}
			return {
				...state,
				owner: driver_owner
			}
		case SAVE_VEHICLE:
			return {
				...state,
				current_vehicle: {
					car_type: action.car_type,
					body_option: action.body_option,
					characteristics: action.characteristics,
					car_pass: action.car_pass,
					hydroboard: action.hydroboard,
					ramp: action.ramp,
					length: action.length,
					width: action.width,
					height: action.height,
					address: action.address,
					photos: action.photos,
					medical_book: action.medical_book,
					will_help: action.will_help,
					driver_loader: action.driver_loader,
					certificate: action.certificate,
					drivers: action.drivers
				}
			}
		case UPDATE_VEHICLE:
			let vehicle_owner = state.owner;
			if (vehicle_owner.vehicles && vehicle_owner.vehicles[0].certificate.license_plate !== '' &&
				vehicle_owner.vehicles[0].certificate.license_plate !== undefined) {
				let index = vehicle_owner.vehicles.findIndex((vehicle) => (
					vehicle.certificate.license_plate === state.current_vehicle.certificate.license_plate
				));
				if (index >= 0) {
					vehicle_owner.vehicles[index] = state.current_vehicle;
				} else {
					vehicle_owner.vehicles.push(state.current_vehicle);
				}
			} else {
				vehicle_owner.vehicles = [state.current_vehicle];
			}
			return {
				...state,
				owner: vehicle_owner
			}
		case REMOVE_VEHICLE:
			let temp = state.owner;
			if (temp.vehicles) {
				let index = temp.vehicles.findIndex((vehicle) => (
					vehicle.certificate.license_plate === state.current_vehicle.certificate.license_plate
				));
				if (index >= 0) {
					temp.vehicles.splice(index, 1);
				}
			}
			return {
				...state,
				owner: temp,
				current_vehicle: {
					car_type: '',
					body_option: '',
					characteristics: [],
					car_pass: '',
					hydroboard: '',
					ramp: '',
					length: '',
					width: '',
					height: '',
					address: {
						address: '',
						latitude: '',
						longitude: ''
					},
					photos: [],
					medical_book: false,
					will_help: false,
					driver_loader: false,
					certificate: {
						license_plate: '',
						vin: '',
						brand_model: '',
						vehicle_type: '',
						category: '',
						build_year: '',
						eco_class: '',
						pts_series: '',
						pts_number: '',
						sts_series: '',
						sts_number: '',
						max_weight: '',
						load_weight: '',
						photos: []
					},
					drivers: []
				}
			}
		default:
			return state;
	}
}

export default finalizeReducer;
