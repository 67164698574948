import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../redux/store";
import { UserState } from "../../redux/reducers/user/@types";
import { SystemState } from "../../redux/reducers/system/@types";
import { DatePickerWindow, DriverStatusWindow, Menu, StatusBtn, YandexMap, OrdersWindow, Overlay } from "../Elements/elements";

interface IProps {
  state: {
    user: UserState,
    system: SystemState
  }
}

const MainScreen: React.FunctionComponent<IProps> = ({ state }) => {
  const { user, system } = state;

  return (
    <>
      <Overlay />
      <Menu />
      <StatusBtn />
      <YandexMap />

      {user.authenticated &&
        <>
          <DatePickerWindow />
          <DriverStatusWindow />
        </>
      }

      <OrdersWindow />
    </>
  )
}

const mapStateToProps = (state: RootState) => ({
  state: {
    user: state.userReducer,
    system: state.systemReducer
  }
});

export default connect(mapStateToProps)(MainScreen);