export const SET_ORDERS = 'SET_ORDERS';

export type Order = {
  id: number;
  address: string;
  lon: number;
  lat: number;
  price: number;
  date: Date;
  is_look: boolean;
}

export interface SetOrdersAction {
  type: typeof SET_ORDERS;
  orders: Order[]
}

export type OrdersActionTypes = SetOrdersAction;

export interface OrdersState {
  items: Order[];
};