import { StatisticState, SET_STATISTIC, StatiscticActionTypes } from "./@types";

const initialState: StatisticState = {
  debit: 0,
  credit: 0,
  price: 0,
  fuel_rate: 0,
  distance: 0
} 

const statisticReducer = (state = initialState, action: StatiscticActionTypes) => {
  switch (action.type) {
    case SET_STATISTIC:
      return {
        ...state,
        debit: action.debit,
        credit: action.credit,
        price: action.price,
        fuel_rate: action.fuel_rate,
        distance: action.distance
      }
    default:
      return state;
  }
}

export default statisticReducer;