import React from "react";
import CloseSVG from "../../App/svg/close.svg";
import { ReactSVG } from "react-svg";
import "./CancelBtn.css";

const CancelBtn: React.FunctionComponent = () => {
	return (
		<div className="cancel_btn" onClick={() => {
			window.location.href = '/';
		}}>
			<ReactSVG src={CloseSVG} />
		</div>
	);
}

export default CancelBtn;
