export enum UserStatus {
  Working = "working",
  DayOff = "day_off",
  Unathenticated = "unauthenticated"
}

export enum OrdersListType {
  Free = "free",
  Set = "set",
  Offer = "offer",
  All = "all"
}

export const editingChars = ['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete', 'Tab', 'Home', 'End'];
