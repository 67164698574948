import {
	PassportDataState,
	SET_NAME_ENTERED,
	SET_BIRTHDATE_ENTERED,
	SET_NUMBER_ENTERED,
	SET_DEPARTMENT_ENTERED,
	SET_ISSUE_DATE_ENTERED,
	SET_ISSUED_BY_ENTERED,
	SET_REGISTRATION_ENTERED,
	SET_ADDRESS_ENTERED,
	ADD_PHOTO,
	REPLACE_PHOTO,
	CLEAR_PHOTOS,
	PassportDataActionTypes
} from "./@types";

let initialState: PassportDataState = {
	name_entered: false,
	name: '',
	birthdate_entered: false,
	birthdate: '',
	number_entered: false,
	number: '',
	department_entered: false,
	department: '',
	issue_date_entered: false,
	issue_date: '',
	issued_by_entered: false,
	issued_by: '',
	registration_entered: false,
	registration: '',
	address_entered: false,
	same_address: false,
	address: '',
	photos: []
}

const passportDataReducer = (state = initialState, action: PassportDataActionTypes) => {
	switch (action.type) {
		case SET_NAME_ENTERED:
			return {
				...state,
				name_entered: action.name_entered,
				name: action.name
			}
		case SET_BIRTHDATE_ENTERED:
			return {
				...state,
				birthdate_entered: action.birthdate_entered,
				birthdate: action.birthdate
			}
		case SET_NUMBER_ENTERED:
			return {
				...state,
				number_entered: action.number_entered,
				number: action.number
			}
		case SET_DEPARTMENT_ENTERED:
			return {
				...state,
				department_entered: action.department_entered,
				department: action.department
			}
		case SET_ISSUE_DATE_ENTERED:
			return {
				...state,
				issue_date_entered: action.issue_date_entered,
				issue_date: action.issue_date
			}
		case SET_ISSUED_BY_ENTERED:
			return {
				...state,
				issued_by_entered: action.issued_by_entered,
				issued_by: action.issued_by
			}
		case SET_REGISTRATION_ENTERED:
			return {
				...state,
				registration_entered: action.registration_entered,
				registration: action.registration
			}
		case SET_ADDRESS_ENTERED:
			return {
				...state,
				address_entered: action.address_entered,
				same_address: action.same_address,
				address: action.address
			}
		case ADD_PHOTO:
			let temp = state.photos;
			temp.push(action.photo);
			return {
				...state,
				photos: temp
			}
		case REPLACE_PHOTO:
			let tmp = state.photos;
			if (action.index < tmp.length) {
				tmp[action.index] = action.new_photo;
			}
			return {
				...state,
				photos: tmp
			}
		case CLEAR_PHOTOS:
			return {
				...state,
				photos: []
			}
		default:
			return state;
	}
}

export default passportDataReducer;
