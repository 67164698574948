import { combineReducers, createStore } from "redux";
import {
	ordersReducer,
	statisticReducer,
	userReducer,
	systemReducer,
	checkPhoneReducer,
	ownerSelectReducer,
	bankDataReducer,
	passportDataReducer,
	licenseDataReducer,
	vehicleReducer,
	finalizeReducer,
	quickRegistrationReducer
} from "./reducers";

const reducers = combineReducers({
  ordersReducer,
  statisticReducer,
  userReducer,
  systemReducer,
  checkPhoneReducer,
  ownerSelectReducer,
  bankDataReducer,
  passportDataReducer,
  licenseDataReducer,
  vehicleReducer,
  finalizeReducer,
  quickRegistrationReducer
});

const store = createStore(reducers);

export default store;

export type RootState = ReturnType<typeof reducers>;
