import React from "react";
import { Button } from "../../elements";
import ChatsSVG from "../../../App/svg/chats.svg";
import "./OrdersMenu.css";

const OrdersMenu: React.FunctionComponent = () => {
  return (
    <div className="order_window_links">
      <Button type="referal" className="order_window_btn order_window_btn_referals">Партнерская программа</Button>
      <Button type="default" className="order_window_btn order_window_btn_chats" icon={ChatsSVG}>Чаты</Button>
    </div>
  )
}

export default OrdersMenu;