import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { RootState } from "../../../redux/store";
import { UserState, SetStatusAction } from "../../../redux/reducers/user/@types";
import { SystemState, ToggleDatepickerWindowAction } from "../../../redux/reducers/system/@types";
import { UserStatus } from "../../../@types";
import { setStatus } from "../../../redux/reducers/user/actions";
import { toggleDatepickerWindow } from "../../../redux/reducers/system/actions";
import DayPicker, { DayModifiers } from "react-day-picker";
import CloseSVG from "../../App/svg/close.svg";
import ChevronLeftSVG from "../../App/svg/chevron_left.svg";
import ChevronRightSVG from "../../App/svg/chevron_right.svg";
import { ReactSVG } from "react-svg";
import moment from "moment";
import { userAPI } from "../../../api";
import "react-day-picker/lib/style.css";
import "./DatePickerWindow.css";

interface IProps {
  state: {
    system: SystemState;
    user: UserState;
  },
  toggleDatepickerWindow: () => ToggleDatepickerWindowAction;
  setStatus: (status: UserStatus) => SetStatusAction;
}

interface IState {
  days_off: string[];
}

const MONTHS = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];
const WEEKDAYS_LONG = [
  'Воскресенье',
  'Понедельник',
  'Вторник',
  'Среда',
  'Четверг',
  'Пятница',
  'Суббота',
];
const WEEKDAYS_SHORT = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];

const NavBar = ({ onPreviousClick, onNextClick }: any) => {
  return (
    <div className="datepicker_navbar">
      <div className="datepicker_navbar_item" onClick={() => onPreviousClick()}><ReactSVG src={ChevronLeftSVG} /></div>
      <div className="datepicker_navbar_item" onClick={() => onNextClick()}><ReactSVG src={ChevronRightSVG} /></div>
    </div>
  )
}

const DatePickerWindow: React.FunctionComponent<IProps> = ({ state, toggleDatepickerWindow, setStatus }) => {
  const { user } = state;
  const { datepickerWindow } = state.system;
  const [localState, setLocalState] = useState<IState>({ days_off: [] });
  const { days_off } = localState;

  const setDayOff = (day: Date, modifiers: DayModifiers) => {
    if (days_off.indexOf(moment(day).format("YYYY-MM-DD")) < 0 && !modifiers.disabled) {
      // если выбранный день уже не является выходным
      userAPI.setDriverStatus({
        id: user.driver_id,
        status: (moment(day).isSame(moment())) ? UserStatus.DayOff : UserStatus.Working,
        dates: [moment(day).format("YYYY-MM-DD")]
      }).then((res) => {
        setLocalState((s) => ({
          ...s,
          days_off: [moment(day).format("YYYY-MM-DD")]
        }));

        setStatus(res.status);
      }).catch();
    }
  }

  const renderDay = (date: Date, modifiers: DayModifiers) => {
    const day_off = days_off.indexOf(moment(date).format("YYYY-MM-DD")) !== -1; 

    return (
      <div className={day_off ? "datepicker_day datepicker_day_off" : "datepicker_day"}><div>{moment(date).format("D")}</div></div>
    );
  }

  useEffect(() => {
    userAPI.getDriverStatus(user.driver_id).then((res) => {
      setStatus(res.status);

      setLocalState((s) => ({
        ...s,
        days_off: res.dates
      }));
    }).catch();
  }, []); 

  return (
    <>
      {datepickerWindow &&
        <div className="datepicker_window_container">
          <div className="datepicker_window">
            <div className="window_close_btn" onClick={toggleDatepickerWindow}><ReactSVG src={CloseSVG} /></div>

            <DayPicker 
              className="datepicker"
              locale="it"
              navbarElement={<NavBar />}
              months={MONTHS}
              weekdaysLong={WEEKDAYS_LONG}
              weekdaysShort={WEEKDAYS_SHORT}
              firstDayOfWeek={1}
              showOutsideDays={false}
              enableOutsideDaysClick={false}
              modifiers={{
                disabled: {
                  before: moment().add(3, "days").toDate()
                }
              }}
              onDayClick={setDayOff}
              renderDay={renderDay}
            />

            <div className="datepicker_info">
              <div className="datepicker_info_item">Вы можете взять выходной на один день через 3 дня после сегодняшнего дня</div>
              <div className="datepicker_info_item">У вас может быть 2 выходных в месяц</div>
            </div>
          </div>
        </div>
      } 
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  state: {
    system: state.systemReducer,
    user: state.userReducer
  }
});

export default connect(mapStateToProps, { toggleDatepickerWindow, setStatus })(DatePickerWindow);