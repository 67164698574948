import { 
  SystemState, 
  SET_OVERLAY, 
  TOGGLE_MENU_WINDOW,
  TOGGLE_DATEPICKER_WINDOW,
  TOGGLE_DRIVER_STATUS_WINDOW, 
  SystemActionTypes, 
  CLOSE_ALL_WINDOWS
} from "./@types";

const initialState: SystemState = {
  overlay: false,
  menuWindow: false,
  datepickerWindow: false,
  driverStatusWindow: false
}

const systemReducer = (state = initialState, action: SystemActionTypes) => {
  switch (action.type) {
    case SET_OVERLAY:
      return {
        ...state,
        overlay: action.overlay
      }
    case TOGGLE_MENU_WINDOW:
      return {
        ...state,
        menuWindow: !state.menuWindow,
        datepickerWindow: false,
        driverStatusWindow: false,
        overlay: !state.menuWindow
      }
    case TOGGLE_DATEPICKER_WINDOW:
      return {
        ...state,
        overlay: !state.datepickerWindow,
        datepickerWindow: !state.datepickerWindow,
        menuWindow: false,
        driverStatusWindow: false
      }
    case TOGGLE_DRIVER_STATUS_WINDOW:
      return {
        ...state,
        overlay: !state.driverStatusWindow,
        driverStatusWindow: !state.driverStatusWindow,
        menuWindow: false,
        datepickerWindow: false
      }
    case CLOSE_ALL_WINDOWS:
      return {
        ...state,
        overlay: false,
        menuWindow: false,
        driverStatusWindow: false,
        datepickerWindow: false
      }
    default:
      return state;
  }
}

export default systemReducer;