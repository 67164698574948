import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import InputMask from "react-input-mask";
import Select from "react-dropdown-select";
import Loader from "react-loader-spinner";
import { AddressSuggestions } from "react-dadata";
import { daDataToken } from "../../deployment";
import { RootState } from "../../redux/store";
import {
	Driver,
	VehicleCertificate,
	Passport,
	License,
	Address
} from "../Elements/Containers/@types";
import {
	VehicleState,
	SetCarTypeEnteredAction,
	SetBodyOptionEnteredAction,
	SetBodyOptionChsEnteredAction,
	SetCarPassEnteredAction,
	SetHydroboardEnteredAction,
	SetRampEnteredAction,
	SetLengthEnteredAction,
	SetWidthEnteredAction,
	SetHeightEnteredAction,
	SetGarageEnteredAction,
	AddVehiclePhotoAction,
	ReplaceVehiclePhotoAction,
	ClearVehiclePhotosAction,
	SetMedicalBookAction,
	SetWillHelpAction,
	SetDriverLoaderAction,
	SetLicensePlateEnteredAction,
	SetVinEnteredAction,
	SetBrandModelEnteredAction,
	SetVehicleTypeEnteredAction,
	SetVehicleCategoryEnteredAction,
	SetYearEnteredAction,
	SetEcologyEnteredAction,
	SetPtsSeriesEnteredAction,
	SetPtsNumberEnteredAction,
	SetStsSeriesEnteredAction,
	SetStsNumberEnteredAction,
	SetMaxWeightEnteredAction,
	SetLoadWeightEnteredAction,
	AddCertificatePhotoAction,
	ReplaceCertificatePhotoAction,
	ClearCertificatePhotosAction,
	AssignDriverAction,
	DismissDriverAction
} from "../../redux/reducers/vehicle/@types";
import {
	FinalizeState,
	SetAssigningDriverAction,
	SetIsOwnerAction,
	SetCameraAccessedAction,
	SaveDriverAction,
	SaveVehicleAction,
	UpdateVehicleAction,
	RemoveVehicleAction
} from "../../redux/reducers/finalize/@types";
import {
	OwnerSelectState,
	MULTIPLE_CARS_OWNER
} from "../../redux/reducers/owner_select/@types";
import {
	setCarTypeEntered,
	setBodyOptionEntered,
	setBodyOptionChsEntered,
	setCarPassEntered,
	setHydroboardEntered,
	setRampEntered,
	setLengthEntered,
	setWidthEntered,
	setHeightEntered,
	setGarageEntered,
	addVehiclePhoto,
	replaceVehiclePhoto,
	clearVehiclePhotos,
	setMedicalBook,
	setWillHelp,
	setDriverLoader,
	setLicensePlateEntered,
	setVinEntered,
	setBrandModelEntered,
	setVehicleTypeEntered,
	setVehicleCategoryEntered,
	setYearEntered,
	setEcologyEntered,
	setPtsSeriesEntered,
	setPtsNumberEntered,
	setStsSeriesEntered,
	setStsNumberEntered,
	setMaxWeightEntered,
	setLoadWeightEntered,
	addCertificatePhoto,
	replaceCertificatePhoto,
	clearCertificatePhotos,
	assignDriver,
	dismissDriver
} from "../../redux/reducers/vehicle/actions";
import {
	setAssigningDriver,
	setIsOwner,
	setCameraAccessed,
	saveDriver,
	saveVehicle,
	updateVehicle,
	removeVehicle
} from "../../redux/reducers/finalize/actions";
import {
	CancelBtn,
	Checkbox,
	PhotoTaker,
	VehicleDriverContainer
} from "../Elements/elements";
import PhotoSVG from "../App/svg/photo.svg";
import { ReactSVG } from "react-svg";
import { editingChars } from "../../@types";
import { vehicleAPI } from "../../api";
import "./Vehicle.css";

const vehicleFrames = [
	{
		index: 0,
		title: "Кузов изнутри",
		box: false,
		facing: 'environment',
		width: '100%',
		height: '32%'
	},
	{
		index: 1,
		title: "ТС спереди",
		box: false,
		facing: 'environment',
		width: '100%',
		height: '32%'
	},
	{
		index: 2,
		title: "ТС сбоку",
		box: false,
		facing: 'environment',
		width: '100%',
		height: '32%'
	}
];

const certificateFrames = [
	{
		index: 0,
		title: "Сторона 1",
		box: true,
		facing: 'environment',
		width: '80%',
		height: '45%'
	},
	{
		index: 1,
		title: "Сторона 2",
		box: true,
		facing: 'environment',
		width: '80%',
		height: '45%'
	}
];

const emptyLicense = {
	number: '',
	issue_date: '',
	issued_by: '',
	country: '',
	categories: [],
	photos: []
}

interface IProps {
	state: VehicleState,
	finalize: FinalizeState,
	ownerSelect: OwnerSelectState,
	setCarTypeEntered: (car_type_entered: boolean, car_type?: string) => SetCarTypeEnteredAction,
	setBodyOptionEntered: (body_option_entered: boolean, body_option?: string) => SetBodyOptionEnteredAction,
	setBodyOptionChsEntered: (body_option_chs_entered: boolean,
		body_option_chs?: any) => SetBodyOptionChsEnteredAction,
	setCarPassEntered: (car_pass_entered: boolean, car_pass?: string) => SetCarPassEnteredAction,
	setHydroboardEntered: (hydroboard_entered: boolean, hydroboard?: string) => SetHydroboardEnteredAction,
	setRampEntered: (ramp_entered: boolean, ramp?: string) => SetRampEnteredAction,
	setLengthEntered: (length_entered: boolean, length?: string) => SetLengthEnteredAction,
	setWidthEntered: (width_entered: boolean, width?: string) => SetWidthEnteredAction,
	setHeightEntered: (height_entered: boolean, height?: string) => SetHeightEnteredAction,
	setGarageEntered: (garage_entered: boolean, address?: string, latitude?: string,
		longitude?: string) => SetGarageEnteredAction,
	addVehiclePhoto: (photo: Blob) => AddVehiclePhotoAction,
	replaceVehiclePhoto: (index: number, new_photo: Blob) => ReplaceVehiclePhotoAction,
	clearVehiclePhotos: () => ClearVehiclePhotosAction,
	setMedicalBook: (medical_book: boolean) => SetMedicalBookAction,
	setWillHelp: (will_help: boolean) => SetWillHelpAction,
	setDriverLoader: (driver_loader: boolean) => SetDriverLoaderAction,
	setLicensePlateEntered: (license_plate_entered: boolean, license_plate?: string) => SetLicensePlateEnteredAction,
	setVinEntered: (vin_entered: boolean, vin?: string) => SetVinEnteredAction,
	setBrandModelEntered: (brand_model_entered: boolean, brand_model?: string) => SetBrandModelEnteredAction,
	setVehicleTypeEntered: (vehicle_type_entered: boolean, vehicle_type?: string) => SetVehicleTypeEnteredAction,
	setVehicleCategoryEntered: (vehicle_category_entered: boolean,
		vehicle_category?: string) => SetVehicleCategoryEnteredAction,
	setYearEntered: (year_entered: boolean, year?: string) => SetYearEnteredAction,
	setEcologyEntered: (ecology_entered: boolean, ecology?: string) => SetEcologyEnteredAction,
	setPtsSeriesEntered: (pts_series_entered: boolean, pts_series?: string) => SetPtsSeriesEnteredAction,
	setPtsNumberEntered: (pts_number_entered: boolean, pts_number?: string) => SetPtsNumberEnteredAction,
	setStsSeriesEntered: (sts_series_entered: boolean, sts_series?: string) => SetStsSeriesEnteredAction,
	setStsNumberEntered: (sts_number_entered: boolean, sts_number?: string) => SetStsNumberEnteredAction,
	setMaxWeightEntered: (max_weight_entered: boolean, max_weight?: string) => SetMaxWeightEnteredAction,
	setLoadWeightEntered: (load_weight_entered: boolean, load_weight?: string) => SetLoadWeightEnteredAction,
	addCertificatePhoto: (photo: Blob) => AddCertificatePhotoAction,
	replaceCertificatePhoto: (index: number, new_photo: Blob) => ReplaceCertificatePhotoAction,
	clearCertificatePhotos: () => ClearCertificatePhotosAction,
	assignDriver: (driver: Driver) => AssignDriverAction,
	dismissDriver: (driver: Driver) => DismissDriverAction,
	setAssigningDriver: (assigning_driver: boolean) => SetAssigningDriverAction,
	setIsOwner: (is_owner: boolean) => SetIsOwnerAction,
	setCameraAccessed: (camera_accessed: boolean) => SetCameraAccessedAction,
	saveDriver: (is_owner?: boolean, passport?: Passport, license?: License) => SaveDriverAction,
	saveVehicle: (car_type?: string, body_option?: string, characteristics?: any[], car_pass?: string,
		hydroboard?: string, ramp?: string, length?: string, width?: string, height?: string,
		address?: Address, photos?: Blob[], medical_book?: boolean, will_help?: boolean,
		driver_loader?: boolean, certificate?: VehicleCertificate, drivers?: Driver[]) => SaveVehicleAction,
	updateVehicle: () => UpdateVehicleAction,
	removeVehicle: () => RemoveVehicleAction
}

const Vehicle: React.FunctionComponent<IProps> = ({
	state,
	finalize,
	ownerSelect,
	setCarTypeEntered,
	setBodyOptionEntered,
	setBodyOptionChsEntered,
	setCarPassEntered,
	setHydroboardEntered,
	setRampEntered,
	setLengthEntered,
	setWidthEntered,
	setHeightEntered,
	setGarageEntered,
	addVehiclePhoto,
	replaceVehiclePhoto,
	clearVehiclePhotos,
	setMedicalBook,
	setWillHelp,
	setDriverLoader,
	setLicensePlateEntered,
	setVinEntered,
	setBrandModelEntered,
	setVehicleTypeEntered,
	setVehicleCategoryEntered,
	setYearEntered,
	setEcologyEntered,
	setPtsSeriesEntered,
	setPtsNumberEntered,
	setStsSeriesEntered,
	setStsNumberEntered,
	setMaxWeightEntered,
	setLoadWeightEntered,
	addCertificatePhoto,
	replaceCertificatePhoto,
	clearCertificatePhotos,
	assignDriver,
	dismissDriver,
	setAssigningDriver,
	setIsOwner,
	setCameraAccessed,
	saveDriver,
	saveVehicle,
	updateVehicle,
	removeVehicle
}) => {
	const history: any = useHistory();
	const [formState, setFormState] = useState(ownerSelect.owner_type === MULTIPLE_CARS_OWNER &&
		finalize.assigning_driver ? 'final' : 'characteristics');
	const garageAddress = useRef<AddressSuggestions>(null);
	const [cameraShown, setCameraShown] = useState(false);
	const [vehicleEditing, setVehicleEditing] = useState(-1);
	const [certificateEditing, setCertificateEditing] = useState(-1);
	const [editing, setEditing] = useState(false);

	const [carTypes, setCarTypes] = useState<any>([]);
	const [bodyOptions, setBodyOptions] = useState<any>([]);
	const [bodyOptionChs, setBodyOptionChs] = useState<any>([]);
	const [bodyOptionChValues, setBodyOptionChValues] = useState<any>([]);
	const [carPasses, setCarPasses] = useState<any>([]);
	const [hydroboardTypes, setHydroboardTypes] = useState<any>([]);
	const [rampTypes, setRampTypes] = useState<any>([]);
	const [loadingCarTypes, setLoadingCarTypes] = useState(false);
	const [loadingBodyOptions, setLoadingBodyOptions] = useState(false);
	const [loadingBodyOptionChs, setLoadingBodyOptionChs] = useState(false);
	const [loadingCarPasses, setLoadingCarPasses] = useState(false);

	useEffect(() => {
		const current = finalize.current_vehicle;
		setCarTypeEntered(current?.car_type !== undefined &&
			current?.car_type !== '', current?.car_type);
		setBodyOptionEntered(current?.body_option !== undefined &&
			current?.body_option !== '', current?.body_option);
		setBodyOptionChsEntered(false, current?.characteristics);
		setCarPassEntered(current?.car_pass !== undefined &&
			current?.car_pass !== '', current?.car_pass);
		setHydroboardEntered(current?.hydroboard !== undefined &&
			current?.hydroboard !== '', current?.hydroboard);
		setRampEntered(current?.ramp !== undefined &&
			current?.ramp !== '', current?.ramp);
		setLengthEntered(current?.length !== undefined &&
			current?.length !== '', current?.length);
		setWidthEntered(current?.width !== undefined &&
			current?.width !== '', current?.width);
		setHeightEntered(current?.height !== undefined &&
			current?.height !== '', current?.height);
		setGarageEntered(current?.address !== undefined &&
			current?.address !== null, current?.address?.address,
			current?.address?.latitude, current?.address?.longitude);
		setMedicalBook(current?.medical_book !== undefined ? current?.medical_book : false);
		setWillHelp(current?.will_help !== undefined ? current?.will_help : false);
		setDriverLoader(current?.driver_loader !== undefined ? current?.driver_loader : false);
		clearVehiclePhotos();
		current?.photos?.forEach((photo) => addVehiclePhoto(photo));
		setLicensePlateEntered(current?.certificate?.license_plate !== undefined &&
			current?.certificate?.license_plate !== '', current?.certificate?.license_plate);
		setVinEntered(current?.certificate?.vin !== undefined &&
			current?.certificate?.vin !== '', current?.certificate?.vin);
		setBrandModelEntered(current?.certificate?.brand_model !== undefined &&
			current?.certificate?.brand_model !== '', current?.certificate?.brand_model);
		setVehicleTypeEntered(current?.certificate?.vehicle_type !== undefined &&
			current?.certificate?.vehicle_type !== '', current?.certificate?.vehicle_type);
		setVehicleCategoryEntered(current?.certificate?.category !== undefined &&
			current?.certificate?.category !== '', current?.certificate?.category);
		setYearEntered(current?.certificate?.build_year !== undefined &&
			current?.certificate?.build_year !== '', current?.certificate?.build_year);
		setEcologyEntered(current?.certificate?.eco_class !== undefined &&
			current?.certificate?.eco_class !== '', current?.certificate?.eco_class);
		setPtsSeriesEntered(current?.certificate?.pts_series !== undefined &&
			current?.certificate?.pts_series !== '', current?.certificate?.pts_series);
		setPtsNumberEntered(current?.certificate?.pts_number !== undefined &&
			current?.certificate?.pts_number !== '', current?.certificate?.pts_number);
		setStsSeriesEntered(current?.certificate?.sts_series !== undefined &&
			current?.certificate?.sts_series !== '', current?.certificate?.sts_series);
		setStsNumberEntered(current?.certificate?.sts_number !== undefined &&
			current?.certificate?.sts_number !== '', current?.certificate?.sts_number);
		setMaxWeightEntered(current?.certificate?.max_weight !== undefined &&
			current?.certificate?.max_weight !== '', current?.certificate?.max_weight);
		setLoadWeightEntered(current?.certificate?.load_weight !== undefined &&
			current?.certificate?.load_weight !== '', current?.certificate?.load_weight);
		clearCertificatePhotos();
		current?.certificate?.photos?.forEach((photo) => addCertificatePhoto(photo));
		if (garageAddress.current)
			garageAddress.current.setInputValue(current?.address?.address);

		setLoadingCarTypes(true);
		vehicleAPI.getCarTypes()
			.then((carTypesResponse) => {
				if (carTypesResponse && carTypesResponse.data) {
					setCarTypes(carTypesResponse.data);
					setLoadingCarTypes(false);
					if (current?.car_type !== undefined && current?.car_type !== '') {
						setLoadingBodyOptions(true);
						vehicleAPI.getBodyOptions(current?.car_type)
							.then((bodyOptionsResponse) => {
								if (bodyOptionsResponse && bodyOptionsResponse.data) {
									setBodyOptions(bodyOptionsResponse.data);
									setLoadingBodyOptions(false);
									if (current?.body_option !== undefined && current?.body_option !== '') {
										setLoadingBodyOptionChs(true);
										setHydroboardTypes([]);
										setRampTypes([]);
										vehicleAPI.getBodyOptionChs(current?.body_option,
											carTypesResponse.data.find((carType: any) => (
												carType.id === current?.car_type)).body_type_id,
											current?.car_type)
											.then((characteristicsResponse) => {
												if (characteristicsResponse && characteristicsResponse.data) {
													setBodyOptionChs(characteristicsResponse.data);
													let values: any = [];
													characteristicsResponse.data.forEach((ch: any) => {
														if (ch.type === 'ref') {
															vehicleAPI.getBodyOptionChValues(ch.id, current.car_type)
																.then((valuesResponse) => {
																	if (valuesResponse && valuesResponse.data) {
																		values.push({'id': ch.id, 'values': valuesResponse.data});
																		if (ch.name === 'Гидроборт') {
																			setHydroboardTypes(valuesResponse.data);
																		}
																		if (ch.name === 'Тип пандуса') {
																			setRampTypes(valuesResponse.data);
																		}
																	}
																});
														}
													});
													setBodyOptionChValues(values);
												}
												setLoadingBodyOptionChs(false);
											});
									}
								}
							});
					}
				}
			});
		setLoadingCarPasses(true);
		vehicleAPI.getCarPasses()
			.then((res) => {
				if (res && res.data) {
					setCarPasses(res.data);
				}
				setLoadingCarPasses(false);
			});
	}, []);

	const editVehiclePhotos = (photos: Blob[]) => {
		setCameraAccessed(true);
		if (vehicleEditing >= 0) {
			replaceVehiclePhoto(vehicleEditing, photos[0]);
			setVehicleEditing(-1);
		} else {
			photos.forEach((photo, index) => {
				replaceVehiclePhoto(index, photo);
			});
		}
	}

	const editCertificatePhotos = (photos: Blob[]) => {
		setCameraAccessed(true);
		if (certificateEditing >= 0) {
			replaceCertificatePhoto(certificateEditing, photos[0]);
			setCertificateEditing(-1);
		} else {
			photos.forEach((photo, index) => {
				replaceCertificatePhoto(index, photo);
			});
		}
	}

  return (
		<>
			<CancelBtn />
			{formState === 'characteristics' &&
				<>
					{cameraShown && (state.vehicle_photos.length < 3 || vehicleEditing >= 0) ?
						<PhotoTaker
							id="vehicle_photo"
							title="Сделайте фотографии ТС"
							editing={vehicleEditing >= 0}
							ask={!finalize.camera_accessed}
							frames={vehicleEditing >= 0 ? [vehicleFrames[vehicleEditing]] : vehicleFrames}
							pictures={vehicleEditing >= 0 ? [state.vehicle_photos[vehicleEditing]] : state.vehicle_photos}
							onChange={(photos: Blob[]) => editVehiclePhotos(photos)}
						/> :
						<div className="vehicle_window">
							<div className="vehicle_container">
								<div className="vehicle_title">Характеристики ТС</div>
								<div className="vehicle_input_container">
									<div id="vehicle_car_type" className={'vehicle_select' +
										(state.car_type_entered ? ' vehicle_select_complete' : '')}>
										{carTypes ? null :
											<div className="vehicle_loader">
												<Loader type="Puff" color="#fff" width={'2rem'} height={'2rem'} />
											</div>
										}
										<Select
											options={carTypes || []}
											values={state.car_type !== undefined && state.car_type !== '' ?
												[state.car_type] : []}
											color="transparent"
											className="vehicle_select_color"
											searchable={false}
											labelField="weight_to"
											onChange={() => {}}
											contentRenderer={() => (
												<>
													{state.car_type === undefined || state.car_type === '' ?
														<div className="vehicle_select_placeholder"
															style={{'fontSize': '0.85rem'}}>
															{carTypes ? 'Грузоподъемность' : ''}
														</div> :
														<div className="vehicle_select_text">
															{'До ' + carTypes.find((carType: any) => (
																carType.id === state.car_type))?.weight_to  + ' кг'}
														</div>
													}
												</>
											)}
											dropdownRenderer={({props, methods}) => (
												<>
													{loadingCarTypes ?
														<div className="vehicle_select_nodata">Загрузка...</div> :
														<>
															{props.options.length === 0 ?
																<div className="vehicle_select_nodata">Нет данных</div> :
																<>
																	{props.options.map((item: any, index) => (
																		<span key={'car_type_' + index} tabIndex={1}
																			className="react-dropdown-select-item vehicle_select_item"
																			onClick={() => {
																				methods.addItem(item);
																				if (state.car_type !== item.id)
																					setBodyOptionEntered(false);
																				setCarTypeEntered(true, item.id);
																				setBodyOptionEntered(false, '');
																				setBodyOptionChsEntered(false, []);
																				setHydroboardEntered(false, '');
																				setRampEntered(false, '');
																				setBodyOptionChs([]);
																				setBodyOptionChValues([]);
																				setHydroboardTypes([]);
																				setRampTypes([]);
																				setLoadingBodyOptions(true);
																				vehicleAPI.getBodyOptions(item.id)
																					.then((res) => {
																						if (res && res.data) {
																							setBodyOptions(res.data);
																							setLoadingBodyOptions(false);
																						}
																					});
																			}}>{'До ' + item.weight_to + ' кг'}
																		</span>
																	))}
																</>
															}
														</>
													}	
												</>
											)}
										/>
									</div>
									<div id="vehicle_body_option" className={'vehicle_select' +
										(state.body_option_entered ? ' vehicle_select_complete' : '')}>
										{loadingBodyOptions ?
											<div className="vehicle_loader">
												<Loader type="Puff" color="#fff" width={'2rem'} height={'2rem'} />
											</div> : null
										}
										<Select
											options={bodyOptions || []}
											values={state.body_option !== undefined && state.body_option !== '' ?
												[state.body_option] : []}
											color="transparent"
											className="vehicle_select_color"
											searchable={false}
											labelField="name"
											onChange={() => {}}
											contentRenderer={() => {
												return (
													<>
														{state.body_option_entered ?
															<div className="vehicle_select_text">
																{bodyOptions.find((option: any) => (
																	option.id === state.body_option))?.name}
															</div> :
															<div className="vehicle_select_placeholder">
																{loadingBodyOptions ? '' : 'Вид кузова'}
															</div>
														}
													</>
												);
											}}
											dropdownRenderer={({props, methods}) => (
												<>
													{loadingBodyOptions ?
														<div className="vehicle_select_nodata">Загрузка...</div> :
														<>
															{props.options.length === 0 ?
																<div className="vehicle_select_nodata">Нет данных</div> :
																<>
																	{props.options.map((item: any, index) => (
																		<span key={'body_option_' + index} tabIndex={1}
																			className="react-dropdown-select-item vehicle_select_item"
																			onClick={() => {
																				methods.addItem(item);
																				setBodyOptionEntered(true, item.id);
																				setLoadingBodyOptionChs(true);
																				setHydroboardTypes([]);
																				setRampTypes([]);
																				vehicleAPI.getBodyOptionChs(item.id, item.body_type_id, state.car_type)
																					.then((res) => {
																						if (res && res.data) {
																							setBodyOptionChs(res.data);
																							setBodyOptionChsEntered(false, res.data);
																							let values: any = [];
																							res.data.forEach((ch: any) => {
																								if (ch.type === 'ref') {
																									vehicleAPI.getBodyOptionChValues(ch.id, state.car_type)
																										.then((r) => {
																											if (r && r.data) {
																												values.push({'id': ch.id, 'values': r.data});
																												if (ch.name === 'Гидроборт') {
																													setHydroboardTypes(r.data);
																												}
																												if (ch.name === 'Тип пандуса') {
																													setRampTypes(r.data);
																												}
																											}
																										});
																								}
																							});
																							setBodyOptionChValues(values);
																						}
																					});
																				setHydroboardEntered(false, '');
																				setRampEntered(false, '');
																				setLoadingBodyOptionChs(false);
																			}}>{item.name}
																		</span>
																	))}
																</>
															}
														</>
													}
												</>
											)}
										/>
									</div>
								</div>
								<div className="vehicle_input_container">
									<div id="vehicle_body_option_chs" className={'vehicle_select' +
										(state.body_option_chs_entered ? ' vehicle_select_complete' : '')}>
										{loadingBodyOptionChs ?
											<div className="vehicle_loader">
												<Loader type="Puff" color="#fff" width={'2rem'} height={'2rem'} />
											</div> : null
										}
										<Select
											options={state.car_type_entered && state.body_option_entered ?
												bodyOptionChs || [] : []}
											values={state.body_option_chs}
											color="transparent"
											className="vehicle_select_color"
											searchable={false}
											labelField="name"
											onChange={() => {}}
											contentRenderer={() => {
												return (
													<>
														<div className="vehicle_select_placeholder"
															style={{'fontSize': '1rem'}}>
															{loadingBodyOptionChs ? '' : 'Характеристика'}
														</div>
													</>
												);
											}}
											dropdownRenderer={({props}) => (
												<>
													{loadingBodyOptionChs ?
														<div className="vehicle_select_nodata">Загрузка...</div> :
														<>
															{props.options.length === 0 ?
																<div className="vehicle_select_nodata">Нет данных</div> :
																<>
																	{props.options.map((item: any, index) => (
																		item.type === 'number' ?
																			<div className="react-dropdown-select-item vehicle_chs_item"
																				key={'option_ch_' + index}>
																				<div className="vehicle_select_input_label">{item.name}:</div>
																				<input type="number" className="vehicle_select_input"
																					value={state.body_option_chs.length > 0 ?
																						state.body_option_chs[index].value : ''}
																					onChange={(e) => {
																						let chs = state.body_option_chs;
																						chs[index].value = e.target.value;
																						setBodyOptionChsEntered(true, chs);
																					}}/>
																			</div> : 
																			((item.type === 'Boolean') && (item.name !== 'Медкнижка') &&
																				(item.name !== 'Помощь погрузки/разгрузки') &&
																				(item.name !== 'Водитель грузчик') ?
																				<div className="react-dropdown-select-item vehicle_chs_item"
																					key={'option_ch_' + index}>
																					<table
																						onClick={() => {
																							let chs = state.body_option_chs;
																							chs[index].value = chs.length > 0 ? !chs[index].value : true;
																							setBodyOptionChsEntered(true, chs);
																						}}
																					>
																						<tbody>
																							<tr>
																								<td className="vehicle_select_checkbox_area">
																	 								<Checkbox className="vehicle_select_checkbox"
																	 									checked={state.body_option_chs.length > 0 ?
																	 										state.body_option_chs[index].value : false} />
																	 							</td>
																	 							<td>
																		 							<div className="vehicle_select_label">{item.name}</div>
																		 						</td>
																		 					</tr>
															 							</tbody>
															 						</table>
																				</div> :
																				((item.type === 'ref') && (item.name !== 'Гидроборт') &&
																					(item.name !== 'Тип пандуса') ?
																					<div className="react-dropdown-select-item vehicle_chs_item"
																						key={'option_ch_' + index}>
																						<div className="vehicle_select_input_label">{item.name}:</div>
																						<table>
																							<tbody>
																								{bodyOptionChValues.find((entry: any) => (
																									entry.id === item.id)).values.map((v: any, i: number) => (
																									<tr onClick={() => {
																										let chs = state.body_option_chs;
																										chs[index].value = v.id;
																										setBodyOptionChsEntered(true, chs);
																									}}>
																										<td className="vehicle_select_checkbox_area">
																	 										<Checkbox className="vehicle_select_checkbox"
																	 											checked={state.body_option_chs.length > 0 ?
																	 												state.body_option_chs[index].value === v.id : false} />
																			 							</td>
																			 							<td>
																				 							<div className="vehicle_select_label">{v.name}</div>
																				 						</td>
																				 					</tr>
																				 				))}
																				 			</tbody>
																				 		</table>
																					</div> : null
																				)
																			)
																	))}
																</>
															}
														</>
													}
												</>
											)}
										/>
									</div>
									<div id="vehicle_car_pass" className={'vehicle_select' +
										(state.car_pass_entered ? ' vehicle_select_complete' : '')}>
										{loadingCarPasses && 
											<div className="vehicle_loader">
												<Loader type="Puff" color="#fff" width={'2rem'} height={'2rem'} />
											</div>
										}
										<Select
											options={carPasses || []}
											values={state.car_pass !== undefined && state.car_pass !== '' ?
												[state.car_pass] : []}
											color="transparent"
											className="vehicle_select_color"
											searchable={false}
											labelField="name"
											onChange={() => {}}
											contentRenderer={() => (
												<>
													{state.car_pass === undefined || state.car_pass === '' ?
														<div className="vehicle_select_placeholder">
															{carPasses ? 'Пропуск' : ''}
														</div> :
														<div className="vehicle_select_text">
															{carPasses.find((carPass: any) => (
																carPass.id === state.car_pass))?.name}
														</div>
													}
												</>
											)}
											dropdownRenderer={({props, state, methods}) => (
												<>
													{loadingCarPasses ?
														<div className="vehicle_select_nodata">Загрузка...</div> :
														<>
															{props.options.length === 0 ?
																<div className="vehicle_select_nodata">Нет данных</div> :
																<>
																	{props.options.map((item: any, index) => (
																		<span key={'car_pass_' + index} tabIndex={1}
																			className="react-dropdown-select-item vehicle_select_item"
																			onClick={() => {
																				methods.addItem(item);
																				setCarPassEntered(true, item.id);
																			}}>{item.name}
																		</span>
																	))}
																</>
															}
														</>
													}	
												</>
											)}
										/>
									</div>
								</div>
								<div className="vehicle_input_container">
									<div id="vehicle_hydroboard" className={'vehicle_select' +
										(state.hydroboard_entered ? ' vehicle_select_complete' : '')}>
										{loadingBodyOptionChs ?
											<div className="vehicle_loader">
												<Loader type="Puff" color="#fff" width={'2rem'} height={'2rem'} />
											</div> : null
										}
										<Select
											options={hydroboardTypes.length > 0 ? hydroboardTypes : []}
											values={[state.hydroboard]}
											labelField="name"
											color="transparent"
											className="vehicle_select_color"
											searchable={false}
											onChange={() => {}}
											contentRenderer={() => (
												<>
													{hydroboardTypes.length === 0 || state.hydroboard === undefined ||
														state.hydroboard === '' ?
														<div className="vehicle_select_placeholder">Гидроборт</div> :
														<div className="vehicle_select_text">
															{'Гидроборт: ' + hydroboardTypes.find((hydroboard: any) => (
																hydroboard.id === state.hydroboard)).name + ' кг'}
														</div>
													}
												</>
											)}
											dropdownRenderer={({props, methods}) => (
												<>
													{loadingBodyOptionChs ?
														<div className="vehicle_select_nodata">Загрузка...</div> :
														<>
															{props.options.length === 0 ?
																<div className="vehicle_select_nodata">Нет данных</div> :
																<>
																	{props.options.map((item: any, index: number) => (
																		<span key={'hydroboard_' + index} tabIndex={1}
																			className="react-dropdown-select-item vehicle_select_item"
																			onClick={() => {
																				methods.addItem(item);
																				setHydroboardEntered(true, item.id);
																			}}>
																			{item.name + ' кг'}
																		</span>
																	))}
																</>
															}
														</>
													}
												</>
											)}
										/>
									</div>
									<div id="vehicle_ramp" className={'vehicle_select' +
										(state.ramp_entered ? ' vehicle_select_complete' : '')}>
										{loadingBodyOptionChs ?
											<div className="vehicle_loader">
												<Loader type="Puff" color="#fff" width={'2rem'} height={'2rem'} />
											</div> : null
										}
										<Select
											options={rampTypes.length > 0 ? rampTypes : []}
											values={[state.ramp]}
											labelField="name"
											color="transparent"
											className="vehicle_select_color"
											searchable={false}
											onChange={() => {}}
											contentRenderer={() => (
												<>
													{state.ramp === undefined || state.ramp === '' ?
														<div className="vehicle_select_placeholder">Под пандус</div> :
														<div className="vehicle_select_text">
															{'Под пандус: ' + state.ramp + ' см'}
														</div>
													}
												</>
											)}
											dropdownRenderer={({props, methods}) => (
												<>
													{state.body_option === undefined || state.body_option === '' ?
														<div className="vehicle_select_nodata">Нет данных</div> :
														<>
															{loadingBodyOptionChs ?
																<div className="vehicle_select_nodata">Загрузка...</div> :
																<>
																	{props.options.map((item: any, index: number) => (
																		<span key={'ramp_' + index} tabIndex={1}
																			className="react-dropdown-select-item vehicle_select_item"
																			onClick={() => {
																				methods.addItem(item);
																				setRampEntered(true, item.name);
																			}}>
																			{item.name + ' см'}
																		</span>
																	))}
																	<span key="ramp_custom" tabIndex={1}
																		className="react-dropdown-select-item vehicle_select_item">
																		<input type="number" className="vehicle_select_input"
																			style={{'marginLeft': '-0.5rem', 'width': '85%'}}
																			placeholder="Иное" onKeyPress={
																				(e: React.KeyboardEvent<HTMLInputElement>) => {
																				let target = e.target as HTMLInputElement;
																				if (e.key === 'Enter' && parseInt(target.value) > 0) {
																					methods.addItem(target.value);
																					setRampEntered(true, target.value);
																				}
																			}}/>
																		<span className="vehicle_select_item">&nbsp;см</span>
																	</span>
																</>
															}
														</>
													}
												</>
											)}
										/>
									</div>
								</div>
								<div className="vehicle_input_container">
									<div id="vehicle_length" className={'vehicle_input vehicle_input_tiny' +
										(state.length_entered ? ' vehicle_input_complete' : '')}>
										<input className="vehicle_input_text" placeholder="Длина" type="number"
											value={state.length} onKeyPress={(e) => {
												let target = e.target as HTMLInputElement;
												if (target.value.length === 5 && !editingChars.includes(e.key)) {
													e.preventDefault();
												}
											}}
											onChange={(e) => {
												if (parseInt(e.target.value) > 0) {
													setLengthEntered(true, e.target.value);
												} else {
													setLengthEntered(false);
												}
											}}
										/>
									</div>
									<div id="vehicle_width" className={'vehicle_input vehicle_input_tiny' +
										(state.width_entered ? ' vehicle_input_complete' : '')}>
										<input className="vehicle_input_text" placeholder="Ширина" type="number"
											value={state.width} onKeyPress={(e) => {
												let target = e.target as HTMLInputElement;
												if (target.value.length === 5 && !editingChars.includes(e.key)) {
													e.preventDefault();
												}
											}}
											onChange={(e) => {
												if (parseInt(e.target.value) > 0) {
													setWidthEntered(true, e.target.value);
												} else {
													setWidthEntered(false);
												}
											}}
										/>
									</div>
									<div id="vehicle_height" className={'vehicle_input vehicle_input_tiny' +
										(state.height_entered ? ' vehicle_input_complete' : '')}>
										<input className="vehicle_input_text" placeholder="Высота" type="number"
											value={state.height} onKeyPress={(e) => {
												let target = e.target as HTMLInputElement;
												if (target.value.length === 5 && !editingChars.includes(e.key)) {
													e.preventDefault();
												}
											}}
											onChange={(e) => {
												if (parseInt(e.target.value) > 0) {
													setHeightEntered(true, e.target.value);
												} else {
													setHeightEntered(false);
												}
											}}
										/>
									</div>
								</div>
								<div id="vehicle_address" className={'vehicle_input' + (state.garage_entered ?
									' vehicle_input_complete' : '')}>
									<AddressSuggestions
										token={daDataToken}
										ref={garageAddress}
										defaultQuery={state.address}
										count={5}
										onChange={(suggestion) => {
											if (suggestion && suggestion.value) {
												setGarageEntered(true, suggestion.value, suggestion.data?.geo_lat || '',
													suggestion.data?.geo_lon || '');
											}
										}}
										inputProps={{className: 'vehicle_input_text', placeholder: 'Адрес гаража', type: 'text',
											onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
												setGarageEntered(false, '', '', '');
											}
										}}
									/>
								</div>
								{state.vehicle_photos.length > 0 ?
									<div className="vehicle_photo_container">
										{state.vehicle_photos.map((photo, index) => (
											<img id={'vehicle_photo_' + index} key={'vehicle_photo_' + index} alt=""
												className="vehicle_photo" src={URL.createObjectURL(photo)}
												onClick={() => setVehicleEditing(index)} />
										))}
									</div> :
									<div id="vehicle_photos" className="vehicle_photo_button" onClick={() => setCameraShown(true)}>
										<div className="vehicle_photo_button_text">
											Фотографии ТС<br />
											<ReactSVG src={PhotoSVG} />
										</div>
									</div>
								}
								<table className="vehicle_table">
									<tbody>
										<tr id="vehicle_medical_book" className="vehicle_table_row"
											onClick={() => setMedicalBook(!state.medical_book)}>
											<td className="vehicle_checkbox">
												<Checkbox checked={state.medical_book} />
											</td>
											<td>Есть медкнижка</td>
										</tr>
										<tr id="vehicle_will_help" className="vehicle_table_row"
											onClick={() => setWillHelp(!state.will_help)}>
											<td className="vehicle_checkbox">
												<Checkbox checked={state.will_help} />
											</td>
											<td>Согласен оказывать помощь в погрузке/разгрузке</td>
										</tr>
										<tr id="vehicle_driver_loader" className="vehicle_table_row"
											onClick={() => setDriverLoader(!state.driver_loader)}>
											<td className="vehicle_checkbox">
												<Checkbox checked={state.driver_loader} />
											</td>
											<td>Водитель-грузчик (оплачивается)</td>
										</tr>
									</tbody>
								</table>
								<section className="vehicle_footer">
									<div id="vehicle_remove" className="vehicle_link" onClick={() => {
										removeVehicle();
										history.push('/finalize');
									}}>Удалить ТС</div>
									<div id="vehicle_next" className={'vehicle_button' +
										(state.car_type_entered && state.body_option_entered && state.length_entered &&
										state.width_entered && state.height_entered && state.garage_entered ?
										'' : ' inactive')} onClick={() => {
										setCameraShown(false);
										setTimeout(setFormState, 1000, ownerSelect.owner_type === MULTIPLE_CARS_OWNER &&
											editing ? 'final' : 'certificate');
									}}>
						    		<div className="vehicle_button_text">{ownerSelect.owner_type === MULTIPLE_CARS_OWNER &&
						    			editing ? 'Сохранить' : 'Далее'}</div>
						    	</div>
								</section>
							</div>
						</div>
					}
				</>
			}
			{formState === 'certificate' &&
				<>
					{cameraShown && (state.certificate_photos.length < 2 || certificateEditing >= 0) ?
						<PhotoTaker
							id="certificate_photo"
							title="Сделайте фотографии свидетельства"
							editing={certificateEditing >= 0}
							ask={!finalize.camera_accessed}
							frames={certificateEditing >= 0 ?
								[certificateFrames[certificateEditing]] : certificateFrames}
							pictures={certificateEditing >= 0 ?
								[state.certificate_photos[certificateEditing]] : state.certificate_photos}
							onChange={(photos: Blob[]) => editCertificatePhotos(photos)}
						/> :
						<div className="vehicle_window">
							<div className="vehicle_container">
								<div className="vehicle_title_big">Свидетельство о регистрации</div>
								<div id="vehicle_license_plate" className={'vehicle_input' +
									(state.license_plate_entered ? ' vehicle_input_complete' : '')}>
									<InputMask mask="a 999 aa 999" value={state.license_plate} onChange={(e) => {
										if (e.target.value !== '' && (e.target.value.indexOf('_') === -1 ||
											e.target.value.indexOf('_') === 11)) {
											setLicensePlateEntered(true, e.target.value);
										} else {
											setLicensePlateEntered(false);
										}
									}}>
										{(inputProps: any) => <input {...inputProps} className="vehicle_input_text"
											placeholder="Регистрационный гос. номер" type="text" onKeyPress={(e) => {
												if (/[a-z]/.test(e.key)) {
													e.preventDefault();
													document.execCommand('insertText', false, e.key.toUpperCase());
												}
											}} />
										}
									</InputMask>
								</div>
								<div id="vehicle_vin" className={'vehicle_input' +
									(state.vin_entered ? ' vehicle_input_complete' : '')}>
									<InputMask mask="*****************" value={state.vin} onChange={(e) => {
										if (e.target.value !== '' && e.target.value.indexOf('_') === -1) {
											setVinEntered(true, e.target.value);
										} else {
											setVinEntered(false);
										}
									}}>
										{(inputProps: any) => <input {...inputProps} className="vehicle_input_text"
											placeholder="Номер VIN" type="text" onKeyPress={(e) => {
												if (/[a-z]/.test(e.key)) {
													e.preventDefault();
													document.execCommand('insertText', false, e.key.toUpperCase());
												}
											}} />
										}
									</InputMask>
								</div>
								<div id="vehicle_brand_model" className={'vehicle_input' +
									(state.brand_model_entered ? ' vehicle_input_complete' : '')}>
									<input className="vehicle_input_text" placeholder="Марка и модель ТС"
										type="text" value={state.brand_model} onChange={(e) => {
										if (e.target.value.length >= 3) {
											setBrandModelEntered(true, e.target.value);
										} else {
											setBrandModelEntered(false);
										}
									}} />
								</div>
								<div className="vehicle_input_container">
									<div id="vehicle_type" className={'vehicle_input vehicle_input_tiny' +
										(state.vehicle_type_entered ? ' vehicle_input_complete' : '')}>
										<input className="vehicle_input_text" placeholder="Тип ТС"
											type="text" value={state.vehicle_type} onChange={(e) => {
											if (e.target.value.length >= 5) {
												setVehicleTypeEntered(true, e.target.value);
											} else {
												setVehicleTypeEntered(false);
											}
										}} />
									</div>
									<div id="vehicle_category" className={'vehicle_input vehicle_input_tiny' +
										(state.vehicle_category_entered ? ' vehicle_input_complete' : '')}>
										<input className="vehicle_input_text" placeholder="Категория"
											type="text" value={state.vehicle_category} onChange={(e) => {
											if (e.target.value.length >= 5) {
												setVehicleCategoryEntered(true, e.target.value);
											} else {
												setVehicleCategoryEntered(false);
											}
										}} />
									</div>
									<div id="vehicle_year" className={'vehicle_input vehicle_input_tiny' +
										(state.year_entered ? ' vehicle_input_complete' : '')}>
										<input className="vehicle_input_text small" placeholder="Год выпуска"
											type="number" value={state.year} onKeyPress={(e) => {
												let target = e.target as HTMLInputElement;
												if (target.value.length === 4 && !editingChars.includes(e.key)) {
													e.preventDefault();
												}
											}}
											onChange={(e) => {
											if (parseInt(e.target.value) >= 2000 &&
												parseInt(e.target.value) <= new Date().getFullYear()) {
												setYearEntered(true, e.target.value);
											} else {
												setYearEntered(false);
											}
										}} />
									</div>
								</div>
								<div id="vehicle_ecology" className={'vehicle_input vehicle_select_wrapper' +
									(state.ecology_entered ? ' vehicle_input_complete' : '')}>
									<Select
										options={["1", "2", "3", "4", "5"]}
										placeholder="Экологический класс"
										searchable={false}
										labelField="value"
										valueField="value"
										values={[state.ecology]}
										onChange={(values) => {
											if (values.length !== 0) {
												setEcologyEntered(true, values[0]);
											} else {
												setEcologyEntered(false);
											}
										}}
										contentRenderer={() => (
											<>
												{state.ecology === undefined || state.ecology === '' ?
													<div className="vehicle_select_placeholder" style={{'color': '#888e99'}}>
														Экологический класс
													</div> :
													<div className="vehicle_input_text" style={{'margin': '0'}}>{state.ecology}</div>
												}
											</>
										)}
										dropdownRenderer={({props, methods}) => (
											<>
												{props.options.map((item: any, index) => (
													<span key={'ecology_' + index} tabIndex={1}
														className="react-dropdown-select-item vehicle_select_item"
														onClick={() => {
															methods.addItem(item);
														}}
													>{item}</span>
												))}	
											</>
										)}
									/>
								</div>
								<div className="vehicle_input_container">
									<div id="vehicle_pts_series" className={'vehicle_input vehicle_input_short' +
										(state.pts_series_entered ? ' vehicle_input_complete' : '')}>
										<InputMask mask="99 XX" formatChars={{'9': '[0-9]', 'X': '[A-Za-zА-Яа-я]'}}
											value={state.pts_series} onChange={(e) => {
											if (e.target.value !== '' && e.target.value.indexOf('_') === -1) {
												setPtsSeriesEntered(true, e.target.value);
											} else {
												setPtsSeriesEntered(false);
											}
										}}>
											{(inputProps: any) => <input {...inputProps} className="vehicle_input_text"
												placeholder="Серия ПТС" type="text" onKeyPress={(e) => {
													if (/[a-zа-я]/.test(e.key)) {
														e.preventDefault();
														document.execCommand('insertText', false, e.key.toUpperCase());
													}
												}} />
											}
										</InputMask>
									</div>
									<div id="vehicle_pts_number" className={'vehicle_input vehicle_input_short' +
										(state.pts_number_entered ? ' vehicle_input_complete' : '')}>
										<InputMask mask="999999" value={state.pts_number} onChange={(e) => {
											if (e.target.value !== '' && e.target.value.indexOf('_') === -1) {
												setPtsNumberEntered(true, e.target.value);
											} else {
												setPtsNumberEntered(false);
											}
										}}>
											{(inputProps: any) => <input {...inputProps} className="vehicle_input_text"
												placeholder="Номер ПТС" type="text" />
											}
										</InputMask>
									</div>
								</div>
								<div className="vehicle_input_container">
									<div id="vehicle_sts_series" className={'vehicle_input vehicle_input_short' +
										(state.sts_series_entered ? ' vehicle_input_complete' : '')}>
										<InputMask mask="99 aa" value={state.sts_series} onChange={(e) => {
											if (e.target.value !== '' && e.target.value.indexOf('_') === -1) {
												setStsSeriesEntered(true, e.target.value);
											} else {
												setStsSeriesEntered(false);
											}
										}}>
											{(inputProps: any) => <input {...inputProps} className="vehicle_input_text"
												placeholder="Серия СТС" type="text" onKeyPress={(e) => {
													if (/[a-z]/.test(e.key)) {
														e.preventDefault();
														document.execCommand('insertText', false, e.key.toUpperCase());
													}
												}} />
											}
										</InputMask>
									</div>
									<div id="vehicle_sts_number" className={'vehicle_input vehicle_input_short' +
										(state.sts_number_entered ? ' vehicle_input_complete' : '')}>
										<InputMask mask="999999" value={state.sts_number} onChange={(e) => {
											if (e.target.value !== '' && e.target.value.indexOf('_') === -1) {
												setStsNumberEntered(true, e.target.value);
											} else {
												setStsNumberEntered(false);
											}
										}}>
											{(inputProps: any) => <input {...inputProps} className="vehicle_input_text"
												placeholder="Номер СТС" type="text" />
											}
										</InputMask>
									</div>
								</div>
								<div className="vehicle_input_container">
									<div id="vehicle_max_weight" className={'vehicle_input vehicle_input_short' +
										(state.max_weight_entered ? ' vehicle_input_complete' : '')}>
										<input className="vehicle_input_text small" placeholder="Разрешенная макс. масса"
											type="number" value={state.max_weight} onKeyPress={(e) => {
												let target = e.target as HTMLInputElement;
												if (target.value.length === 5 && !editingChars.includes(e.key)) {
													e.preventDefault();
												}
											}}
											onChange={(e) => {
											if (parseInt(e.target.value) >= 0) {
												setMaxWeightEntered(true, e.target.value);
											} else {
												setMaxWeightEntered(false);
											}
										}} />
									</div>
									<div id="vehicle_load_weight" className={'vehicle_input vehicle_input_short' +
										(state.load_weight_entered ? ' vehicle_input_complete' : '')}>
										<input className="vehicle_input_text small" placeholder="Снаряженная масса"
											type="number" value={state.load_weight} onKeyPress={(e) => {
												let target = e.target as HTMLInputElement;
												if (target.value.length === 5 && !editingChars.includes(e.key)) {
													e.preventDefault();
												}
											}}
											onChange={(e) => {
											if (parseInt(e.target.value) >= 0) {
												setLoadWeightEntered(true, e.target.value);
											} else {
												setLoadWeightEntered(false);
											}
										}} />
									</div>
								</div>
								{state.certificate_photos.length > 0 ?
									<div className="vehicle_photo_container">
										{state.certificate_photos.map((photo, index) => (
											<img id={'certificate_photo_' + index} key={'certificate_photo_' + index} alt=""
												className="vehicle_photo" src={URL.createObjectURL(photo)}
												onClick={() => setCertificateEditing(index)} />
										))}
									</div> :
									<div id="certificate_photos" className="vehicle_photo_button"
										onClick={() => setCameraShown(true)}>
										<div className="vehicle_photo_button_text">
											Фотографии свидетельства<br />
											<ReactSVG src={PhotoSVG} />
										</div>
									</div>
								}
								<section className="vehicle_footer">
									<div id="vehicle_back" className="vehicle_link" onClick={() => {
										if (editing) {
											removeVehicle();
											history.push('/finalize');
										} else {
											setFormState('characteristics');
										}
									}}>{editing ? 'Удалить ТС' : 'Предыдущий шаг'}</div>
									<div id="vehicle_save" className={'vehicle_button' +
										(state.license_plate_entered && state.vin_entered && state.brand_model_entered &&
										state.vehicle_type_entered && state.vehicle_category_entered && state.year_entered &&
										state.ecology_entered && state.sts_series_entered && state.sts_number_entered &&
										state.max_weight_entered && state.load_weight_entered ?
										'' : ' inactive')} onClick={() => {
											saveVehicle(state.car_type, state.body_option, state.body_option_chs, state.car_pass,
												state.hydroboard, state.ramp, state.length, state.width, state.height,
												{address: state.address, latitude: state.latitude, longitude: state.longitude},
												state.vehicle_photos, state.medical_book, state.will_help, state.driver_loader,
												{license_plate: (state.license_plate[11] === '_' ?
													state.license_plate.slice(0, 11) : state.license_plate), vin: state.vin,
												brand_model: state.brand_model, vehicle_type: state.vehicle_type,
												category: state.vehicle_category, build_year: state.year, eco_class: state.ecology,
												pts_series: state.pts_series, pts_number: state.pts_number,
												sts_series: state.sts_series, sts_number: state.sts_number,
												max_weight: state.max_weight, load_weight: state.load_weight,
												photos: state.certificate_photos}, ownerSelect.owner_type === MULTIPLE_CARS_OWNER ?
												state.drivers : [finalize.current_driver]);
											updateVehicle();
											setCameraShown(false);
											setEditing(true);
											if (ownerSelect.owner_type === MULTIPLE_CARS_OWNER) {
												setTimeout(setFormState, 1000, 'final');
											} else {
												setTimeout(history.push, 1000,'/finalize');
											}
										}}>
						    		<div className="vehicle_button_text">Сохранить</div>
						    	</div>
								</section>
							</div>
						</div>
					}
				</>
			}
			{formState === 'final' &&
				<>
					<div className="vehicle_final_window">
						<div className="vehicle_final_container">
							<div className="vehicle_final_back" onClick={() => setFormState('certificate')}></div>
							<div className="vehicle_final_title">
								<div className="vehicle_final_title_name">
									{state.brand_model + ' ' + state.license_plate.replace('_', '')
										.replace(/ /g, '')}
								</div>
								<div className="vehicle_final_title_type">
									{state.drivers.length === 0 ?
										`Не назначены водители` : `Назначено водителей: ${state.drivers.length}`}
								</div>
							</div>
							<VehicleDriverContainer
								id="assign_drivers"
								drivers={finalize.owner.drivers}
								assigned={state.drivers}
								onAddDriver={() => {
									setIsOwner(false);
									setAssigningDriver(true);
									setTimeout(history.push, 1000, '/passport');
								}}
								onAssignSelf={() => {
									setIsOwner(true);
									setAssigningDriver(true);
									saveDriver(true, finalize.owner.passport, emptyLicense);
									setTimeout(history.push, 1000, '/license');
								}}
								onToggleDriver={(index) => {
									if (state.drivers.findIndex((driver) => (
										driver.passport.name === finalize.owner.drivers[index].passport.name)) >= 0) {
										dismissDriver(finalize.owner.drivers[index]);
									} else {
										assignDriver(finalize.owner.drivers[index]);
									}
								}}
							/>
							<div className="vehicle_final_container_wrapper">
								<div id="final_characteristics" className="vehicle_final_container_item"
									onClick={() => setTimeout(setFormState, 1000, 'characteristics')}>
									<div className="vehicle_final_container_item_text">Характеристики ТС</div>
									<div className="vehicle_final_expand"></div>
								</div>
								<div id="final_certificate" className="vehicle_final_container_item"
									onClick={() => setTimeout(setFormState, 1000, 'certificate')}>
									<div className="vehicle_final_container_item_text">Свидетельство о регистрации</div>
									<div className="vehicle_final_expand"></div>
								</div>
							</div>
							<section className="vehicle_footer">
								<div id="vehicle_final_save" className={'vehicle_button' +
									(state.drivers.length > 0 ? '' : ' inactive')} onClick={() => {
										saveVehicle(state.car_type, state.body_option, state.body_option_chs, state.car_pass,
											state.hydroboard, state.ramp, state.length, state.width, state.height,
											{address: state.address, latitude: state.latitude, longitude: state.longitude},
											state.vehicle_photos, state.medical_book, state.will_help,
											state.driver_loader, {license_plate: (state.license_plate[11] === '_' ?
											state.license_plate.slice(0, 11) : state.license_plate), vin: state.vin,
											brand_model: state.brand_model, vehicle_type: state.vehicle_type,
											category: state.vehicle_category, build_year: state.year, eco_class: state.ecology,
											pts_series: state.pts_series, pts_number: state.pts_number,
											sts_series: state.sts_series, sts_number: state.sts_number,
											max_weight: state.max_weight, load_weight: state.load_weight,
											photos: state.certificate_photos}, state.drivers);
										updateVehicle();
										setCameraShown(false);
										setTimeout(history.push, 1000, '/finalize');
									}}>
						    <div className="vehicle_button_text">Сохранить</div>
						   </div>
							</section>
						</div>
					</div>
				</>
			}
		</>
	)
}

const mapStateToProps = (state: RootState) => ({
	state: state.vehicleReducer,
	finalize: state.finalizeReducer,
	ownerSelect: state.ownerSelectReducer
});

export default connect(mapStateToProps, {
	setCarTypeEntered,
	setBodyOptionEntered,
	setBodyOptionChsEntered,
	setCarPassEntered,
	setHydroboardEntered,
	setRampEntered,
	setLengthEntered,
	setWidthEntered,
	setHeightEntered,
	setGarageEntered,
	addVehiclePhoto,
	replaceVehiclePhoto,
	clearVehiclePhotos,
	setMedicalBook,
	setWillHelp,
	setDriverLoader,
	setLicensePlateEntered,
	setVinEntered,
	setBrandModelEntered,
	setVehicleTypeEntered,
	setVehicleCategoryEntered,
	setYearEntered,
	setEcologyEntered,
	setPtsSeriesEntered,
	setPtsNumberEntered,
	setStsSeriesEntered,
	setStsNumberEntered,
	setMaxWeightEntered,
	setLoadWeightEntered,
	addCertificatePhoto,
	replaceCertificatePhoto,
	clearCertificatePhotos,
	assignDriver,
	dismissDriver,
	setIsOwner,
	setAssigningDriver,
	setCameraAccessed,
	saveDriver,
	saveVehicle,
	updateVehicle,
	removeVehicle
})(Vehicle);
