import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import InputMask from "react-input-mask";
import { RootState } from "../../redux/store";
import {
	QuickRegistrationState,
	SetQuickNameEnteredAction,
	SetQuickNumberEnteredAction,
	SetQuickIssueDateEnteredAction,
	SetQuickIssuedByEnteredAction,
	SetQuickBrandModelEnteredAction,
	SetQuickLicensePlateEnteredAction,
	SetQuickAgreeAction
} from "../../redux/reducers/quick_registration/@types";
import {
	setQuickNameEntered,
	setQuickNumberEntered,
	setQuickIssueDateEntered,
	setQuickIssuedByEntered,
	setQuickBrandModelEntered,
	setQuickLicensePlateEntered,
	setQuickAgree
} from "../../redux/reducers/quick_registration/actions";
import { CancelBtn, Checkbox, Response } from "../Elements/elements";
import { validateName, validateOrg, validateDate, convertDate } from "../../api/validate";
import { editingChars } from "../../@types";
import "./QuickRegistration.css";

interface IProps {
  state: QuickRegistrationState,
  setQuickNameEntered: (name_entered: boolean,
  	name?: string) => SetQuickNameEnteredAction,
  setQuickNumberEntered: (number_entered: boolean,
  	number?: string) => SetQuickNumberEnteredAction,
  setQuickIssueDateEntered: (issue_date_entered: boolean,
  	issue_date?: string) => SetQuickIssueDateEnteredAction,
  setQuickIssuedByEntered: (issued_by_entered: boolean,
  	issued_by?: string) => SetQuickIssuedByEnteredAction,
  setQuickBrandModelEntered: (brand_model_entered: boolean,
  	brand_model?: string) => SetQuickBrandModelEnteredAction,
  setQuickLicensePlateEntered: (license_plate_entered: boolean,
  	license_plate?: string) => SetQuickLicensePlateEnteredAction,
  setQuickAgree: (agree: boolean) => SetQuickAgreeAction
}

const QuickRegistration: React.FunctionComponent<IProps> = ({
	state,
	setQuickNameEntered,
	setQuickNumberEntered,
	setQuickIssueDateEntered,
	setQuickIssuedByEntered,
	setQuickBrandModelEntered,
	setQuickLicensePlateEntered,
	setQuickAgree
}) => {
	const history: any = useHistory();
	const [sendingData, setSendingData] = useState(false);
	const [response, setResponse] = useState('');

	const sendData = async () => {
		setSendingData(true);
		const form = {
			full_name: state.name,
			passport_series: state.number.slice(0, 5).replace(' ', ''),
			passport_number: state.number.slice(6),
			issued_by: state.issued_by,
			issue_date: convertDate(state.issue_date),
			brand: state.brand_model.split(' ')[0],
			model: state.brand_model.slice(state.brand_model.indexOf(' ') + 1),
			number: state.license_plate.replace('_', '').replace(/ /g, ''),
			accept_agreement: true
		}
		console.log(form); //TODO
		setSendingData(false);
		setResponse('success');
		//setTimeout(history.push, 5000, '/');
	}

	return (
		<>
			{response === 'success' &&
				<Response
					isError={false}
					name={state.name?.slice(state.name?.indexOf(' ') + 1)}
				/>
			}
			{response === 'error' &&
				<Response
					isError={true}
				/>
			}
			<CancelBtn />
			<div className="quick_window">
				<div className="quick_container">
					<div className="quick_title">Ваш паспорт</div>
					<div id="quick_name" className={'quick_input' + (state.name_entered ?
						' quick_input_complete' : '')}>
						<input className="quick_input_text" placeholder="ФИО полностью" type="text"
							value={state.name} onKeyDown={(e: React.KeyboardEvent) => {
								if (!/[А-Яа-я -]/.test(e.key) && !editingChars.includes(e.key)) {
									e.preventDefault();
								}
							}}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								if (validateName(e.target.value)) {
									setQuickNameEntered(true, e.target.value);
								} else {
									setQuickNameEntered(false);
								}
							}}
						/>
					</div>
					<div className="quick_input_container">
						<div id="quick_number" className={'quick_input quick_input_short' +
							(state.number_entered ? ' quick_input_complete' : '')}>
							<InputMask mask="99 99 999999" value={state.number} onChange={(e) => {
								if (e.target.value !== '' && e.target.value.indexOf('_') === -1) {
									setQuickNumberEntered(true, e.target.value);
								} else {
									setQuickNumberEntered(false);
								}
							}}>
								{(inputProps: any) => <input {...inputProps} className="quick_input_text"
									placeholder="Серия и номер" type="text" />}
							</InputMask>
						</div>
						<div id="quick_issue_date" className={'quick_input quick_input_short' +
							(state.issue_date_entered ? ' quick_input_complete' : '')}>
							<input className="quick_input_text" placeholder="Дата выдачи" type="text"
								onFocus={(e) => {
									const text = e.target.value;
									e.target.type ='date';
									if (text !== '') {
										e.target.value = text.slice(6, 10) + '-' +
											text.slice(3, 5) + '-' + text.slice(0, 2);
									}
								}}
								onBlur={(e) => {
									const date = e.target.value;
									e.target.type ='text';
									if (date !== '') {
										e.target.value = date.slice(8, 10) + '.' +
											date.slice(5, 7) + '.' + date.slice(0, 4);
									}
								}}
								onChange={(e) => {
									if (validateDate(e.target.value)) {
										const date = e.target.value;
										setQuickIssueDateEntered(true, date.slice(8, 10) + '.' +
											date.slice(5, 7) + '.' + date.slice(0, 4));
									} else {
										setQuickIssueDateEntered(false);
									}
								}}
							/>
						</div>
					</div>
					<div id="quick_issued_by" className={'quick_input' + (state.issued_by_entered ?
						' quick_input_complete' : '')}>
						<textarea className="quick_textarea" placeholder="Кем выдан" rows={2}
							value={state.issued_by} onKeyDown={(e: React.KeyboardEvent) => {
								if (!/[-а-яА-Я0-9№.,/ ()"]/.test(e.key) && !editingChars.includes(e.key)) {
									e.preventDefault();
								}
							}}
							onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
								if (validateOrg(e.target.value)) {
									setQuickIssuedByEntered(true, e.target.value);
								} else {
									setQuickIssuedByEntered(false);
								}
							}}
						/>
					</div>
					<div className="quick_title"style={{'marginTop': '1.7rem'}}>Ваш автомобиль</div>
					<div className="quick_input_container">
						<div id="quick_brand_model" className={'quick_input quick_input_short' +
							(state.brand_model_entered ? ' quick_input_complete' : '')}>
							<input className="quick_input_text" placeholder="Марка"
								type="text" value={state.brand_model} onChange={(e) => {
									if (e.target.value.length >= 3) {
										setQuickBrandModelEntered(true, e.target.value);
									} else {
										setQuickBrandModelEntered(false);
									}
								}}
							/>
						</div>
						<div id="quick_license_plate" className={'quick_input quick_input_short' +
							(state.license_plate_entered ? ' quick_input_complete' : '')}>
							<InputMask mask="a 999 aa 999" value={state.license_plate} onChange={(e) => {
								if (e.target.value !== '' && (e.target.value.indexOf('_') === -1 ||
									e.target.value.indexOf('_') === 11)) {
									setQuickLicensePlateEntered(true, e.target.value);
								} else {
									setQuickLicensePlateEntered(false);
								}
							}}>
								{(inputProps: any) => <input {...inputProps} className="quick_input_text"
									placeholder="Номер" type="text" onKeyPress={(e) => {
										if (/[a-z]/.test(e.key)) {
											e.preventDefault();
											document.execCommand('insertText', false, e.key.toUpperCase());
										}
									}} />
								}
							</InputMask>
						</div>
					</div>
					<table className="quick_agree" onClick={(e) => {
						e.preventDefault();
						setQuickAgree(!state.agree);
					}}>
						<tbody>
							<tr>
								<td className="quick_agree_check">
									<Checkbox id="quick_agree" checked={state.agree} />
								</td>
								<td className="quick_agree_text">
									Я принимаю <a onClick={(e) => e.stopPropagation()} target="_blank" href="/terms-of-use" className="quick_agree_link">пользовательское соглашение</a> и <a onClick={(e) => e.stopPropagation()} target="_blank" href="/privacy-policy" className="quick_agree_link">политику конфиденциальности</a>
								</td>
							</tr>
						</tbody>
					</table>
					{sendingData ?
						<div className="quick_sending">Проверяем ваши данные...</div> :
						<div id="quick_finish" className={'quick_button' +
							(state.name_entered && state.number_entered && state.issue_date_entered &&
							state.issued_by_entered && state.brand_model_entered && state.license_plate_entered &&
							state.agree ? '' : ' inactive')}
							onClick={() => setTimeout(sendData, 1000)}>
			    		<div className="quick_button_text">Сохранить</div>
			    	</div>
			    }
				</div>
			</div>
		</>
	)
}

const mapStateToProps = (state: RootState) => ({
	state: state.quickRegistrationReducer
});

export default connect(mapStateToProps, {
	setQuickNameEntered,
	setQuickNumberEntered,
	setQuickIssueDateEntered,
	setQuickIssuedByEntered,
	setQuickBrandModelEntered,
	setQuickLicensePlateEntered,
	setQuickAgree
})(QuickRegistration);
