import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../../redux/store";
import { UserState} from "../../../redux/reducers/user/@types";
import { ToggleDriverStatusWindowAction } from "../../../redux/reducers/system/@types";
import { toggleDriverStatusWindow } from "../../../redux/reducers/system/actions";
import CheckSVG from "../../App/svg/check.svg";
import { ReactSVG } from "react-svg";
import "./StatusBtn.css";

interface IProps {
  state: UserState,
  toggleDriverStatusWindow: () => ToggleDriverStatusWindowAction
}

const StatusBtn: React.FunctionComponent<IProps> = ({ state, toggleDriverStatusWindow }) => {
  const { authenticated, status } = state;

  if (!authenticated) {
    return (
      <div className="status_btn status_btn_guest">
        <div className="status_btn_text">Начать работу</div>
      </div>
    );
  } else {
    switch (status) {
      case 'working':
        return (
          <div className="status_btn status_btn_work" onClick={toggleDriverStatusWindow}>
            <div className="status_btn_text">Работаю</div>
            <div className="status_btn_icon"><ReactSVG src={CheckSVG} /></div>
          </div>
        );
      case 'day_off':
        return (
          <div className="status_btn status_btn_dayoff" onClick={toggleDriverStatusWindow}>
            <div className="status_btn_text">Не работаю</div>
          </div>
        );
      default: 
          return <></>
    }
  }
}

const mapStateToProps = (state: RootState) => ({
  state: state.userReducer
});

export default connect(mapStateToProps, { toggleDriverStatusWindow })(StatusBtn);