import {
	SELECT_OWNER_TYPE,
	SET_TIN_ENTERED,
	SET_AGREE,
	SET_TIN_VERIFYING,
	SelectOwnerTypeAction,
	SetTinEnteredAction,
	SetAgreeAction,
	SetTinVerifyingAction
} from "./@types";

export const selectOwnerType = (owner_type_selected: boolean, owner_type?: string): SelectOwnerTypeAction => ({ 
  type: SELECT_OWNER_TYPE, 
  owner_type_selected,
  owner_type
});

export const setTinEntered = (tin_entered: boolean, tin_type?: string, tin?: string): SetTinEnteredAction => ({ 
  type: SET_TIN_ENTERED, 
  tin_entered,
  tin_type,
  tin
});

export const setAgree = (agree: boolean): SetAgreeAction => ({
	type: SET_AGREE,
	agree
});

export const setTinVerifying = (tin_verifying: boolean): SetTinVerifyingAction => ({
	type: SET_TIN_VERIFYING,
	tin_verifying
});
