import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { AddressSuggestions } from "react-dadata";
import InputMask from "react-input-mask";
import { daDataToken } from "../../deployment";
import { RootState } from "../../redux/store";
import {
	Vehicle,
	Driver,
	Passport,
	License,
	Bank
} from "../Elements/Containers/@types";
import {
	PassportDataState,
	SetNameEnteredAction,
	SetBirthdateEnteredAction,
	SetNumberEnteredAction,
	SetDepartmentEnteredAction,
	SetIssueDateEnteredAction,
	SetIssuedByEnteredAction,
	SetRegistrationEnteredAction,
	SetAddressEnteredAction,
	AddPhotoAction,
	ReplacePhotoAction,
	ClearPhotosAction
} from "../../redux/reducers/passport_data/@types";
import {
	FinalizeState,
	SetCameraAccessedAction,
	SaveOwnerAction,
	SaveDriverAction
} from "../../redux/reducers/finalize/@types";
import {
	OwnerSelectState,
	MULTIPLE_CARS_OWNER
} from "../../redux/reducers/owner_select/@types";
import {
	setNameEntered,
	setBirthdateEntered,
	setNumberEntered,
	setDepartmentEntered,
	setIssueDateEntered,
	setIssuedByEntered,
	setRegistrationEntered,
	setAddressEntered,
	addPhoto,
	replacePhoto,
	clearPhotos
} from "../../redux/reducers/passport_data/actions";
import {
	setCameraAccessed,
	saveOwner,
	saveDriver
} from "../../redux/reducers/finalize/actions";
import { CancelBtn, Checkbox, PhotoTaker } from "../Elements/elements";
import PhotoSVG from "../App/svg/photo.svg";
import { ReactSVG } from "react-svg";
import { validateName, validateOrg, validateDate } from "../../api/validate";
import { editingChars } from "../../@types";
import "./PassportData.css";

const passportFrames = [
	{
		index: 0,
		title: "Разворот",
		box: true,
		facing: 'environment',
		width: '80%',
		height: '45%'
	},
	{
		index: 1,
		title: "Прописка",
		box: true,
		facing: 'environment',
		width: '62%',
		height: '37%'
	},
	{
		index: 2,
		title: "Фото с паспортом в руке",
		box: true,
		facing: 'user',
		width: '90%',
		height: '45%'
	}
];

interface IProps {
  state: PassportDataState,
  finalize: FinalizeState,
  ownerSelect: OwnerSelectState,
  setNameEntered: (name_entered: boolean, name?: string) => SetNameEnteredAction,
  setBirthdateEntered: (birthdate_entered: boolean, birthdate?: string) => SetBirthdateEnteredAction,
  setNumberEntered: (number_entered: boolean, number?: string) => SetNumberEnteredAction,
  setDepartmentEntered: (department_entered: boolean, department?: string) => SetDepartmentEnteredAction,
  setIssueDateEntered: (issue_date_entered: boolean, issue_date?: string) => SetIssueDateEnteredAction,
  setIssuedByEntered: (issued_by_entered: boolean, issued_by?: string) => SetIssuedByEnteredAction,
  setRegistrationEntered: (registration_entered: boolean, registration?: string) => SetRegistrationEnteredAction,
  setAddressEntered: (address_entered: boolean, same_address?: boolean, address?: string) => SetAddressEnteredAction,
  addPhoto: (photo: Blob) => AddPhotoAction,
  replacePhoto: (index: number, new_photo: Blob) => ReplacePhotoAction,
  clearPhotos: () => ClearPhotosAction,
  setCameraAccessed: (camera_accessed: boolean) => SetCameraAccessedAction,
  saveOwner: (owner_type?: string, tin_type?: string, tin?: string,
		agree?: boolean, passport?: Passport, bank?: Bank, vehicles?: Vehicle[],
		drivers?: Driver[]) => SaveOwnerAction,
	saveDriver: (is_owner?: boolean, passport?: Passport, license?: License) => SaveDriverAction
}

const PassportData: React.FunctionComponent<IProps> = ({
	state,
	finalize,
	ownerSelect,
	setNameEntered,
	setBirthdateEntered,
	setNumberEntered,
	setDepartmentEntered,
	setIssueDateEntered,
	setIssuedByEntered,
	setRegistrationEntered,
	setAddressEntered,
	addPhoto,
	replacePhoto,
	clearPhotos,
	setCameraAccessed,
	saveOwner,
	saveDriver
}) => {
	const history: any = useHistory();
	const registration = useRef<AddressSuggestions>(null);
	const address = useRef<AddressSuggestions>(null);
	const birthDate = useRef<HTMLInputElement>(null);
	const issueDate = useRef<HTMLInputElement>(null);
	const [cameraShown, setCameraShown] = useState(false);
	const [editing, setEditing] = useState(-1);

	const handleChange = (photos: Blob[]) => {
		setCameraAccessed(true);
		if (editing >= 0) {
			replacePhoto(editing, photos[0]);
			setEditing(-1);
		} else {
			photos.forEach((photo, index) => {
				replacePhoto(index, photo);
			});
		}
	}

	useEffect(() => {
		if (birthDate.current && state.birthdate)
			birthDate.current.value = state.birthdate;
		if (issueDate.current && state.issue_date)
			issueDate.current.value = state.issue_date;
	}, [state.photos.length, editing]);

	useEffect(() => {
		if (finalize.assigning_driver) {
			setNameEntered(false, '');
			setBirthdateEntered(false, '');
			setNumberEntered(false, '');
			setDepartmentEntered(false, '');
			setIssueDateEntered(false, '');
			setIssuedByEntered(false, '');
			setRegistrationEntered(false, '');
			setAddressEntered(false, false, '');
			clearPhotos();
			if (birthDate.current)
				birthDate.current.value = '';
			if (issueDate.current)
				issueDate.current.value = '';
			if (registration.current)
				registration.current.setInputValue('');
			if (address.current)
				address.current.setInputValue('');
		} else {
			const current = finalize.is_owner ? finalize.owner : finalize.current_driver;
			setNameEntered(current?.passport?.name !== undefined &&
				current?.passport?.name !== '', current?.passport?.name);
			setBirthdateEntered(current?.passport?.birthdate !== undefined &&
				current?.passport?.birthdate !== '', current?.passport?.birthdate);
			setNumberEntered(current?.passport?.number !== undefined &&
				current?.passport?.number !== '', current?.passport?.number);
			setDepartmentEntered(current?.passport?.department !== undefined &&
				current?.passport?.department !== '', current?.passport?.department);
			setIssueDateEntered(current?.passport?.issue_date !== undefined &&
				current?.passport?.issue_date !== '', current?.passport?.issue_date);
			setIssuedByEntered(current?.passport?.issued_by !== undefined &&
				current?.passport?.issued_by !== '', current?.passport?.issued_by);
			setRegistrationEntered(current?.passport?.registration !== undefined &&
				current?.passport?.registration !== '', current?.passport?.registration);
			setAddressEntered(current?.passport?.address !== undefined &&
				current?.passport?.address !== '', current?.passport?.same_address,
				current?.passport?.address);
			clearPhotos();
			current?.passport?.photos?.forEach((photo) => addPhoto(photo));
			if (birthDate.current)
				birthDate.current.value = current?.passport?.birthdate || '';
			if (issueDate.current)
				issueDate.current.value = current?.passport?.issue_date || '';
			if (registration.current)
				registration.current.setInputValue(current?.passport?.registration);
			if (address.current)
				address.current.setInputValue(current?.passport?.same_address ?
					current?.passport?.registration : '');
		}
	}, []);

	return (
		<>
			<CancelBtn />
			{cameraShown && (state.photos.length < 3 || editing >= 0) ?
				<PhotoTaker
					id="passport_photo"
					title="Сделайте фотографии паспорта"
					editing={editing >= 0}
					ask={!finalize.camera_accessed}
					frames={editing >= 0 ? [passportFrames[editing]] : passportFrames}
					pictures={editing >= 0 ? [state.photos[editing]] : state.photos}
					onChange={(photos: Blob[]) => handleChange(photos)}
				/> :
				<div className="passport_window">
					<div className="passport_container">
						<div className="passport_title">{finalize.is_owner ?
							'Ваш паспорт' : 'Паспортные данные'}</div>
						<div id="passport_name" className={'passport_input' + (state.name_entered ?
							' passport_input_complete' : '')}>
							<input className="passport_input_text" placeholder="ФИО полные" type="text"
								value={state.name} onKeyDown={(e: React.KeyboardEvent) => {
									if (!/[А-Яа-я -]/.test(e.key) && !editingChars.includes(e.key)) {
										e.preventDefault();
									}
								}}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									if (validateName(e.target.value)) {
										setNameEntered(true, e.target.value);
									} else {
										setNameEntered(false);
									}
								}}
							/>
						</div>
						<div className="passport_input_container">
							<div id="passport_birthdate" className={'passport_input passport_input_short' +
								(state.birthdate_entered ? ' passport_input_complete' : '')}>
								<input className="passport_input_text" placeholder="Дата рождения"
									type="text" ref={birthDate}
									onFocus={(e) => {
										const text = e.target.value;
										e.target.type ='date';
										if (text !== '') {
											e.target.value = text.slice(6, 10) + '-' +
												text.slice(3, 5) + '-' + text.slice(0, 2);
										}
									}}
									onBlur={(e) => {
										const date = e.target.value;
										e.target.type ='text';
										if (date !== '') {
											e.target.value = date.slice(8, 10) + '.' +
												date.slice(5, 7) + '.' + date.slice(0, 4);
										}
									}}
									onChange={(e) => {
										if (validateDate(e.target.value)) {
											const date = e.target.value;
											setBirthdateEntered(true, date.slice(8, 10) + '.' +
												date.slice(5, 7) + '.' + date.slice(0, 4));
										} else {
											setBirthdateEntered(false);
										}
									}}/>
							</div>
							<div id="passport_number" className={'passport_input passport_input_short' +
								(state.number_entered ? ' passport_input_complete' : '')}>
								<InputMask mask="99 99 999999" value={state.number} onChange={(e) => {
										if (e.target.value !== '' && e.target.value.indexOf('_') === -1) {
											setNumberEntered(true, e.target.value);
										} else {
											setNumberEntered(false);
										}
									}}>
									{(inputProps: any) => <input {...inputProps} className="passport_input_text"
										placeholder="Серия и номер" type="text" />}
								</InputMask>
							</div>
						</div>
						<div className="passport_input_container">
							<div id="passport_department" className={'passport_input passport_input_short' +
								(state.department_entered ? ' passport_input_complete' : '')}>
								<InputMask mask="999-999" value={state.department} onChange={(e) => {
										if (e.target.value !== '' && e.target.value.indexOf('_') === -1) {
											setDepartmentEntered(true, e.target.value);
										} else {
											setDepartmentEntered(false);
										}
									}}>
									{(inputProps: any) => <input {...inputProps} className="passport_input_text"
										placeholder="Код подразделения" type="text" />}
								</InputMask>
							</div>
							<div id="passport_issue_date" className={'passport_input passport_input_short' +
								(state.issue_date_entered ? ' passport_input_complete' : '')}>
								<input className="passport_input_text" placeholder="Дата выдачи"
									type="text" ref={issueDate}
									onFocus={(e) => {
										const text = e.target.value;
										e.target.type ='date';
										if (text !== '') {
											e.target.value = text.slice(6, 10) + '-' +
												text.slice(3, 5) + '-' + text.slice(0, 2);
										}
									}}
									onBlur={(e) => {
										const date = e.target.value;
										e.target.type ='text';
										if (date !== '') {
											e.target.value = date.slice(8, 10) + '.' +
												date.slice(5, 7) + '.' + date.slice(0, 4);
										}
									}}
									onChange={(e) => {
										if (validateDate(e.target.value)) {
											const date = e.target.value;
											setIssueDateEntered(true, date.slice(8, 10) + '.' +
												date.slice(5, 7) + '.' + date.slice(0, 4));
										} else {
											setIssueDateEntered(false);
										}
									}}/>
							</div>
						</div>
						<div id="passport_issued_by" className={'passport_input' + (state.issued_by_entered ?
							' passport_input_complete' : '')}>
							<textarea className="passport_textarea" placeholder="Кем выдан" rows={2}
								value={state.issued_by} onKeyDown={(e: React.KeyboardEvent) => {
									if (!/[-а-яА-Я0-9№.,/ ()"]/.test(e.key) && !editingChars.includes(e.key)) {
										e.preventDefault();
									}
								}}
								onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
									if (validateOrg(e.target.value)) {
										setIssuedByEntered(true, e.target.value);
									} else {
										setIssuedByEntered(false);
									}
								}}
							/>
						</div>
						<div id="passport_registration" className={'passport_input' + (state.registration_entered ?
							' passport_input_complete' : '')}>
							<AddressSuggestions
								token={daDataToken}
								ref={registration}
								defaultQuery={state.registration}
								count={5}
								onChange={(suggestion) => {
									if (suggestion && suggestion.value && suggestion.data.house) {
										setRegistrationEntered(true, suggestion.value);
										if (address.current && state.same_address)
											address.current.setInputValue(suggestion.value);
									}
								}}
								inputProps={{className: 'passport_input_text', placeholder: 'Адрес прописки', type: 'text',
									onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
										setRegistrationEntered(false, '');
									}
								}}
							/>
						</div>
						<div id="passport_address" className={'passport_input' + (state.address_entered ||
							(state.registration_entered && state.same_address) ?
							' passport_input_complete' : '')}>
							<AddressSuggestions
								token={daDataToken}
								ref={address}
								defaultQuery={state.same_address ? state.registration : state.address}
								count={5}
								onChange={(suggestion) => {
									if (suggestion && suggestion.value && suggestion.data.house) {
										setAddressEntered(true, false, suggestion.value);
									}
								}}
								inputProps={{className: 'passport_input_text', placeholder: 'Адрес проживания', type: 'text',
									onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
										setAddressEntered(false, false, '');
									},
									disabled: state.same_address
								}}
							/>
							<Checkbox id="passport_same_address" className="passport_check" checked={state.same_address}
								onClick={() => {
									if (address.current)
										address.current.setInputValue(state.same_address ? '' : state.registration);
									setAddressEntered(state.address_entered && state.same_address, !state.same_address);
								}}
							/>
						</div>
						{state.photos.length > 0 ?
							<div className="passport_photo_container">
								{state.photos.map((photo, index) => (
									<img id={'passport_photo_' + index} key={'passport_photo_' + index} alt=""
										className="passport_photo" src={URL.createObjectURL(photo)}
										onClick={() => setEditing(index)} />
								))}
							</div> :
							<div id="passport_photos" className="passport_photo_button" onClick={() => setCameraShown(true)}>
								<div className="passport_photo_button_text">
									Фотографии паспорта<br />
									<ReactSVG src={PhotoSVG} />
								</div>
							</div>
						}
						<section className="passport_footer">
							<div id="passport_back" className="passport_link" onClick={() => history.goBack()}>Вернуться назад</div>
							<div id="passport_next" className={'passport_button' +
								(state.name_entered && state.birthdate_entered && state.number_entered &&
								state.department_entered && state.issue_date_entered && state.issued_by_entered &&
								state.registration_entered && (state.same_address || state.address_entered) ?
								'' : ' inactive')} onClick={() => {
									const newData = finalize.owner.drivers === undefined || finalize.owner.drivers.length === 0;
									if (finalize.is_owner) {
										saveOwner(finalize.owner.owner_type, finalize.owner.tin_type, finalize.owner.tin,
											finalize.owner.agree,
											{name: state.name, birthdate: state.birthdate, number: state.number,
											department: state.department, issue_date: state.issue_date,
											issued_by: state.issued_by, registration: state.registration,
											address: state.address, same_address: state.same_address, photos: state.photos},
											finalize.owner.bank, finalize.owner.vehicles, finalize.owner.drivers);
									} else {
										saveDriver(false, {name: state.name, birthdate: state.birthdate, number: state.number,
											department: state.department, issue_date: state.issue_date,
											issued_by: state.issued_by, registration: state.registration,
											address: state.address, same_address: state.same_address, photos: state.photos},
											finalize.current_driver.license);
									}
									setCameraShown(false);
									setTimeout(history.push, 1000, finalize.is_owner && (!newData ||
										ownerSelect.owner_type === MULTIPLE_CARS_OWNER) ? '/finalize' : '/license')}}>
				    		<div className="passport_button_text">Сохранить</div>
				    	</div>
						</section>
					</div>
				</div>
			}
		</>
	)
}

const mapStateToProps = (state: RootState) => ({
	state: state.passportDataReducer,
	finalize: state.finalizeReducer,
	ownerSelect: state.ownerSelectReducer
});

export default connect(mapStateToProps, {
	setNameEntered,
	setBirthdateEntered,
	setNumberEntered,
	setDepartmentEntered,
	setIssueDateEntered,
	setIssuedByEntered,
	setRegistrationEntered,
	setAddressEntered,
	addPhoto,
	replacePhoto,
	clearPhotos,
	setCameraAccessed,
	saveOwner,
	saveDriver
})(PassportData);
