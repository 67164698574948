export const SET_ACCOUNT_ENTERED = 'SET_ACCOUNT_ENTERED';
export const SET_BANK_ENTERED = 'SET_BANK_ENTERED';
export const SET_BIC_ENTERED = 'SET_BIC_ENTERED';
export const SET_CORR_ENTERED = 'SET_CORR_ENTERED';
export const SET_INN_ENTERED = 'SET_INN_ENTERED';
export const SET_KPP_ENTERED = 'SET_KPP_ENTERED';
export const SET_OGRN_ENTERED = 'SET_OGRN_ENTERED';
export const SET_BANK_ADDRESS_ENTERED = 'SET_BANK_ADDRESS_ENTERED';

export interface SetAccountEnteredAction {
  type: typeof SET_ACCOUNT_ENTERED;
  account_entered: boolean;
  account?: string;
}

export interface SetBankEnteredAction {
  type: typeof SET_BANK_ENTERED;
  bank_entered: boolean;
  bank?: string;
}

export interface SetBicEnteredAction {
  type: typeof SET_BIC_ENTERED;
  bic_entered: boolean;
  bic?: string;
}

export interface SetCorrEnteredAction {
  type: typeof SET_CORR_ENTERED;
  corr_entered: boolean;
  corr?: string;
}

export interface SetInnEnteredAction {
  type: typeof SET_INN_ENTERED;
  inn_entered: boolean;
  inn?: string;
}

export interface SetKppEnteredAction {
  type: typeof SET_KPP_ENTERED;
  kpp_entered: boolean;
  kpp?: string;
}

export interface SetOgrnEnteredAction {
  type: typeof SET_OGRN_ENTERED;
  ogrn_entered: boolean;
  ogrn?: string;
}

export interface SetBankAddressEnteredAction {
  type: typeof SET_BANK_ADDRESS_ENTERED;
  bank_address_entered: boolean;
  bank_address?: string;
}

export type BankDataActionTypes = SetAccountEnteredAction
	| SetBankEnteredAction
	| SetBicEnteredAction
	| SetCorrEnteredAction
	| SetInnEnteredAction
	| SetKppEnteredAction
	| SetOgrnEnteredAction
	| SetBankAddressEnteredAction;

export interface BankDataState {
	account_entered: boolean;
	account: string;
	bank_entered: boolean;
	bank: string;
	bic_entered: boolean;
	bic: string;
	corr_entered: boolean;
	corr: string;
	inn_entered: boolean;
	inn: string;
	kpp_entered: boolean;
	kpp: string;
	ogrn_entered: boolean;
	ogrn: string;
	bank_address_entered: boolean;
	bank_address: string;
}
