import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../../redux/store";
import { SystemState, CloseAllWindowsAction } from "../../../redux/reducers/system/@types";
import { closeAllWindows } from "../../../redux/reducers/system/actions";
import "./Overlay.css";

interface IProps {
  state: SystemState;
  closeAllWindows: () => CloseAllWindowsAction;
}

const Overlay: React.FunctionComponent<IProps> = ({ state, closeAllWindows }) => {
  const { overlay } = state;

  return (
    <>
      {overlay && <div className="overlay" onClick={closeAllWindows}></div>}
    </>
  )
}

const mapStateToProps = (state: RootState) => ({
  state: state.systemReducer
});

export default connect(mapStateToProps, { closeAllWindows })(Overlay);