import React from "react";
import { ReactSVG } from "react-svg";
import "./Button.css";

interface IProps {
  type: "default" | "referal" | "grey";
  className?: string;
  onClick?: () => void,
  icon?: string;
  style?: object;
}

const Button: React.FunctionComponent<IProps> = (props) => {
  const { type } = props;

  return (
    <div 
      className={`btn ${type && 'btn_' + type}${props.className ? ' '+props.className : ''}`}
      onClick={props.onClick}
      style={props.style}
    >{props.children} {(props.icon) && <ReactSVG src={props.icon} />}</div>
  );
}

export default Button;