import React from "react";
import { Driver } from "./@types";
import "./Container.css";

interface IProps {
	id?: string;
	title?: string;
	drivers: Driver[];
	onAddDriver: () => void;
	onEditDriver: (index: number) => void;
}

const DriverContainer: React.FunctionComponent<IProps> = (props) => {

	return (
		<div id={props.id} className="container">
			<div className="container_title">
				<div className="container_title_text">{props.title ? props.title : 'Водители'}</div>
			</div>
			<div>
				{props.drivers && props.drivers.map((driver: Driver, index: number) => (
					<div key={'driver_' + driver.passport.name} className="container_item"
						onClick={() => props.onEditDriver(index)}>
						<div className="container_item_description">
							<div className="container_item_name">{driver.is_owner ? 'Вы' : driver.passport.name}</div>
							<div className="container_item_value">{`Категория ${driver.license.categories.map(
								(cat) => cat.value).join(', ')}`}</div>
						</div>
						<div className="container_expand"></div>
					</div>
				))}
				<div key="driver_add" className="container_item" onClick={() => props.onAddDriver()}>
					<div className="container_item_action">Добавить водителя</div>
					<div className="container_add">
						<div className="container_add_h"></div>
						<div className="container_add_v"></div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default DriverContainer;
