import {
	Vehicle,
	Driver,
	Passport,
	License,
	Bank,
	VehicleCertificate,
	Address
} from "../../../components/Elements/Containers/@types";
import {
	SET_FINALIZING,
	SET_ASSIGNING_DRIVER,
	SET_IS_OWNER,
	SET_CAMERA_ACCESSED,
	SAVE_OWNER,
	SAVE_DRIVER,
	UPDATE_DRIVER,
	SAVE_VEHICLE,
	UPDATE_VEHICLE,
	REMOVE_VEHICLE,
	SetFinalizingAction,
	SetAssigningDriverAction,
	SetIsOwnerAction,
	SetCameraAccessedAction,
	SaveOwnerAction,
	SaveDriverAction,
	UpdateDriverAction,
	SaveVehicleAction,
	UpdateVehicleAction,
	RemoveVehicleAction
} from "./@types";

export const setFinalizing = (finalizing: boolean): SetFinalizingAction => ({
  type: SET_FINALIZING,
  finalizing
});

export const setAssigningDriver = (assigning_driver: boolean): SetAssigningDriverAction => ({
  type: SET_ASSIGNING_DRIVER,
  assigning_driver
});

export const setIsOwner = (is_owner: boolean): SetIsOwnerAction => ({
  type: SET_IS_OWNER,
  is_owner
});

export const setCameraAccessed = (camera_accessed: boolean): SetCameraAccessedAction => ({
  type: SET_CAMERA_ACCESSED,
  camera_accessed
});

export const saveOwner = (owner_type?: string, tin_type?: string, tin?: string,
	agree?: boolean, passport?: Passport, bank?: Bank, vehicles?: Vehicle[],
	drivers?: Driver[]): SaveOwnerAction => ({
	type: SAVE_OWNER,
	owner_type,
	tin_type,
	tin,
	agree,
	passport,
	bank,
	vehicles,
	drivers
});

export const saveDriver = (is_owner?: boolean, passport?: Passport,
	license?: License): SaveDriverAction => ({
	type: SAVE_DRIVER,
	is_owner,
	passport,
	license
});

export const updateDriver = (): UpdateDriverAction => ({
	type: UPDATE_DRIVER
});

export const saveVehicle = (car_type?: string, body_option?: string, characteristics?: any[],
	car_pass?: string, hydroboard?: string, ramp?: string, length?: string, width?: string,
	height?: string, address?: Address, photos?: Blob[], medical_book?: boolean, will_help?: boolean,
	driver_loader?: boolean, certificate?: VehicleCertificate, drivers?: Driver[]): SaveVehicleAction => ({
	type: SAVE_VEHICLE,
	car_type,
	body_option,
	characteristics,
	car_pass,
	hydroboard,
	ramp,
	length,
	width,
	height,
	address,
	photos,
	medical_book,
	will_help,
	driver_loader,
	certificate,
	drivers
});

export const updateVehicle = (): UpdateVehicleAction => ({
	type: UPDATE_VEHICLE
});

export const removeVehicle = (): RemoveVehicleAction => ({
	type: REMOVE_VEHICLE
});
