import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { connect, Provider } from "react-redux";
import { setAuthenticated } from "../../redux/reducers/user/actions";
import { UserState, SetAuthenticatedAction } from "../../redux/reducers/user/@types";
import store, { RootState } from "../../redux/store";
import { userAPI } from "../../api";
import {
	MainScreen,
	Profile,
	Statistic,
	CheckPhone,
	OwnerSelect,
	BankData,
	PassportData,
	LicenseData,
	Vehicle,
	Finalize,
	QuickRegistration,
	Partnership
} from "../components";
import "./App.css";
import "./Registration.css";

interface IProps {
  state: UserState,
  setAuthenticated: ({ authenticated, driver_id, full_name, status }: UserState) => SetAuthenticatedAction
}

const App: React.FunctionComponent<IProps> = ({ state, setAuthenticated }) => {
  const { authenticated } = state;

  useEffect(() => {
    userAPI.authMe().then((res) => {
      setAuthenticated({
        authenticated: true,
        ...res
      });
    }).catch((err) => {})
  }, []);

  return (
    <Switch>
      <Route exact path="/" component={MainScreen} />
      <Route exact path="/check-phone" component={CheckPhone} />
      <Route exact path="/owner-select" component={OwnerSelect} />
      <Route exact path="/bank" component={BankData} />
      <Route exact path="/passport" component={PassportData} />
      <Route exact path="/license" component={LicenseData} />
      <Route exact path="/vehicle" component={Vehicle} />
      <Route exact path="/finalize" component={Finalize} />
      <Route exact path="/quick-registration" component={QuickRegistration} />

      {authenticated &&
        <>
          <Route exact path="/statistic" component={Statistic} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/partnership" component={Partnership} />
        </>
      }
    </Switch>
  )
} 

const mapStateToProps = (state: RootState) => ({
  state: state.userReducer
});

const AppContainer = connect(mapStateToProps, { setAuthenticated })(App);

const Container: React.FunctionComponent = (props) => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <AppContainer />
      </BrowserRouter>
    </Provider>
  )
}

export default Container;
