import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import InputMask from "react-input-mask";
import { RootState } from "../../redux/store";
import { UserState } from "../../redux/reducers/user/@types";
import { CancelBtn } from "../Elements/elements";
import { validateName, validateEmail, trimPhone } from "../../api/validate";
import { referralsAPI } from "../../api";
import { editingChars } from "../../@types";
import "./Partnership.css";

const referralData = {
	drivers: [
		{
			phone: '79121111111',
			status: 'Зарегистрировался',
			summ: 1000
		},
		{
			phone: '79122222222',
			status: 'Зарегистрировался',
			summ: 1000
		},
		{
			phone: '79123333333',
			status: 'Оформляется...',
			summ: 1000
		},
		{
			phone: '79124444444',
			status: 'Приглашен',
			summ: 1000
		}
	],
	clients: [
		{
			phone: '79125555555',
			status: 'Зарегистрировался',
			summ: 1000
		},
		{
			phone: '79126666666',
			status: 'Оформляется...',
			summ: 1000
		},
		{
			phone: '79127777777',
			status: 'Приглашен',
			summ: 1000
		},
		{
			phone: '79128888888',
			status: 'Приглашен',
			summ: 1000
		}
	]
}

interface IProps {
  state: UserState
}

const Partnership: React.FunctionComponent<IProps> = ({
	state
}) => {
	const [selectedTab, setSelectedTab] = useState('drivers');
	const [referrals, setReferrals] = useState<any>(null);
	const [formState, setFormState] = useState('');
	const [driverPhone, setDriverPhone] = useState('');
	const [clientPhone, setClientPhone] = useState('');
	const [clientName, setClientName] = useState('');
	const [clientEmail, setClientEmail] = useState('');
	const [sendingData, setSendingData] = useState(false);
	const [postResponse, setPostResponse] = useState<any>(null);

	useEffect(() => {
		if (state.driver_id !== undefined && state.driver_id !== 0) {
			referralsAPI.getData(state.driver_id.toString())
				.then((response) => {
					if (response && response.data &&
						(response.data.drivers || response.data.clients)) {
						setReferrals(response.data);
					}
				});
		}
		setReferrals(referralData);//TODO for tests only
	}, [postResponse]);

	const formatPhone = (phone: string) => {
		return '+' + phone[0] + ' (' + phone.slice(1, 4) + ') ' +
			phone.slice(4, 7) + '-' + phone.slice(7, 9) + '-' + phone.slice(9);
	}

	const sendDriver = async () => {
		setSendingData(true);
		const response = await referralsAPI.sendDriver(state.driver_id.toString(), driverPhone);
		if (response && response.data)
			setPostResponse({status: 'success'});//TODO setPostResponse(response.data);
		setSendingData(false);
	}

	const sendClient = async () => {
		setSendingData(true);
		const response = await referralsAPI.sendClient(state.driver_id.toString(),
			clientPhone, clientName, clientEmail);
		if (response && response.data)
			setPostResponse({status: 'success'});//TODO setPostResponse(response.data);
		setSendingData(false);
	}

	const closeForm = () => {
		setFormState('');
		setPostResponse(null);
	}

	return (
		<>
			<CancelBtn />
			{formState === '' &&
				<div className="partnership_window">
					<div className="partnership_container">
						<div className="partnership_title">Партнерская программа</div>
						<div className="partnership_body">
							<div className="partnership_body_container">
								<div className="partnership_body_text">
									За каждого приглашенного водителя или клиента вы получите 1000 ₽
								</div>
								<div id="partnership_invite_driver" className="partnership_body_button"
									onClick={() => setTimeout(setFormState, 1000, 'driver')}>
									<div className="partnership_body_button_text">Пригласить водителя</div>
								</div>
								<div id="partnership_invite_client"
									className="partnership_body_button partnership_body_button_orange"
									onClick={() => setTimeout(setFormState, 1000, 'client')}>
									<div className="partnership_body_button_text">Пригласить клиента</div>
								</div>
							</div>
							<div className="partnership_button_container">
								<div id="partnership_drivers" className={'partnership_section_button' +
									(selectedTab === 'drivers' ? ' partnership_section_button_selected' : '') +
									(referrals?.drivers?.length > 0 ? '' : ' partnership_section_button_empty')}
									onClick={() => setSelectedTab('drivers')}>
									<div className="partnership_section_button_text">Водители</div>
								</div>
								<div id="partnership_clients" className={'partnership_section_button' +
									(selectedTab === 'clients' ? ' partnership_section_button_selected' : '') +
									(referrals?.clients?.length > 0 ? '' : ' partnership_section_button_empty')}
									onClick={() => setSelectedTab('clients')}>
									<div className="partnership_section_button_text">Клиенты</div>
								</div>
							</div>
							<table className="partnership_referrals">
								<thead>
									<tr>
										<th className="partnership_referrals_phone">Номер</th>
										<th className="partnership_referrals_status">Статус</th>
										<th className="partnership_referrals_sum">Сумма</th>
									</tr>
								</thead>
								<tbody>
									{selectedTab === 'drivers' ?
										<>
											{referrals?.drivers?.filter((driver: any) => driver.status === 'Зарегистрировался')
												.map((driver: any) => (
													<tr key={'partnership_driver_' + driver?.phone}
														className="partnership_referrals_row">
														<td className="partnership_referrals_phone">{formatPhone(driver?.phone)}</td>
														<td className={'partnership_referrals_status' +
															' partnership_referrals_status_registered'}>{driver?.status}</td>
														<td className={'partnership_referrals_sum' +
															' partnership_referrals_sum_registered'}>{driver?.summ + ' ₽'}</td>
													</tr>
												))
											}
											{referrals?.drivers?.filter((driver: any) => (
												driver.status === 'Зарегистрировался'
											)).length > 0 ?
												<tr className="partnership_referrals_text">
													<th colSpan={3}>Вы получите средства вместе со следующей оплатой</th>
												</tr> : null
											}
											{referrals?.drivers?.filter((driver: any) => driver.status === 'Оформляется...')
												.map((driver: any) => (
													<tr key={'partnership_driver_' + driver?.phone}
														className="partnership_referrals_row">
														<td className="partnership_referrals_phone">{formatPhone(driver?.phone)}</td>
														<td className="partnership_referrals_status">{driver?.status}</td>
														<td className="partnership_referrals_sum">{driver?.summ + ' ₽'}</td>
													</tr>
												))
											}
											{referrals?.drivers?.filter((driver: any) => driver.status === 'Приглашен')
												.map((driver: any) => (
													<tr key={'partnership_driver_' + driver?.phone}
														className="partnership_referrals_row">
														<td className="partnership_referrals_phone">{formatPhone(driver?.phone)}</td>
														<td className="partnership_referrals_status">{driver?.status}</td>
														<td className="partnership_referrals_sum">{driver?.summ + ' ₽'}</td>
													</tr>
												))
											}
										</> :
										<>
											{referrals?.clients?.filter((client: any) => client.status === 'Зарегистрировался')
												.map((client: any) => (
													<tr key={'partnership_client_' + client?.phone}
														className="partnership_referrals_row">
														<td className="partnership_referrals_phone">{formatPhone(client?.phone)}</td>
														<td className={'partnership_referrals_status' +
															' partnership_referrals_status_registered'}>{client?.status}</td>
														<td className={'partnership_referrals_sum' +
															' partnership_referrals_sum_registered'}>{client?.summ + ' ₽'}</td>
													</tr>
												))
											}
											{referrals?.clients?.filter((client: any) => (
												client.status === 'Зарегистрировался'
											)).length > 0 ?
												<tr className="partnership_referrals_text">
													<th colSpan={3}>Вы получите средства вместе со следующей оплатой</th>
												</tr> : null
											}
											{referrals?.clients?.filter((client: any) => client.status === 'Оформляется...')
												.map((client: any) => (
													<tr key={'partnership_driver_' + client?.phone}
														className="partnership_referrals_row">
														<td className="partnership_referrals_phone">{formatPhone(client?.phone)}</td>
														<td className="partnership_referrals_status">{client?.status}</td>
														<td className="partnership_referrals_sum">{client?.summ + ' ₽'}</td>
													</tr>
												))
											}
											{referrals?.clients?.filter((client: any) => client.status === 'Приглашен')
												.map((client: any) => (
													<tr key={'partnership_driver_' + client?.phone}
														className="partnership_referrals_row">
														<td className="partnership_referrals_phone">{formatPhone(client?.phone)}</td>
														<td className="partnership_referrals_status">{client?.status}</td>
														<td className="partnership_referrals_sum">{client?.summ + ' ₽'}</td>
													</tr>
												))
											}
										</>
									}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			}
			{formState === 'driver' &&
				<div className="partnership_window">
					<div className="partnership_subcontainer">
						<div className="partnership_subtitle">Пригласить водителя</div>
						<div id="partnership_driver_phone" className={'partnership_input' +
							(driverPhone === '' ? '' : ' partnership_input_complete')}>
							<InputMask mask="+7(999) 999-99-99" type="tel" onChange={(e) => {
								let phone = e.target.value;
								if (phone.indexOf('_') === -1) {
									setDriverPhone(trimPhone(phone));
								} else {
									setDriverPhone('');
								}
							}}>
								{(inputProps: any) => <input {...inputProps} className="partnership_input_text"
									placeholder="Телефон водителя" />}
							</InputMask>
						</div>
						{sendingData ?
							<div className="partnership_sending">Отправка данных...</div> :
							<div id="partnership_driver_share" className={'partnership_button' +
								(driverPhone === '' ? ' inactive' : '')} onClick={sendDriver}>
								<div className="partnership_button_text">Поделиться ссылкой</div>
							</div>
						}
					</div>
				</div>
			}
			{formState === 'client' &&
				<div className="partnership_window">
					<div className="partnership_subcontainer">
						<div className="partnership_subtitle">Внести клиента</div>
						<div id="partnership_client_phone" className={'partnership_input' +
							(clientPhone === '' ? '' : ' partnership_input_complete')}>
							<InputMask mask="+7(999) 999-99-99" type="tel" onChange={(e) => {
								let phone = e.target.value;
								if (phone.indexOf('_') === -1) {
									setClientPhone(trimPhone(phone));
								} else {
									setClientPhone('');
								}
							}}>
								{(inputProps: any) => <input {...inputProps} className="partnership_input_text"
									placeholder="Телефон потенциального клиента" />}
							</InputMask>
						</div>
						<div id="partnership_client_name" className={'partnership_input' +
							(clientName === '' ? '' : ' partnership_input_complete')}>
							<input type="text" className="partnership_input_text"
								placeholder="ФИО (не обязательно)" onKeyDown={(e: React.KeyboardEvent) => {
									if (!/[А-Яа-я -]/.test(e.key) && !editingChars.includes(e.key)) {
										e.preventDefault();
									}
								}}
								onChange={(e) => {
									let name = e.target.value;
									if (validateName(name)) {
										setClientName(name);
									} else {
										setClientName('');
									}
								}}
							/>
						</div>
						<div id="partnership_client_email" className={'partnership_input' +
							(clientEmail === '' ? '' : ' partnership_input_complete')}>
							<input type="text" className="partnership_input_text"
								placeholder="Email (не обязательно)" onChange={(e) => {
									let email = e.target.value;
									if (validateEmail(email)) {
										setClientEmail(email);
									} else {
										setClientEmail('');
									}
								}}
							/>
						</div>
						{sendingData ?
							<div className="partnership_sending">Отправка данных...</div> :
							<div id="partnership_client_share" className={'partnership_button' +
								(clientPhone === '' ? ' inactive' : '')} onClick={sendClient}>
								<div className="partnership_button_text">Поделиться ссылкой</div>
							</div>
						}
					</div>
				</div>
			}
			{postResponse && postResponse.status === 'success' &&
				<div className="partnership_response_window">
					<div className="partnership_response_container">
						<div className="partnership_response_success"></div>
						<div className="partnership_response_success_text">Приглашение отправлено</div>
						<div className="partnership_response_success_message">
							Когда {formState === 'driver' ? 'водитель ' : 'клиент '}
							зарегистрируется по вашей ссылке, вы получите вознаграждение
						</div>
						<div id="partnership_close" className="partnership_button"
							onClick={() => setTimeout(closeForm, 1000)}>
							<div className="partnership_button_text">OK</div>
						</div>
					</div>
				</div>
			}
		</>
	)
}

const mapStateToProps = (state: RootState) => ({
	state: state.userReducer
});

export default connect(mapStateToProps, {})(Partnership);
