import {
	BankDataState,
	SET_ACCOUNT_ENTERED,
	SET_BANK_ENTERED,
	SET_BIC_ENTERED,
	SET_CORR_ENTERED,
	SET_INN_ENTERED,
	SET_KPP_ENTERED,
	SET_OGRN_ENTERED,
	SET_BANK_ADDRESS_ENTERED,
	BankDataActionTypes
} from "./@types";

let initialState: BankDataState = {
	account_entered: false,
	account: '',
	bank_entered: false,
	bank: '',
	bic_entered: false,
	bic: '',
	corr_entered: false,
	corr: '',
	inn_entered: false,
	inn: '',
	kpp_entered: false,
	kpp: '',
	ogrn_entered: false,
	ogrn: '',
	bank_address_entered: false,
	bank_address: ''
}

const bankDataReducer = (state = initialState, action: BankDataActionTypes) => {
	switch (action.type) {
		case SET_ACCOUNT_ENTERED:
			return {
				...state,
				account_entered: action.account_entered,
				account: action.account
			}
		case SET_BANK_ENTERED:
			return {
				...state,
				bank_entered: action.bank_entered,
				bank: action.bank
			}
		case SET_BIC_ENTERED:
			return {
				...state,
				bic_entered: action.bic_entered,
				bic: action.bic
			}
		case SET_CORR_ENTERED:
			return {
				...state,
				corr_entered: action.corr_entered,
				corr: action.corr
			}
		case SET_INN_ENTERED:
			return {
				...state,
				inn_entered: action.inn_entered,
				inn: action.inn
			}
		case SET_KPP_ENTERED:
			return {
				...state,
				kpp_entered: action.kpp_entered,
				kpp: action.kpp
			}
		case SET_OGRN_ENTERED:
			return {
				...state,
				ogrn_entered: action.ogrn_entered,
				ogrn: action.ogrn
			}
		case SET_BANK_ADDRESS_ENTERED:
			return {
				...state,
				bank_address_entered: action.bank_address_entered,
				bank_address: action.bank_address
			}
		default:
			return state;
	}
}

export default bankDataReducer;
