import { SET_AUTHENTICATED, SET_STATUS, UserState, SetAuthenticatedAction, SetStatusAction } from "./@types";
import { UserStatus } from "../../../@types";

export const setAuthenticated = ({ authenticated, driver_id, full_name, status }: UserState): SetAuthenticatedAction => ({ 
  type: SET_AUTHENTICATED, 
  authenticated,
  driver_id: driver_id,
  full_name: full_name,
  status: status
});

export const setStatus = (status: UserStatus): SetStatusAction => ({
  type: SET_STATUS,
  status
});