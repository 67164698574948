import React from "react";
import { Link } from "react-router-dom";
import "./Tab.css";

interface IProps {
  url: string;
  title: string;
  warning?: string;
}

const Tab: React.FunctionComponent<IProps> = ({ url, title, warning }) => {
  return (
    <div className="screen_tab_container">
      <Link to={url}>
        <div className="screen_tab">
          <div className="screen_tab_icon">
            <svg width="0.65rem" height="1.14rem" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.97652 0.325817C1.76125 0.114994 1.48728 0 1.16438 0C0.518591 0 0 0.49831 0 1.13078C0 1.44701 0.136987 1.7345 0.362036 1.9549L7.21135 8.50958L0.362036 15.0451C0.136987 15.2655 0 15.5626 0 15.8692C0 16.5017 0.518591 17 1.16438 17C1.48728 17 1.76125 16.885 1.97652 16.6742L9.58904 9.39121C9.86301 9.14205 9.99022 8.8354 10 8.5C10 8.1646 9.86301 7.87711 9.58904 7.61838L1.97652 0.325817Z" fill="#c6cad2"/>
            </svg>
          </div>
          <div className="screen_tab_title">{ title }</div>
          { warning && <div className="screen_tab_warning">{ warning }</div> }
        </div>
      </Link>
    </div>
  );
}

export default Tab;