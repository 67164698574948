import { Driver } from "../../../components/Elements/Containers/@types";

import {
	SET_CAR_TYPE_ENTERED,
	SET_BODY_OPTION_ENTERED,
	SET_BODY_OPTION_CHS_ENTERED,
	SET_CAR_PASS_ENTERED,
	SET_HYDROBOARD_ENTERED,
	SET_RAMP_ENTERED,
	SET_LENGTH_ENTERED,
	SET_WIDTH_ENTERED,
	SET_HEIGHT_ENTERED,
	SET_GARAGE_ENTERED,
	ADD_VEHICLE_PHOTO,
	REPLACE_VEHICLE_PHOTO,
	CLEAR_VEHICLE_PHOTOS,
	SET_MEDICAL_BOOK,
	SET_WILL_HELP,
	SET_DRIVER_LOADER,
	SET_LICENSE_PLATE_ENTERED,
	SET_VIN_ENTERED,
	SET_BRAND_MODEL_ENTERED,
	SET_VEHICLE_TYPE_ENTERED,
	SET_VEHICLE_CATEGORY_ENTERED,
	SET_YEAR_ENTERED,
	SET_ECOLOGY_ENTERED,
	SET_PTS_SERIES_ENTERED,
	SET_PTS_NUMBER_ENTERED,
	SET_STS_SERIES_ENTERED,
	SET_STS_NUMBER_ENTERED,
	SET_MAX_WEIGHT_ENTERED,
	SET_LOAD_WEIGHT_ENTERED,
	ADD_CERTIFICATE_PHOTO,
	REPLACE_CERTIFICATE_PHOTO,
	CLEAR_CERTIFICATE_PHOTOS,
	ASSIGN_DRIVER,
	DISMISS_DRIVER,
	SetCarTypeEnteredAction,
	SetBodyOptionEnteredAction,
	SetBodyOptionChsEnteredAction,
	SetCarPassEnteredAction,
	SetHydroboardEnteredAction,
	SetRampEnteredAction,
	SetLengthEnteredAction,
	SetWidthEnteredAction,
	SetHeightEnteredAction,
	SetGarageEnteredAction,
	AddVehiclePhotoAction,
	ReplaceVehiclePhotoAction,
	ClearVehiclePhotosAction,
	SetMedicalBookAction,
	SetWillHelpAction,
	SetDriverLoaderAction,
	SetLicensePlateEnteredAction,
	SetVinEnteredAction,
	SetBrandModelEnteredAction,
	SetVehicleTypeEnteredAction,
	SetVehicleCategoryEnteredAction,
	SetYearEnteredAction,
	SetEcologyEnteredAction,
	SetPtsSeriesEnteredAction,
	SetPtsNumberEnteredAction,
	SetStsSeriesEnteredAction,
	SetStsNumberEnteredAction,
	SetMaxWeightEnteredAction,
	SetLoadWeightEnteredAction,
	AddCertificatePhotoAction,
	ReplaceCertificatePhotoAction,
	ClearCertificatePhotosAction,
	AssignDriverAction,
	DismissDriverAction
} from "./@types";

export const setCarTypeEntered = (car_type_entered: boolean, car_type?: string):
	SetCarTypeEnteredAction => ({
  type: SET_CAR_TYPE_ENTERED,
  car_type_entered,
  car_type
});

export const setBodyOptionEntered = (body_option_entered: boolean, body_option?: string):
	SetBodyOptionEnteredAction => ({
  type: SET_BODY_OPTION_ENTERED,
  body_option_entered,
  body_option
});

export const setBodyOptionChsEntered = (body_option_chs_entered: boolean, body_option_chs?: any):
	SetBodyOptionChsEnteredAction => ({
  type: SET_BODY_OPTION_CHS_ENTERED,
  body_option_chs_entered,
  body_option_chs
});

export const setCarPassEntered = (car_pass_entered: boolean, car_pass?: string):
	SetCarPassEnteredAction => ({
  type: SET_CAR_PASS_ENTERED,
  car_pass_entered,
  car_pass
});

export const setHydroboardEntered = (hydroboard_entered: boolean, hydroboard?: string):
	SetHydroboardEnteredAction => ({
  type: SET_HYDROBOARD_ENTERED,
  hydroboard_entered,
  hydroboard
});

export const setRampEntered = (ramp_entered: boolean, ramp?: string): SetRampEnteredAction => ({
  type: SET_RAMP_ENTERED,
  ramp_entered,
  ramp
});

export const setLengthEntered = (length_entered: boolean, length?: string): SetLengthEnteredAction => ({
  type: SET_LENGTH_ENTERED,
  length_entered,
  length
});

export const setWidthEntered = (width_entered: boolean, width?: string): SetWidthEnteredAction => ({
  type: SET_WIDTH_ENTERED,
  width_entered,
  width
});

export const setHeightEntered = (height_entered: boolean, height?: string): SetHeightEnteredAction => ({
  type: SET_HEIGHT_ENTERED,
  height_entered,
  height
});

export const setGarageEntered = (garage_entered: boolean, address?: string, latitude?: string,
	longitude?: string): SetGarageEnteredAction => ({
  type: SET_GARAGE_ENTERED,
  garage_entered,
  address,
  latitude,
  longitude
});

export const addVehiclePhoto = (photo: Blob): AddVehiclePhotoAction => ({
  type: ADD_VEHICLE_PHOTO,
  photo
});

export const replaceVehiclePhoto = (index: number, new_photo: Blob): ReplaceVehiclePhotoAction => ({
  type: REPLACE_VEHICLE_PHOTO,
  index,
  new_photo
});

export const clearVehiclePhotos = (): ClearVehiclePhotosAction => ({
  type: CLEAR_VEHICLE_PHOTOS
});

export const setMedicalBook = (medical_book: boolean): SetMedicalBookAction => ({
  type: SET_MEDICAL_BOOK,
  medical_book
});

export const setWillHelp = (will_help: boolean): SetWillHelpAction => ({
  type: SET_WILL_HELP,
  will_help
});

export const setDriverLoader = (driver_loader: boolean): SetDriverLoaderAction => ({
  type: SET_DRIVER_LOADER,
  driver_loader
});

export const setLicensePlateEntered = (license_plate_entered: boolean, license_plate?: string):
	SetLicensePlateEnteredAction => ({
  type: SET_LICENSE_PLATE_ENTERED,
  license_plate_entered,
  license_plate
});

export const setVinEntered = (vin_entered: boolean, vin?: string): SetVinEnteredAction => ({
  type: SET_VIN_ENTERED,
  vin_entered,
  vin
});

export const setBrandModelEntered = (brand_model_entered: boolean, brand_model?: string):
	SetBrandModelEnteredAction => ({
  type: SET_BRAND_MODEL_ENTERED,
  brand_model_entered,
  brand_model
});

export const setVehicleTypeEntered = (vehicle_type_entered: boolean, vehicle_type?: string):
	SetVehicleTypeEnteredAction => ({
  type: SET_VEHICLE_TYPE_ENTERED,
  vehicle_type_entered,
  vehicle_type
});

export const setVehicleCategoryEntered = (vehicle_category_entered: boolean, vehicle_category?: string):
	SetVehicleCategoryEnteredAction => ({
  type: SET_VEHICLE_CATEGORY_ENTERED,
  vehicle_category_entered,
  vehicle_category
});

export const setYearEntered = (year_entered: boolean, year?: string): SetYearEnteredAction => ({
  type: SET_YEAR_ENTERED,
  year_entered,
  year
});

export const setEcologyEntered = (ecology_entered: boolean, ecology?: string):
	SetEcologyEnteredAction => ({
  type: SET_ECOLOGY_ENTERED,
  ecology_entered,
  ecology
});

export const setPtsSeriesEntered = (pts_series_entered: boolean, pts_series?: string):
	SetPtsSeriesEnteredAction => ({
  type: SET_PTS_SERIES_ENTERED,
  pts_series_entered,
  pts_series
});

export const setPtsNumberEntered = (pts_number_entered: boolean, pts_number?: string):
	SetPtsNumberEnteredAction => ({
  type: SET_PTS_NUMBER_ENTERED,
  pts_number_entered,
  pts_number
});

export const setStsSeriesEntered = (sts_series_entered: boolean, sts_series?: string):
	SetStsSeriesEnteredAction => ({
  type: SET_STS_SERIES_ENTERED,
  sts_series_entered,
  sts_series
});

export const setStsNumberEntered = (sts_number_entered: boolean, sts_number?: string):
	SetStsNumberEnteredAction => ({
  type: SET_STS_NUMBER_ENTERED,
  sts_number_entered,
  sts_number
});

export const setMaxWeightEntered = (max_weight_entered: boolean, max_weight?: string):
	SetMaxWeightEnteredAction => ({
  type: SET_MAX_WEIGHT_ENTERED,
  max_weight_entered,
  max_weight
});

export const setLoadWeightEntered = (load_weight_entered: boolean, load_weight?: string):
	SetLoadWeightEnteredAction => ({
  type: SET_LOAD_WEIGHT_ENTERED,
  load_weight_entered,
  load_weight
});

export const addCertificatePhoto = (photo: Blob): AddCertificatePhotoAction => ({
  type: ADD_CERTIFICATE_PHOTO,
  photo
});

export const replaceCertificatePhoto = (index: number, new_photo: Blob):
	ReplaceCertificatePhotoAction => ({
  type: REPLACE_CERTIFICATE_PHOTO,
  index,
  new_photo
});

export const clearCertificatePhotos = (): ClearCertificatePhotosAction => ({
  type: CLEAR_CERTIFICATE_PHOTOS
});

export const assignDriver = (driver: Driver): AssignDriverAction => ({
  type: ASSIGN_DRIVER,
  driver
});

export const dismissDriver = (driver: Driver): DismissDriverAction => ({
  type: DISMISS_DRIVER,
  driver
});
